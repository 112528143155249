import axios from "axios";

export const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API_URL + "/api/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
