import React, { useRef } from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import {
//   Chart as ChartJS,
//   defaults
// } from 'chart.js';
import  { InteractionItem } from 'chart.js';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables 
} from 'chart.js';

import { Line, Scatter} from 'react-chartjs-2';
 
ChartJS.register(
  ...registerables
);



function  GraphChart(props){
  let  data = {}
 if(props.data.graphType==='single'){
  data = {
    datasets: [{
      type: 'line',
      label: props.data.label,
      radius: 0,
      data:props.data.data.data1,
      fill: false,
      borderColor: 'rgb(54, 162, 235)'
    }]
  };
 }
 else{
  data = {
    datasets: [
      {
        type: 'line',
        label: props.data.label2,
        radius: 0,
        data:props.data.data.data2,
        fill: false,
        borderColor: 'red'
      },
      {
      type: 'line',
      label: props.data.label,
      radius: 0,
      data:props.data.data.data1,
      fill: false,
      borderColor: 'rgb(54, 162, 235)'
    }]
  };
 }
    
 
 const renderTtitle = () =>{
  return(<h3>Heello</h3>)
 }
    
      
  return <Scatter 
  options={{
    plugins: {
      legend: {
        display: (props.data.graphType==='dual')
      },
      tooltip: {
        enabled: false
     },
     title: renderTtitle(),
    },
    scales: {

      yAxes: {
       title: {
            display: true,
            text: props.data.yAxisLabel
          }
       
      },
      xAxes: {
        title: {
          display: true,
          text: props.data.xAxisLabel
        }
      }
    } 
  }} 
  data={data}     />;
}
export default GraphChart;