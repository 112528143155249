import React, { useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";

function TableHeaderActions(props) {
  const [searchKey, setSearchKey] = useState("");
  const [checkBoxValue, setCheckBoxValue] = useState(
    props?.metaData?.checkBoxValue
  );
  const myModel = useSelector((state) => state.userModel);
  const latestVersion = useSelector((state) => state.latestVersion)
  // const searchKey = useSelector((state) => state.searchKey);

  const handleSearchKey = (e) => {
    props.metaData.handleSearch(e.target.value);
    setSearchKey(e.target.value);
  };

  const handleMyFiles = (e) => {
    props.metaData.handleMyFiles(e.target.checked);
    setCheckBoxValue(e.target.checked);
  };

  const handleClear = (e) => {
    props.metaData.handleSearch("");
    setSearchKey("");
  };

  return (
    <div className="header-btn-grp">
      {props?.metaData?.checkBoxTitle && (
        <div className="form-check">
          <input
            className="form-check-input"
            onChange={(e) => handleMyFiles(e)}
            checked={checkBoxValue}
            type="checkbox"
            id="defaultCheck1"
            style={{ visibility: "visible", opacity: 1 }}
          />
          <label
            className="form-check-label"
            for="defaultCheck1"
            style={{ paddingLeft: "0px", paddingRight: "73px" }}
          >
            {props.metaData.checkBoxTitle}
          </label>
        </div>
      )}

      <div className="form-group search-box" style={{ marginRight: "10px" }}>
        <label for="">Search</label>
        <input
          type="email"
          value={searchKey}
          className="form-control"
          id=""
          onChange={(e) => handleSearchKey(e)}
        />
      </div>

      {(searchKey || myModel.isChecked || latestVersion.isLatestVersionChecked) && (
        <button
          onClick={() => handleClear()}
          type="button"
          className="btn btn-outline btnBlue"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          <i className="now-ui-icons  ui-1_simple-remove"></i> Clear
        </button>
      )}
      {props?.metaData?.buttonTitle && (
        <button
          type="button"
          className="btn btn-outline-info"
          onClick={props?.metaData?.onButtonClick}
        >
          <i class="now-ui-icons ui-1_simple-add"></i>{" "}
          {props.metaData.buttonTitle}
        </button>
      )}
    </div>
  );
}

export default TableHeaderActions;
