import React, { useEffect, useState, useCallback } from "react";
import TableHeaderTitle from "./tableHeaderTitle";
import TableHeaderActions from "./tableHeaderActions";
import "./index.css";

function TableHeader(props) {
  const metaData = {
    iconType: props.iconType,
    tableTitle: props.title,
    path: props.path,
    userRole: props.userRole,
    checkBoxTitle: props.checkBoxTitle,
    checkBoxValue: props.checkBoxValue,
    buttonTitle: props.buttonTitle,
    renderElement: props.renderElement,
    onButtonClick: props.onButtonClick,
    handleSearch: props.handleSearch,
    handleMyFiles: props.handleMyFiles,
  };
  return (
    <div className="col-12  header-bar">
      <div className="content">
        <TableHeaderTitle metaData={metaData} />
        <TableHeaderActions metaData={metaData} />
      </div>
    </div>
  );
}

export default TableHeader;
