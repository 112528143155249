import React from "react";

import "./index.css";
import PageHeadeActionButtons from "./pageHeaderActionButtons";
import PageHeaderTitle from "./pageHeaderTtile";

function PageHeader(props) {
  const metaData = {
    iconType: props.iconType,
    pageTitle: props.title,
    path: props.path,
    type: props.type,
    userRole: props.userRole,
  };

  return (
    <div className="col-12 header-bar">
      <div className="content">
        <PageHeaderTitle metaData={metaData} />
        <PageHeadeActionButtons metaData={metaData} />
      </div>
    </div>
  );
}

export default PageHeader;
