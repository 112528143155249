import React from 'react';
import loader from '../loader.gif';
import logo from '../components/header/logo.png';
const PageNotFound = () => {
  return (
    <div className='main-panel ps'>
             <div className='content padd-top'>
                 <div className='waitingForUserInfo'>                     
                     <div className='loadingMsg'>
                     <div className='loadingRes'>
                     <h1 style={{'color':'red'}}>404</h1>
                     <h5>Error 404, Page Not Found</h5>
                     
                     </div>
                     </div>
                 </div>
             </div>

       </div> 
  );
};

export default PageNotFound;