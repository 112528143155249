export function getStatusColor(status) {
  switch (status) {
    case "published":
      return "green";
    case "Generated":
      return "green";
    case "Uploaded":
      return "green";
    case "active":
    case "Active":
      return "green";
    case "Generating":
      return "blue";
    case "Uploading":
      return "blue";
    case "draft":
      return "grey";
    case "Failed":
    case "inActive":
      return "red";
    default:
      return "yellow";
  }
}
