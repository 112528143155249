
export const  userRoles=(roles) =>{
   let tempRole =  '';
    if(roles.constructor === Array){
      //  if(roles.length>0){
          if(roles.includes('NL_ADMIN') || roles.includes('NL_OKTA_PROD_ADMIN')){
            tempRole = 'NL_ADMIN'
          }
        // }
        // else{
          
          else if(roles.includes('NL_EDIT') || roles.includes('NL_OKTA_PROD_EDIT')){
            tempRole = "NL_EDIT"
          } 
          else if(roles.includes('NL_READ') || roles.includes('NL_OKTA_PROD_READ')){
            tempRole = "NL_READ"
          } 
          
        // }
     
       
    }
    return tempRole;
  }

  

  