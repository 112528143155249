export default (state = {isLatestVersionChecked : false}, action) => {
    switch (action.type) {
        case 'UPDATE_LATEST_VERSION':
            return action.payload;
            break
        case 'RESET_LATEST_VERSION':
            return {isLatestVersionChecked : false}
            break;
        default:
            return state;
    }
};