import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { editPartName, addPartName, getBusinessUnit } from "../../axios";
import { useDispatch } from "react-redux";
import Input from "../atom/input";
import ErrorMsg from "../errors/errorMsg";
import "./index.css";

function AddEditPartNameModal({
  isModalOpen,
  setIsModalOpen,
  selectedItem,
  refetchApi,
  editModal,
}) {
  const initialValue = {
    name: "",
    color: "(0,0,0)",
    status: "",
    type: "",
    businessUnit: "",
    // color: "(84,42,60)",
  };
  const isEditModal = editModal === "Part Names";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [allFields, setAllFields] = useState({});
  const [buOptions, setBuOptions] = useState([]);
  const [color, setColor] = useState("#000"); // Initial color is black

  const statusList = [
    {
      id: "active",
      name: "active",
    },
    {
      id: "inActive",
      name: "inActive",
    },
  ];
  function componentToHex(c) {
    var hex = c.toString(16);
    return hex.length == 1 ? "0" + hex : hex;
  }

  function rgbToHex(value) {
    const arrayFromString = value
      .slice(1, -1) // Remove parentheses
      .split(",")
      .map(Number);
    return (
      "#" +
      componentToHex(arrayFromString[0]) +
      componentToHex(arrayFromString[1]) +
      componentToHex(arrayFromString[2])
    );
  }

  const handleColorChange = (event) => {
    const color = event.target.value;
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    setColor(color);
    setAllFields({
      ...allFields,
      color: `(${r},${g},${b})`,
    });
    setError("");
  };
  const handleAdd = () => {
    // Validations

    // Check all mandatory files
    let mandatoryFieldMissing = false;
    for (let key in allFields) {
      if (allFields.hasOwnProperty(key) && !allFields[key]) {
        setError(`${key} is mandatory.`);
        mandatoryFieldMissing = true;
        return;
      }
    }

    if (mandatoryFieldMissing) {
      return;
    }

    setLoading(true);
    dispatch(isEditModal ? editPartName(allFields) : addPartName(allFields))
      .then((result) => {
        refetchApi();
        setLoading(false);
        setAllFields({ ...initialValue });
        setColor("#000");
        setIsModalOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.responseDesc || `Error in operation`);
      });
  };

  useEffect(() => {
    if (isEditModal) {
      setAllFields({
        name: selectedItem?.name,
        color: selectedItem?.color,
        status: selectedItem?.status,
        type: selectedItem?.type,
        businessUnit: selectedItem?.businessUnit,
        id: selectedItem?.Id,
      });
      setColor(rgbToHex(selectedItem?.color));
    } else {
      setAllFields({ ...initialValue });
    }
  }, [isEditModal, isModalOpen]);

  useEffect(() => {
    dispatch(getBusinessUnit())
      .then((result) => {
        setBuOptions(result?.data?.responseData || []);
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return () => {
      setError("");
      setAllFields({ ...initialValue });
      setLoading(false);
      setColor("#000");
    };
  }, []);

  const onChange = (e) => {
    setAllFields({
      ...allFields,
      [e.target?.id]: e.target?.value,
    });
    setError("");
  };

  return (
    <Modal
      title={<h3>{isEditModal ? "Edit" : "Add"} Part Name</h3>}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setAllFields({ ...initialValue });
        setColor("#000");
        setError("");
        setLoading(false);
      }}
      onOk={() => {
        handleAdd();
      }}
      okText={isEditModal ? "Edit" : "Save"}
      destroyOnClose
      width={700}
      confirmLoading={loading}
      // size={"large"}
    >
      {error && <ErrorMsg message={error} />}

      <Input
        id={"name"}
        label={"Name"}
        onChange={onChange}
        // infoComponent={infoComponent}
        required={true}
        value={allFields["name"]}
      />
      <>
        <div className="textLabel">
          <span>{"Status"}</span>
          <span className="required">*</span>
        </div>

        <div className="dropdown">
          <select
            class="custom-select"
            id={"status"}
            key={"status"}
            value={allFields["status"]}
            onChange={onChange}
          >
            <option selected="" disabled="" value="">
              Choose...
            </option>

            {statusList?.map((value) => (
              <option value={value?.id}>{value?.name}</option>
            ))}
          </select>
        </div>
      </>
      <Input
        id={"type"}
        label={"Type"}
        onChange={onChange}
        // infoComponent={infoComponent}
        required={true}
        value={allFields["type"]}
      />
      <>
        <div className="textLabel">
          <span>{"Business Unit"}</span>
          <span className="required">*</span>
        </div>

        <div className="dropdown">
          <select
            class="custom-select"
            id={"businessUnit"}
            key={"businessUnit"}
            value={allFields["businessUnit"]}
            onChange={onChange}
          >
            <option selected="" disabled="" value="">
              Choose...
            </option>

            {buOptions?.map((value) => (
              <option value={value?.id}>{value?.businessUnit}</option>
            ))}
          </select>
        </div>
      </>
      <Input
        id={"color"}
        label={"Color"}
        onChange={handleColorChange}
        required={true}
        value={color}
        type={"color"}
      />

      {/* <input type="color" value={color} onChange={handleColorChange} /> */}
      {/* <div style={{ marginTop: "20px" }}>
      </div> */}
    </Modal>
  );
}

export default AddEditPartNameModal;
