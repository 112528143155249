import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import { runAutoCalculation } from "../utils/calculatorUtils";
import "./index.css";
import GraphChart from "../charts/graphChart";

const Graphs = (props) => {
  const graphDetails = useSelector((state) => state.graphData);
  const [graphType, setGraphType] = React.useState("");
  const [barchartData, setBarchartData] = React.useState({});
  const [selectedGraph, setSelectedGraph] = React.useState({});
  const [calulatedVariables, setCalulatedVariables] = React.useState({});
  const [errorData, setErrorData] = React.useState([]);

  useEffect(() => {
    if (graphDetails && graphDetails.data && graphDetails.data.graphData) {
      setGraphType(graphDetails.data.graphData[0]["graphId"]);
      handlGraphVisualizer(graphDetails.data.graphData[0]["graphId"]);
    }
  }, [graphDetails]);

  const renderGraphOptions = () => {
    if (graphDetails && graphDetails.data && graphDetails.data.graphData) {
      return graphDetails.data.graphData.map((option) => {
        return <option value={option["graphId"]}>{option["graphName"]}</option>;
      });
    }
  };

  const handlGraphVisualizer = (e) => {
    if (Object.keys(props.dataSet.material).length > 0) {
      let id = e;
      let graphIndex = graphDetails.data.graphData.findIndex((temp) => {
        return temp.graphId == id;
      });

      let loopedVariables = graphDetails.data.loopingVariables;
      let initialEquations = graphDetails.data.graphInitialEquation;
      let graphData = graphDetails.data.graphData[graphIndex];
      setSelectedGraph(graphData);
      let coordinates = [];
      let coordinatesforSecondGrpah = [];
      let coordinateSet = {};
      let calculatedValues = {};
      let errors = [];
      initialEquations.map((record) => {
        if (record.defaultValue) {
          calculatedValues[record.equationDbColumnName] = record.defaultValue;
        } else {
          Object.keys(props.dataSet).map((module) => {
            props.dataSet[module]["propertyData"].map((tempProperty) => {
              let key = Object.keys(tempProperty)[0];
              if (tempProperty[key]["valueTypeArray"]) {
                tempProperty[key]["value"].map((tempArrayProperty) => {
                  let innerkey = Object.keys(tempArrayProperty)[0];
                  if (tempArrayProperty[innerkey]["value"] !== null && tempArrayProperty[innerkey]["value"] !== "-") {
                    calculatedValues[innerkey] = tempArrayProperty[innerkey]["value"];
                  } else {
                    let isExists = errors.findIndex((temp) => {
                      return temp == innerkey;
                    });
                    if (isExists === -1) {
                      errors.push(innerkey);
                    }
                  }
                });
              } else {
                if (tempProperty[key]["value"] !== null) {
                  calculatedValues[key] = tempProperty[key]["value"];
                } else if (!tempProperty[key["value"]]) {
                  if (["solidsDryGelDensity", "solidsDryGelRadius", "contactAngle"].includes(key)) {
                    calculatedValues[key] = 1;
                  }
                  let isExists = errors.findIndex((temp) => {
                    return temp == key;
                  });
                  if (isExists === -1) {
                    errors.push(key);
                  }
                }
              }
            });
          });
          if (record.loopingVariableUsed === false) {
            calculatedValues[record.equationDbColumnName] = runAutoCalculation(calculatedValues, record.equation);
          } else if (record.loopingVariableUsed) {
            let useDefaultLoopingVariables = false;
            let loop = [];
            let tempLoopVar = record.loopingVariable;
            calculatedValues[record.equationDbColumnName] = [];
            if (loopedVariables[tempLoopVar] !== undefined) {
              loop = loopedVariables[tempLoopVar];

              useDefaultLoopingVariables = true;
            } else if (loopedVariables[tempLoopVar] === undefined && calculatedValues[tempLoopVar] !== undefined) {
              loop = calculatedValues[tempLoopVar];
              useDefaultLoopingVariables = false;
            }
            loop.map((loopItem, loopIndex) => {
              let tempcalculatedValues = calculatedValues;
              if (useDefaultLoopingVariables === true) {
                tempcalculatedValues[tempLoopVar] = loopItem;
              }
              let tempVal = runAutoCalculation(tempcalculatedValues, record.equation, loopIndex);
              calculatedValues[record.equationDbColumnName][loopIndex] = tempVal;
            });
          }
        }
      });

      // Calulating  --- Running loop for x and y values
      if (graphData.loopVariable) {
        let tempLoopVar = graphData.loopVariable;
        if (loopedVariables[tempLoopVar]) {
          loopedVariables[tempLoopVar].map((loopItem, loopIndex) => {
            let tempcalculatedValues = calculatedValues;
            tempcalculatedValues[tempLoopVar] = loopItem;
            let tempEquationResultX = runAutoCalculation(tempcalculatedValues, graphData["xAxisEquation"], loopIndex);
            tempcalculatedValues["X"] = tempEquationResultX;
            let tempEquationResultY = runAutoCalculation(tempcalculatedValues, graphData["yAxisEquation"], loopIndex);
            coordinates.push({ x: tempEquationResultX, y: tempEquationResultY });
            if (graphData.graphType === "dual") {
              let x1AxisEquation = runAutoCalculation(tempcalculatedValues, graphData["x1AxisEquation"], loopIndex);
              tempcalculatedValues["X"] = x1AxisEquation;
              let y1AxisEquation = runAutoCalculation(tempcalculatedValues, graphData["y1AxisEquation"], loopIndex);
              coordinatesforSecondGrpah.push({ x: x1AxisEquation, y: y1AxisEquation });
            }
            //coordinatesforSecondGrpah
            coordinateSet["data1"] = coordinates;
            coordinateSet["data2"] = coordinatesforSecondGrpah;
          });
        }
      }
      let tempGraphData = {
        graphType: graphData.graphType,
        data: coordinateSet,
        label: graphData.firstLegendLabel,
        label2: graphData.secondLegendLabel,
        yAxisLabel: graphData.yAxisLabel,
        xAxisLabel: graphData.xAxisLabel,
      };

      const missingVariables = errors?.filter(
        (field) =>
          graphData["xAxisEquation"]?.includes(field) ||
          graphData["yAxisEquation"]?.includes(field) ||
          graphData["x1AxisEquation"]?.includes(field) ||
          graphData["y1AxisEquation"]?.includes(field)
      );

      setCalulatedVariables(calculatedValues);
      setErrorData(missingVariables);
      setBarchartData(tempGraphData);
    }
  };

  const handletGraphTypeChange = (e) => {
    setGraphType(e);
    handlGraphVisualizer(e);
  };

  const renderGraphTypes = () => {
    return (
      <div className="form-group searchType">
        <select
          className="custom-select"
          id="searchType"
          value={graphType}
          onChange={(e) => {
            handletGraphTypeChange(e.target.value);
          }}
        >
          <option value="" disabled>
            Select
          </option>
          {renderGraphOptions()}
        </select>
      </div>
    );
  };

  const renderGraph = () => {
    if (Object.keys(barchartData).length > 0) {
      return (
        <>
          <GraphChart data={barchartData} />
          <br />
        </>
      );
    }
  };

  const renderGraphMetaData = () => {
    if (Object.keys(selectedGraph).length > 0) {
      return (
        <div className="col-12">
          <b>Data used for plotting graph</b>
          <br />
          <br />
          <div class="table-responsive">
            <table className="table table-bordered table-sm">
              <tbody>
                <tr>
                  <td style={{ minWidth: "150px" }}>Graph Type</td>
                  <td>{selectedGraph.graphName}</td>
                </tr>
                <tr>
                  <td>Graph Description</td>
                  <td>{selectedGraph.graphDescription}</td>
                </tr>
                <tr>
                  <td>Equation Used</td>
                  <td>
                    <div class="table-responsive">
                      <table className="table table-borderless table-sm">
                        {selectedGraph.graphType === "single" && (
                          <tbody>
                            <tr>
                              <td style={{ minWidth: "150px" }}>X Axis </td>
                              <td>{selectedGraph.xAxisEquation.replaceAll("item.", "")}</td>
                            </tr>
                            <tr>
                              <td style={{ minWidth: "150px" }}>Y Axis </td>
                              <td>{selectedGraph.yAxisEquation.replaceAll("item.", "")}</td>
                            </tr>
                          </tbody>
                        )}
                        {selectedGraph.graphType === "dual" && (
                          <tbody>
                            <tr>
                              <td style={{ minWidth: "150px" }}>X1 Axis </td>
                              <td>{selectedGraph.xAxisEquation.replaceAll("item.", "")}</td>
                            </tr>
                            <tr style={{ borderBottom: "1px solid #dee2e6" }}>
                              <td style={{ minWidth: "150px" }}>Y1 Axis </td>
                              <td>{selectedGraph.yAxisEquation.replaceAll("item.", "")}</td>
                            </tr>
                            <tr>
                              <td style={{ minWidth: "150px" }}>X2 Axis </td>
                              <td>{selectedGraph.x1AxisEquation.replaceAll("item.", "")}</td>
                            </tr>
                            <tr>
                              <td style={{ minWidth: "150px" }}>Y2 Axis </td>
                              <td>{selectedGraph.y1AxisEquation.replaceAll("item.", "")}</td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      );
    }
  };

  const renderCalualtedVaraibles = () => {
    if (Object.keys(calulatedVariables).length > 0) {
      return Object.keys(calulatedVariables).map((item, index) => {
        let tempVal = "";
        if (Array.isArray(calulatedVariables[item])) {
          tempVal = calulatedVariables[item].toString();
          tempVal = String(tempVal);
          tempVal = tempVal.replaceAll(",", ",  ");
        } else {
          tempVal = calulatedVariables[item];
        }
        return (
          <tr>
            <td>{item}</td>
            <td>{tempVal}</td>
          </tr>
        );
      });
    }
  };

  const renderGraphCalulatedData = () => {
    if (Object.keys(selectedGraph).length > 0 && Object.keys(calulatedVariables).length > 0) {
      return (
        <div className="col-12">
          <b>Vairables Used for Calulation</b>
          <br />
          <br />
          <div class="table-responsive">
            <table className="table table-bordered table-sm">
              <thead>
                <tr>
                  <th scope="col">Variable</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>{renderCalualtedVaraibles()}</tbody>
            </table>
          </div>
        </div>
      );
    }
  };

  const renderGraphTitle = () => {
    if (Object.keys(selectedGraph).length > 0) {
      return <div className="visualGraphTitle">{selectedGraph.graphDescription}</div>;
    }
  };

  const renderGraphErrorData = () => {
    if (Object.keys(selectedGraph).length > 0 && errorData.length > 0) {
      return (
        <div className="col-12">
          <b style={{ color: "red" }}>Missing Variables</b>
          <br />
          <ol>
            {errorData.map((data, index) => (
              <li key={index}>{data}</li>
            ))}
          </ol>
        </div>
      );
    }
  };

  const renderErrorMsg = () => {
    if (Object.keys(selectedGraph).length > 0 && errorData.length > 0) {
      return (
        <small style={{ textTransform: "none", color: "red" }}>
          Few of the graphs might not be genrated as required values are missing. Scroll to <b>Missing Varaible</b>{" "}
          section for more info.
        </small>
      );
    }
  };

  return (
    <>
      <div className="col-12">
        {renderGraphTypes()}
        {renderGraphTitle()}
        {renderErrorMsg()}
        {renderGraph()}
      </div>

      {renderGraphErrorData()}
      {renderGraphMetaData()}
      {renderGraphCalulatedData()}
    </>
  );
};

export default Graphs;
