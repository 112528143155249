
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {reaetSendRecordDataToBackEndReducer} from '../../axios/index'  
import {Link} from "react-router-dom";
import loader from '../../loader.gif';
import './index.css';
 
function SaveMaterialPopup(props) {  
  const dispatch = useDispatch();
  const [saveOptions, setSaveOptions] = React.useState(props.modal.saveType);
  const [statusType, setStatusType] = React.useState('inDraft');
  // const handleEvent = (status)=>{
  //   return props.handleEditStatus(status)
    
  // }
  // const handleSave = (e) =>{
  //   return props.handleSaveEvent(true)
  // }
const handleSaveEvent=(e)=>{
  return props.saveRecordEvent({saveType:saveOptions, statusType:statusType, module:props.modal.module})
}

const renderLoader = ()=>{
  if(props.recordUpdateApiStatus.loading===true){
    return(
      <div className='fixedLoader'><img src={loader} className='loaderr' /></div>
    )
  }
}
const renderCardBody = () =>{
  if(props.recordUpdateApiStatus.loading &&  props.recordUpdateApiStatus.responseType===''){
     
    let currentModuleeditStatus = '';
    if(props.moduleDefaultFieldDataSet[props.modal.module]!==undefined && props.moduleDefaultFieldDataSet[props.modal.module]['header']['status']==='Draft'){
      currentModuleeditStatus= 'draft'
    }
    
    return(
      <div className="card-body">
          <p>{props.modal.info}</p>
          <form>
            <div className="row">
              <div className="col-6 pr-1">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="saveOptions" id="radio1" value="new" checked = {saveOptions=='new'} onChange={(e)=>setSaveOptions(e.target.value)} />
                    <label class="form-check-label" for="radio1">
                    Save as New <span>{props.modal.module}</span>
                    </label>
                  </div>
                  {props.urlId!='-1'?(
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="saveOptions" id="radio2" value="newVersion"  checked = {saveOptions=='newVersion'} onChange={(e)=>setSaveOptions(e.target.value)} />
                    <label class="form-check-label" for="radio2">
                    Save as New <span>{props.modal.module}</span> Version
                    </label>
                  </div>
                  ):null}
                  {
                    currentModuleeditStatus==='draft'?(
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="saveOptions" id="radio2" value="update"  checked = {saveOptions=='update'} onChange={(e)=>setSaveOptions(e.target.value)} />
                        <label class="form-check-label" for="radio2">
                        Update current <span>{props.modal.module}</span> Version
                        </label>
                      </div>
                    ):null
                  }
                  
              </div>
              <div className="col-6 pr-1">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="statusType" id="radio3" value="inProduction"   checked = {statusType=='inProduction'} onChange={(e)=>setStatusType(e.target.value)} />
                    <label class="form-check-label" for="radio3">
                    Publish this <span>{props.modal.module} to <b>Production</b></span>
                    </label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="statusType" id="radio4" value="inDraft"    checked = {statusType=='inDraft'} onChange={(e)=>setStatusType(e.target.value)}/>
                    <label class="form-check-label" for="radio4">
                    Save this <span>{props.modal.module} to <b>Drafts</b></span>
                    </label>
                  </div>
              </div>
              
    
    
              
            </div> 
          </form>
          {renderLoader()}
        </div>
    )
  }
  else if(props.recordUpdateApiStatus.loading===false &&  props.recordUpdateApiStatus.responseType!==''){
    if(props.recordUpdateApiStatus.responseType==='Failed'){
      return(<div className="card-body">
             <div ClassName='failedMsg'>
                  <div className='failed'><i className='now-ui-icons ui-1_simple-remove'></i>&nbsp; {props.recordUpdateApiStatus.responseType}</div>
                  <div className='failedTxt'>{renderingTxt()}</div>
             </div>
      </div>)

    }
    else if(props.recordUpdateApiStatus.responseType==='success'){
      return(<div className="card-body">
             <div ClassName='failedMsg'>
                  <div className='successMsg'><i className='now-ui-icons ui-1_check'></i>&nbsp; {props.recordUpdateApiStatus.responseType}</div>
                  <div className='successTxt'>{renderingTxt2()}</div>
             </div>
      </div>)

    }
     
  }
}
const renderingTxt=()=>{
  let errtxt = 'Error';
  if(props.recordUpdateApiStatus.responseDesc.length>0){    
    Object.keys(props.recordUpdateApiStatus.responseDesc[0]).map((key)=>{
      errtxt = props.recordUpdateApiStatus.responseDesc[0][key]
    })
  }
 
  return(
    errtxt
  )
}
const renderingTxt2=()=>{
  let errtxt = 'Success';
  if(props.recordUpdateApiStatus.responseDesc!==''){
    
     
      errtxt = props.recordUpdateApiStatus.responseDesc
    }
  
 
  return(
    errtxt
  )
}

const renderFooterBtn =() =>{

  if(props.recordUpdateApiStatus.responseType==='' && props.recordUpdateApiStatus.loading==='initial'){
    return(<> <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    <button type="button" className="btn btn-primary" onClick={(e)=>handleSaveEvent(e)} ><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>)
  }
  else if(props.recordUpdateApiStatus.responseType==='Failed' && props.recordUpdateApiStatus.loading===false){
    return(
      <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Close</button>
    )
  }
  else if(props.recordUpdateApiStatus.responseType==='success' && props.recordUpdateApiStatus.loading===false){
    return(
      <button type="button" className="btn btn-primary"   onClick={(e)=>{props.closeModal(e)}} > &nbsp;Ok</button>
    )
  }
  else if(props.recordUpdateApiStatus.loading===true){
    return(<> <button type="button" className="btn btn-secondary"  disabled><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
    <button type="button" className="btn btn-primary" disabled><i className='now-ui-icons arrows-1_cloud-upload-94' ></i> &nbsp;Continue </button></>)
  }
}
  return (
    <div className="modal fade show" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel"><i className={props.modal.icon}></i> {props.modal.title}</h5>
            <button type="button" className="close"   aria-label="Close" onClick={(e)=>{props.closeModal(e)}}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="card downloadFile" style={{'boxShadow':'none'}}>
                  {renderCardBody()}                  
                </div>
          </div>
          <div className="modal-footer">
           {renderFooterBtn()}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SaveMaterialPopup;
