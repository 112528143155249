import React from "react";

const ToolOverview = () => {
  return (
    <div className="main-panel ps">
      <div className="content padd-top">
        <iframe
          title="Tool Overview"
          src="https://api.dev.nimblelab.app.kimclark.com/static/help.htm"
          style={{ width: "100%", height: "calc(100vh - 104px)" }}
        />
      </div>
    </div>
  );
};

export default ToolOverview;
