import React from "react";
import "./index.css";

function input({
  id,
  label,
  placeholder,
  onChange,
  value,
  infoComponent,
  required,
  type,
  maxlength,
  min,
  max
}) {
  return (
    <div>
      <div className="input">
        <div className="textLabel">
          <span>{label}</span>
          {required && <span className="required">*</span>}
          {infoComponent && infoComponent()}
        </div>
        <div>
          {type === "color" ? (
            <div className="color">
              <input
                id={id}
                name={id}
                class="form-control color-input"
                type="color"
                placeholder={placeholder}
                aria-label="default input example"
                onChange={onChange}
                value={value}
              />
              <span className="color-label">Selected Color: {value}</span>
            </div>
          ) : (
            <div>
              <input
                id={id}
                name={id}
                class="form-control"
                type={type ? type : "text"}
                placeholder={placeholder}
                aria-label="default input example"
                onChange={onChange}
                value={value}
                maxlength={maxlength ? maxlength : ""}
                step=".01"
                min={min}
                max={max}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default input;
