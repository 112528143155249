import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getCompressionData} from '../axios/index'  
import DataGrid from "../components/tables/dataGrid" 
import DefaultBreadCrumb from "../components/breadcrumb"
import PageHeader from "../components/header/pageHeader"


const CompressionProperties = () => {
    const dispatch = useDispatch();    
    //const data = useSelector((state)=> state.calculator);
    const data = useSelector((state)=> state.materialSearch);

    // const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    // const selectedSubRegion = useSelector((state) => state.updatedSubRegion)
    // const oktaAuth = useSelector((state) => state.oktaAuth);

    useEffect(() => {
        dispatch(getCompressionData());
    }, []);

       
    return (
        <div className="main-panel ps">
            <div className="content padd-top">
            <div className="row">
                <DefaultBreadCrumb  data={[{title:'Home',path:'/'}, {title:'Compression data',path:'/compression', active:true }]} />  
            </div> 
            <div className="row">
                <PageHeader  iconType ={"compression"} title = {" Manage Compression Data"} type='compression' path='compression-data' />
            </div>
            <div className="row">
                <DataGrid  tableData = {data}  enableLink={true} path='compression-data' />
            </div>
            </div>
     </div>
    );
}

export default CompressionProperties;