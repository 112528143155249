import {
  DragOutlined,
  ExclamationCircleTwoTone,
  LineChartOutlined,
  RetweetOutlined,
  SaveOutlined,
  ScissorOutlined,
  UndoOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  notification,
  Result,
  Select,
  Space,
  Spin,
  Switch,
  Tag,
  Tooltip,
  Typography,
  InputNumber,
} from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { initDB, useIndexedDB } from "react-indexed-db-hook";
import { useDispatch, useSelector } from "react-redux";

import { calculateAverage, getMaterialCurationById, uploadFinalJSON } from "../../axios";
import Chart from "./Chart";
import { DBConfig } from "./dbConfig";
import { symbols } from "../svg/symbols";
import LegendIcon from "./LegendIcon";

const { CheckableTag } = Tag;

initDB(DBConfig);

function Curation({ id, closeCuration }) {
  const dispatch = useDispatch();
  const {
    add: addAction,
    deleteRecord: deleteAction,
    getAll: getAllActions,
    clear: clearActions,
  } = useIndexedDB("graph_actions");

  const userInfo = useSelector((state) => state.userInfo);

  const moveOriginSuffix = " - MO";
  const clipSuffix = " - C";
  const smooth = true;
  const clip = false;
  const loadColor = "#FF0000"; // red
  const unloadColor = "#65B741"; // green
  const excludeColor = "#cccccc"; // grey
  const colors = useMemo(
    () => [
      "#0000FF", // Blue
      "#FFA500", // Orange
      "#800080", // Purple
      "#FF4E88", // pink
      "#543310", // brown
    ],
    []
  );
  const axisOptions = [
    { label: "Strain - Stress (kPa)", value: "STRAIN_STRESS" },
    { label: "Crosshead (cm) - Load (gf)", value: "CROSSHEAD_LOAD" },
  ];
  const [loading, setLoading] = useState(false);
  const [isValidID, setIsValidID] = useState(true);
  const [metadata, setMetadata] = useState({});
  const [rawJsonData, setRawJsonData] = useState({});
  const [graphData, setGraphData] = useState({
    STRAIN_STRESS: [],
    CROSSHEAD_LOAD: [],
  });
  const [dataPts, setDataPts] = useState([]);
  const [legendNames, setLegendNames] = useState([]);
  const [exCurves, setExCurves] = useState([]);
  const [exDataPts, setExDataPts] = useState([]);
  const [axes, setAxes] = useState("STRAIN_STRESS");
  const [clipEnabled, setClipEnabled] = useState(false);
  const [moveOriginEnabled, setMoveOriginEnabled] = useState(false);
  const [disableUndo, setDisableUndo] = useState(true);
  const [isAverageCurvePresent, setIsAverageCurvePresent] = useState(false);
  const [avgCurveData, setAvgCurveData] = useState([]);
  const [nresample, setNresample] = useState(20);
  const [cycles, setCycles] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState(1);

  const handleInputChange = (value) => {
    if (value !== null) {
      setNresample(value);
    }
  };

  const convertGraphDataToOriginalJSONFormat = (forAverage = false) => {
    const filteredDataPoints = dataPts.filter((item) => legendNames.includes(item.name) && item.data.length > 0);

    if (forAverage) {
      const specimen = filteredDataPoints.map((item) => {
        return {
          Stress_kPa: item.data.map((d) => d[1]),
          Strain: item.data.map((d) => d[0]),
        };
      });

      return specimen;
    }

    const specimen = filteredDataPoints.map((item, index) => {
      return {
        Stress_kPa: item.data.map((d) => d[1]),
        Strain: item.data.map((d) => d[0]),
        Load_gf: graphData.CROSSHEAD_LOAD[index].map((d) => d[1]),
        Crosshead_cm: graphData.CROSSHEAD_LOAD[index].map((d) => d[0]),
      };
    });

    return specimen;
  };

  const convertRawJsonToGraphArray = (rawJson) => {
    const strainStress = rawJson.map((data) => {
      return data.Stress_kPa.map((y, i) => [data.Strain[i], y]);
    });
    const crossheadLoad = rawJson.map((data) => {
      return data.Load_gf.map((y, i) => [data.Crosshead_cm[i], y]);
    });

    return {
      STRAIN_STRESS: strainStress,
      CROSSHEAD_LOAD: crossheadLoad,
    };
  };

  const convertAvgJsonToGraphArray = (data) => {
    const load = data.loadStress.map((y, i) => [data.loadStrain[i], y]);
    const unload = data.unloadStress.map((y, i) => [data.unloadStrain[i], y]);

    return {
      LOAD: load,
      UNLOAD: unload,
    };
  };

  const generateDataPoints = useCallback(
    (dataSource) => {
      const series = dataSource.map((data, index) => {
        return {
          name: "Spec " + (index + 1),
          type: "line",
          smooth,
          clip,
          data: data,
          itemStyle: {
            color: colors[index % colors.length],
            opacity: 0.75,
          },
          lineStyle: {
            width: 1,
          },
          symbol: symbols[index % symbols.length],
          symbolSize: 6,
        };
      });
      setDataPts(series);

      setLegendNames(series.map((s) => s.name));
    },
    [clip, colors, smooth]
  );

  const getSpecimentStressStrainObject = useCallback(
    (object, testMethod) => {
      if (testMethod.startsWith("STM-00437")) {
        setCycles(
          [...new Set(object.testData[0].CycleChannel.data)].map((item) => {
            return { label: item, value: item };
          })
        );
        return object.testData.map((o) => {
          const cycleStartIndex = o.CycleChannel.data.findIndex((cycle) => cycle === selectedCycle);
          const cycleEndIndex = o.CycleChannel.data.findLastIndex((cycle) => cycle === selectedCycle);

          return {
            Stress_kPa: o.Stress.data.slice(cycleStartIndex, cycleEndIndex),
            Strain: o.Strain.data.slice(cycleStartIndex, cycleEndIndex),
            Load_gf: [],
            Crosshead_cm: [],
          };
        });
      }

      return object.specimenStressStrain;
    },
    [selectedCycle]
  );

  const getData = useCallback(() => {
    setLoading(true);
    dispatch(getMaterialCurationById(id))
      .then((response) => {
        setLoading(false);
        if (response?.data?.responseData) {
          setMetadata(response.data.responseData.dataValues);
          setRawJsonData(response.data.responseData.rawJSON);

          const specimenStressStrain = getSpecimentStressStrainObject(
            response.data.responseData.rawJSON,
            response.data.responseData.dataValues.testMethod
          );
          const graphObject = convertRawJsonToGraphArray(specimenStressStrain);
          setGraphData(graphObject);

          generateDataPoints(graphObject.STRAIN_STRESS);
        } else {
          setIsValidID(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notification.open({
          message: "Invalid ID!",
          type: "error",
          duration: 2,
        });
        setLoading(false);
        setIsValidID(false);
      });
  }, [dispatch, generateDataPoints, getSpecimentStressStrainObject, id]);

  useEffect(() => {
    getData();

    return () => {
      clearActions();
    };
  }, [clearActions, getData]);

  useEffect(() => {
    if (axes === "STRAIN_STRESS") {
      clearActions().then(() => {
        setDisableUndo(true);
        setClipEnabled(false);
        setMoveOriginEnabled(false);
        setIsAverageCurvePresent(false);
        setExCurves([]);
      });
      generateDataPoints(graphData.STRAIN_STRESS);
    } else {
      clearActions().then(() => {
        setDisableUndo(true);
        setClipEnabled(false);
        setMoveOriginEnabled(false);
        setIsAverageCurvePresent(false);
        setExCurves([]);
      });
      generateDataPoints(graphData.CROSSHEAD_LOAD);
    }
  }, [axes, clearActions, generateDataPoints, graphData.CROSSHEAD_LOAD, graphData.STRAIN_STRESS]);

  const handleExCurveSelection = (tag, checked) => {
    const excluded = checked ? [...exCurves, tag] : exCurves.filter((t) => t !== tag);
    setExCurves(excluded);

    const matchCondition = (item) =>
      item.name === tag || item.name === tag + clipSuffix || item.name === tag + moveOriginSuffix;
    const unMatchCondition = (item) =>
      !(item.name === tag || item.name === tag + clipSuffix || item.name === tag + moveOriginSuffix);

    if (checked) {
      const updatedSeries = dataPts.filter(unMatchCondition);
      const excludedSpec = dataPts.filter(matchCondition);

      setDataPts(updatedSeries);
      setExDataPts([...exDataPts, ...excludedSpec]);
    } else {
      const includeSpec = exDataPts.filter(matchCondition);
      const excludedSpec = exDataPts.filter(unMatchCondition);

      setDataPts([...dataPts, ...includeSpec]);
      setExDataPts(excludedSpec);
    }
  };

  const moveDataPoints = (a, b) => {
    let result = [];

    a.forEach((e, i) => {
      result.push(e - b[i]);
    });

    return result;
  };

  const handleReset = () => {
    generateDataPoints(graphData.STRAIN_STRESS);

    clearActions().then(() => {
      setDisableUndo(true);
      setIsAverageCurvePresent(false);
      setExCurves([]);
    });
  };

  // logic for undo actions
  const handleUndo = async () => {
    const allActions = await getAllActions();
    if (allActions.length > 0) {
      if (allActions.length === 1) {
        // set data from graphData
        handleReset();
      } else {
        // set latest prev action from iDB
        const latestAction = allActions[allActions.length - 2];
        deleteAction(latestAction.id).then(() => {
          setDataPts(latestAction.data);
        });
      }
    } else {
      message.error("No actions to undo!");
    }
  };

  // logic for move origin
  const moveOrigin = (params) => {
    if (params.seriesName.endsWith(moveOriginSuffix)) {
      return;
    }

    // check for exisiting MO index
    const exisitingItem = dataPts.find((d) => d.name === params.seriesName + moveOriginSuffix);

    if (!exisitingItem) {
      // existing MO curve IS NOT PRESENT
      const movedDataPoints = dataPts[params.seriesIndex].data.map((d) => moveDataPoints(d, params.data));
      const zeroValueIndex = movedDataPoints.findIndex((mdp) => mdp[0] === 0 && mdp[1] === 0);
      const negativeDataPts = movedDataPoints.splice(0, zeroValueIndex);
      negativeDataPts.push([0, 0]);

      const updatedSeries = dataPts.map((item, index) => {
        // update the original item with moved points
        if (index === params.seriesIndex) {
          return { ...item, data: movedDataPoints };
        }
        return item;
      });

      updatedSeries.push({
        name: params.seriesName + moveOriginSuffix,
        type: "line",
        smooth,
        clip,
        data: negativeDataPts,
        itemStyle: {
          color: excludeColor,
        },
        lineStyle: {
          width: 1,
        },
        symbol: symbols[params.seriesIndex % symbols.length],
        symbolSize: 6,
      });

      addAction({ action: "MOVE_ORIGIN", data: updatedSeries }).then(() => {
        setDataPts(updatedSeries);
        setDisableUndo(false);
      });
    } else {
      // existing MO curve IS PRESENT
    }
  };

  // logic for clipping points
  const clipPoints = (params) => {
    if (params.seriesName.endsWith(clipSuffix)) {
      return;
    }

    // check for exisiting C index
    const exisitingItem = dataPts.find((d) => d.name === params.seriesName + clipSuffix);

    if (!exisitingItem) {
      // existing C curve IS NOT PRESENT
      const dataPoints = dataPts[params.seriesIndex].data.map((pt) => pt);
      const clippedPoints = dataPoints.splice(params.dataIndex);

      const updatedSeries = dataPts.map((item, index) => {
        // update the original item with moved points
        if (index === params.seriesIndex) {
          return { ...item, data: dataPoints };
        }
        return item;
      });

      updatedSeries.push({
        name: params.seriesName + clipSuffix,
        type: "line",
        smooth,
        clip,
        data: clippedPoints,
        itemStyle: {
          color: excludeColor,
        },
        lineStyle: {
          width: 1,
        },
        symbol: symbols[params.seriesIndex % symbols.length],
        symbolSize: 6,
      });

      addAction({ action: "CLIP", data: updatedSeries }).then(() => {
        setDataPts(updatedSeries);
        setDisableUndo(false);
      });
    } else {
      // existing MO curve IS PRESENT
    }
  };

  const onChartClick = (params) => {
    if (moveOriginEnabled) {
      moveOrigin(params);
    }

    if (clipEnabled) {
      clipPoints(params);
    }
  };

  const handleAverage = async () => {
    setLoading(true);
    setIsAverageCurvePresent(false);

    const jsonObject = {
      specimenStressStrain: convertGraphDataToOriginalJSONFormat(true),
    };

    const requestData = {
      type: metadata.testMethod,
      data: jsonObject,
      nresample: parseInt(nresample, 10),
    };

    dispatch(calculateAverage(requestData))
      .then((response) => {
        setLoading(false);
        setIsAverageCurvePresent(true);
        if (response?.data?.responseData?.[0]) {
          setAvgCurveData(response.data.responseData);
          const graphObject = convertAvgJsonToGraphArray(response.data.responseData[0]);
          const loadSeries = {
            name: "Load Average",
            type: "line",
            smooth,
            clip,
            data: graphObject.LOAD,
            itemStyle: {
              color: loadColor,
            },
            lineStyle: {
              width: 8,
            },
            symbolSize: 8,
          };
          const unloadSeries = {
            name: "Unload Average",
            type: "line",
            smooth,
            clip,
            data: graphObject.UNLOAD,
            itemStyle: {
              color: unloadColor,
            },
            lineStyle: {
              width: 8,
            },
            symbolSize: 8,
          };

          const updatedSeries = [...dataPts];
          updatedSeries.push(unloadSeries);
          updatedSeries.push(loadSeries);

          addAction({ action: "AVERAGE", data: updatedSeries }).then(() => {
            setDataPts(updatedSeries);
            setDisableUndo(false);
            setClipEnabled(false);
            setMoveOriginEnabled(false);
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error("Error:", error);
        notification.open({
          message: "Something went wrong!",
          description: error,
          type: "error",
          duration: 3,
        });
        setLoading(false);
        setIsAverageCurvePresent(false);
      });
  };

  const handleSave = () => {
    setLoading(true);

    const formData = new FormData();
    formData.append("curationId", id);
    formData.append("lastModifiedBy", userInfo.name);

    if (metadata.testMethod.startsWith("STM-00437")) {
      const finalJSON = {
        ...rawJsonData,
        average: avgCurveData,
        finalJSON: convertGraphDataToOriginalJSONFormat(),
        cycle: selectedCycle,
      };
      const jsonBlob = new Blob([JSON.stringify(finalJSON, null, 2)], {
        type: "application/json",
      });
      formData.append("file", jsonBlob);
    } else {
      const finalJSON = {
        average: avgCurveData,
        rawJSON: rawJsonData,
        finalJSON: convertGraphDataToOriginalJSONFormat(),
      };
      const jsonBlob = new Blob([JSON.stringify(finalJSON, null, 2)], {
        type: "application/json",
      });
      formData.append("file", jsonBlob);
    }

    const fileName = metadata.rawJSONPath.split("/")?.[2]?.replace("RAW", "CURATED") || "CURATED_JSON.json";
    const filePath = `${metadata.materialID}/${metadata.testMethod}/${fileName}`;
    formData.append("fileName", filePath.replace(/\s/g, "_"));

    dispatch(uploadFinalJSON(formData))
      .then(() => {
        message.success("Final JSON uploaded successfully!");
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error uploading data:", error);
        message.error("Failed to upload final JSON.");
        setLoading(false);
      });
  };

  return (
    <div>
      <Spin spinning={loading} size="large">
        <div style={{ height: "calc(100vh - 120px)", overflow: "auto" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Space wrap>
              <span>
                <Typography.Text strong>Test Method: </Typography.Text>
                <Typography.Text>{metadata.testMethod || "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Material ID: </Typography.Text>
                <Typography.Text>{metadata.materialID || "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Gauge Length: </Typography.Text>
                <Typography.Text>{metadata.length ? metadata.length + "mm" : "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Width: </Typography.Text>
                <Typography.Text>{metadata.width ? metadata.width + "mm" : "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Thickness: </Typography.Text>
                <Typography.Text>{metadata.thickness ? metadata.thickness + "mm" : "-"}</Typography.Text>
              </span>
            </Space>

            <Space wrap>
              <Tooltip
                title="Changing the Axes to 'Crosshead - Load' will reset the graph and will not let you perform any actions!"
                color="#8f6b02bf"
                placement="leftBottom"
              >
                <ExclamationCircleTwoTone size={8} twoToneColor="#faae14" />
              </Tooltip>
              <Typography.Text>Axes:</Typography.Text>
              <Select
                options={axisOptions}
                value={axes}
                onChange={(value) => setAxes(value)}
                size="small"
                disabled={isAverageCurvePresent || !isValidID}
              />

              {metadata?.testMethod?.startsWith("STM-00437") ? (
                <>
                  <Typography.Text>Cycle:</Typography.Text>
                  <Select
                    options={cycles}
                    value={selectedCycle}
                    onChange={(value) => setSelectedCycle(value)}
                    size="small"
                  />
                </>
              ) : (
                <></>
              )}

              <Tooltip title="Reset">
                <Button size="small" disabled={disableUndo || !isValidID} onClick={handleReset}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <RetweetOutlined />
                  </div>
                </Button>
              </Tooltip>
              <Tooltip title="Undo">
                <Button size="small" disabled={disableUndo || isAverageCurvePresent || !isValidID} onClick={handleUndo}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <UndoOutlined />
                  </div>
                </Button>
              </Tooltip>

              <Tooltip title="Clip">
                <Switch
                  checkedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ScissorOutlined />
                    </div>
                  }
                  unCheckedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ScissorOutlined />
                    </div>
                  }
                  checked={clipEnabled}
                  onChange={(checked) => {
                    setClipEnabled(checked);
                    if (checked) {
                      setClipEnabled(checked);
                      if (checked) {
                        setMoveOriginEnabled(!checked);
                      }
                    }
                  }}
                  disabled={axes === "CROSSHEAD_LOAD" || isAverageCurvePresent || !isValidID}
                />
              </Tooltip>
              <Tooltip title="Move Origin">
                <Switch
                  checkedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DragOutlined />
                    </div>
                  }
                  unCheckedChildren={
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <DragOutlined />
                    </div>
                  }
                  checked={moveOriginEnabled}
                  onChange={(checked) => {
                    setMoveOriginEnabled(checked);
                    if (checked) {
                      setMoveOriginEnabled(checked);
                      if (checked) {
                        setClipEnabled(!checked);
                      }
                    }
                  }}
                  disabled={axes === "CROSSHEAD_LOAD" || isAverageCurvePresent || !isValidID}
                />
              </Tooltip>

              <Typography.Text>Average:</Typography.Text>
              <Space.Compact size="small" style={{ width: "100%" }}>
                <InputNumber
                  size="small"
                  value={nresample}
                  onChange={handleInputChange}
                  style={{ width: 64 }}
                  min={5}
                  disabled={axes === "CROSSHEAD_LOAD" || isAverageCurvePresent || !isValidID}
                />
                <Button
                  type="primary"
                  onClick={handleAverage}
                  disabled={axes === "CROSSHEAD_LOAD" || isAverageCurvePresent || !isValidID}
                >
                  <LineChartOutlined />
                </Button>
              </Space.Compact>

              <Button type="primary" size="small" disabled={!isAverageCurvePresent || !isValidID} onClick={handleSave}>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                  <SaveOutlined /> <span>Save</span>
                </div>
              </Button>
            </Space>
          </div>

          <div
            style={{
              backgroundColor: "white",
              height: "calc(100% - 48px)",
              borderTop: "2px solid #06509e",
              marginTop: 8,
            }}
          >
            {isValidID ? (
              <>
                <div className="curation-legends">
                  {legendNames.map((tag, i) => (
                    <CheckableTag
                      key={tag}
                      checked={exCurves.indexOf(tag) > -1}
                      onChange={(checked) => handleExCurveSelection(tag, checked)}
                    >
                      <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                        <LegendIcon
                          index={i + 1}
                          style={{
                            fontSize: 16,
                            color: exCurves.indexOf(tag) > -1 ? "lightgrey" : colors[i % colors.length],
                          }}
                        />

                        <b>{tag}</b>
                      </div>
                    </CheckableTag>
                  ))}

                  {isAverageCurvePresent ? (
                    <>
                      <div style={{ display: "flex", alignItems: "center", gap: 4, marginRight: 8, cursor: "default" }}>
                        <LegendIcon
                          index={0}
                          style={{
                            fontSize: 16,
                            color: loadColor,
                          }}
                        />

                        <b>Load Average</b>
                      </div>
                      <div style={{ display: "flex", alignItems: "center", gap: 4, cursor: "default" }}>
                        <LegendIcon
                          index={0}
                          style={{
                            fontSize: 16,
                            color: unloadColor,
                          }}
                        />
                        <b>Unload Average</b>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
                <Chart axes={axes} legendNames={legendNames} data={dataPts} onChartClick={onChartClick} />
              </>
            ) : (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the curation you are looking for does not exist."
                extra={
                  <Button type="primary" onClick={closeCuration}>
                    Back to Test Methods
                  </Button>
                }
              />
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default Curation;
