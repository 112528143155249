import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import KpiCard from  '../components/kpiCard';
import AdminCard from '../components/kpiCard/adminCard';
import {getMaterialSummarydata, getSamSummaryData, getFiberSummaryData, getCalculatorReferenceData, getLabvantageReferenceData, getCompressionData} from '../axios/index'  
import {userRoles} from '../components/utils/userRoles'
const MaterialHome = (props) => {
     const dispatch = useDispatch();
     const materialSummary = useSelector((state)=>state.materialSummaryData)
     const samSummaryData = useSelector((state)=>state.samSummaryData)
     const fiberSummary = useSelector((state)=>state.fiberSummaryData)
     const calculatorSummary = useSelector((state)=>state.calculator)
     const labvantageApiSummary = useSelector((state)=>state.labvantage)
     const comrpessionData = useSelector((state) => state.materialSearch)
     const userInfo = useSelector((state) => state.userInfo)
     const [userRole, setUserRole] = useState();


    useEffect(() => {
        dispatch(getMaterialSummarydata());
        dispatch(getSamSummaryData());
        dispatch(getFiberSummaryData());
        dispatch(getCalculatorReferenceData());
        dispatch(getLabvantageReferenceData());
        dispatch(getCompressionData())
        
        // console.log("called")
    }, []);    

    useEffect(() => {
        if (userInfo.sAMAccountName) {
            console.log("userInfo ------>", userInfo)
            // Roles = ['']
            setUserRole(userRoles(userInfo.Roles))
            dispatch(getMaterialSummarydata({ user: userInfo.sAMAccountName }));
            dispatch(getSamSummaryData({ user: userInfo.sAMAccountName }));
            dispatch(getFiberSummaryData({ user: userInfo.sAMAccountName }));
        }
    }, [userInfo]);

    return (
        
            <div className="main-panel bg-gray">
                <div className="content bg-dark">    
                    <div className='row'>                      
                        {<KpiCard data = {{title:'Material', content:'This database of absorbent materials contains the material parameters required as inputs for the absorbent modeling tools.  These materials make up the various layers in our absorbent products.', cardType:'Summary',path:'/materials', icon:'material', bgColor:'#ffff',width:'30px', height:'30px', kpiData:materialSummary, userRole}} /> }
                        {<KpiCard data = {{title:'Fiber', content:'This database of fibers contains the detailed properties used to make up the material records in the material database.  A material can be made using one or more fiber from this database.', cardType:'Summary', path:'/fibers',icon:'fiber', bgColor:'#fff',width:'30px', height:'30px',kpiData:fiberSummary, userRole}} /> }
                        {<KpiCard data = {{title:'SAM', content:'This database of superabsorbent materials (SAM) contains the detailed properties used in the construction of material records.  A material may contain SAM from this database.  Any material containing SAM will have the detailed SAM properties in this database.', cardType:'Summary', path:'/sam',icon:'sam', bgColor:'#fff',width:'54px', height:'40px',kpiData:samSummaryData, userRole}} /> }       
                        <div className="pattern bottom"></div>                 
                    </div>                     
                </div>   
                { userRole == "NL_ADMIN" ? 
                    ( 
                        <div className="content-sub">
                            <div className="row">
                                <AdminCard data = {{title:'All', cardType:'Calulators', path:'/calculators',desc:"Onboarded Calulators", icon:'calculator', bgcolor:'#006fbb',  kpiData:calculatorSummary }} />
                                <AdminCard data = {{title:'APIs', cardType:'Labvantage', path:'/labvantage-apis', desc:"Labvantage APIs Onboarded", icon:'labvantageApis', bgcolor:'#006fbb', kpiData:labvantageApiSummary }} />
                                <AdminCard data = {{title:'Compression', cardType:'Data', path:'/compression-data', desc:"Compression data in system", icon:'compression',bgcolor:'#006fbb ',  kpiData:comrpessionData }} />
                            </div>
                        </div> 
                    )
                :   (
                        <div className="content-sub">
                            <div className="d-flex justify-content-center">
                                <AdminCard data = {{title:'Compression', cardType:'Data', path:'/compression-data', desc:"Compression data in system", icon:'compression',bgcolor:'#006fbb ',  kpiData:comrpessionData }} />
                            </div>
                        </div>
                    )   
                }
            </div>     
        
    );
}

export default MaterialHome;