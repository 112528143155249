export default (state = {isChecked : false}, action) => {
    switch (action.type) {
        case 'UPDATE_USER_MODEL':
            return action.payload;
            break
        case 'RESET_USER_MODEL':
            return {isChecked : false}
            break;
        default:
            return state;
    }
};