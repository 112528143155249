let intinalState = {
    loading: null,
    error: false,
    responseType: '',        
    message: '',
    data: {
        outputData: [],
        referenceData: [],
        chartTitle: {},
        outputChart: {},
        errors: {},
        dataSource:{},
        version:null,
        date:null,
    }
}
export default (
    state = intinalState, action) => {
    switch (action.type) {

        case 'FETCH_CALCULATOR_API_RESULT':
            console.log("FETCH_SAM_SEARCH_DATA ", action.payload.data)
            return {
                ...state,
                loading: false,
                responseType:action.payload.data.responseType,
                message:action.payload.data.responseDesc,
                data:action.payload.data.responseData,
            };        
    case 'RESET_CALCULATOR_API_RESULT':    
            return {...intinalState, loading:true};
    case 'FETCH_CALCULATOR_API_RESULT_FAILED':
        return {
            ...state,
            loading: false,
            responseType:action.payload.data.responseType,
            message:action.payload.data.responseDesc,
            data: {
                outputData: [],
                referenceData: [],
                chartTitle: {},
                outputChart: {},
                errors: {},
                dataSource:{},
                version:null,
                date:null,
            }
            
        }       
    case 'RESET_CALCULATOR_API_RESULT_INITIAL':
            return intinalState;        
        default:
            return state;

    }

};