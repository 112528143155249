const initialState = {
    loading: true,
    error: false,
    responseType: "",
    message: "",
    data: [] // Make sure data is initialized as an empty array
  }

const SvgUndoData = (state = initialState, action) => {
    switch (action.type) {
      case "UNDO_LAST_ACTION":
        let newStateForUpdatePart = {
          ...state,
          data: [
            ...state.data,
            {
              actionName: action.payload.actionName,
              coordinatePairs: action.payload.coordinatePairs,
              partIndex: action.payload.partIndex,
              svgPath: action.payload.svgPath,
              pointType: action.payload.pointType,
              connectionPairs: action.payload.connectionPairs
            }
          ]
        };
        return newStateForUpdatePart;
      
      case "DELETE_LAST_ACTION":
        let undoArray = [...state.data];  
        undoArray.pop()
        let newStateForUndo = {
          ...state,
          data: [...undoArray]
        };
        return newStateForUndo;

      case "DELETE_ALL_ACTIONS":
        let emptyUndoArray = {
          ...state,
          data: []
        };
        return emptyUndoArray;

      default:
        return state;
    }
  };

  export default SvgUndoData;
  