import cloneDeep from 'lodash/cloneDeep';
const partData = {
  action: {
    updated: false,
    type: 'new',
    delete: false,
  },
  data: {
    partName: '',
    partDescription: '',
    partType: '',
    PartArea: '',
    partZ: '',
    partDirection: '',
    PartCalulatedOrigin: '',
    partOriginMetaData: [],
    partSourceSvgPath: '',
    partTransformedSvgPath: '',
    partReference: {},
    partHoleReference: {},
    partSourceCoordinates: {},
    partTransformedCoordinates: {},
    partStatus: 0,
    partVisibility: 0,
  }
}

const configMetaData = {
  configId: '',
  configName: '',
  meshType: '',
  configDescription: '',
  configFile: '',
  configStatus: 'draft',
  configActive: 'false',
  createdBy: '',
  assemblyID: "",
  lastModifiedBy: '',
  configUnits: 'mm',
}




const intinalState = {
  loading: true,
  error: false,
  responseType: "",
  message: "",
  data: {
    configData: {
      type: "new",
      modified:false,
      metaData: configMetaData,
      partData: []
    }
  }
}


const SvgViewerPartData = (
  state = intinalState,
  action
) => {
  switch (action.type) {
    case "GET_SVG_PART_DATA_LOADING":
      return intinalState;
    case "UPDATE_SVG_PART_META_DATA":
      const  metaData = cloneDeep(state);
      metaData.data.configData.metaData[action.payload.key] = action.payload.val
      metaData.data.configData.modified = true;
      // metaData.data.configData.metaData.assemblyID = 'akshita1209new-2'
      return metaData;
    case "GET_SVG_PART_DATA":
      const partConfig  = cloneDeep(state);
      if(Object.keys(action.payload?.data?.responseData?.configData?.metaData).length===0){       
        partConfig.responseType = action.payload.data.responseType;
        partConfig.loading = false;
        partConfig.data.configData.metaData.assemblyID = action.payload.data.responseData.assemblyData.metaData.assemblyID
        return partConfig;
      }
      else{
        partConfig.responseType = action.payload.data.responseType;
        partConfig.loading = false;
        partConfig.data.configData = action.payload.data.responseData.configData
        return partConfig;
      }
       
  
    case "UPDATE_SVG_PART_DATA":
      const  updatePartList = cloneDeep(state)
      console.log("updatePartList: ", updatePartList)
      updatePartList.data.configData.modified = true;
      if(action.payload?.ranges!==undefined){
        updatePartList.data.configData.metaData.meshType = JSON.stringify(action.payload.ranges)
      }
      if (action.payload.type === 'partData') {
         
        updatePartList.data.configData.partData.push(action.payload.data)
        updatePartList.data.configData.modified = true;
        return updatePartList;
      }
      else if (action.payload.type === 'hole') {
        updatePartList.data.configData.partData[action.payload.target].data.partHoleReference.push(action.payload.data.data)
        updatePartList.data.configData.modified = true;
        return updatePartList;
      }
      else{ 
        return state;
      }

      case "UPDATE_SVG_PART_DATA_BY_MULTIPLE_KEY":
        const  partList = cloneDeep(state);
        if(action.payload.index!==undefined){
          if(action.payload?.ranges!==undefined){
            partList.data.configData.metaData.meshType = JSON.stringify(action.payload.ranges)
          }
          Object.keys(action.payload.data).map((key, index)=>{
            partList.data.configData.partData[action.payload.index].data[key] = action.payload.data[key];
          })
          partList.data.configData.partData[action.payload.index]['action']['updated'] = true;
          partList.data.configData.modified = true;
        }
        return partList;
        
      case "UPDATE_SVG_PART_DATA_BY_KEY":
        const  tempPartList = cloneDeep(state)
        console.log("tempPartList: ", tempPartList)
        if(action.payload.key === 'partStatus' && (tempPartList.data.configData?.lastSelecedPart===undefined)){
          tempPartList.data.configData.lastSelecedPart=action.payload;
      }
        else if(action.payload.key === 'partStatus' && (tempPartList.data.configData?.lastSelecedPart!==undefined)){
            let lastKeyData = tempPartList.data.configData.lastSelecedPart
            tempPartList.data.configData.partData[lastKeyData.index]['data'][lastKeyData.key] = false;
            tempPartList.data.configData.lastSelecedPart=action.payload;
        }
      
        tempPartList.data.configData.partData[action.payload.index]['data'][action.payload.key] = action.payload.data;
        tempPartList.data.configData.partData[action.payload.index]['action']['updated'] = true;
        tempPartList.data.configData.modified = true;
        return tempPartList;
      
      case "UPDATE_ALL_SVG_PARTS_DATA":
        const allParts = cloneDeep(state);
        console.log("allParts: ", allParts)
        allParts.data.configData.partData.forEach((part, partIndex)=>{
          if(part.data.partStatus == true){
            allParts.data.configData.partData[partIndex].data.partStatus = false
          }
        })
        return allParts;

      case "UPDATE_VISIBILITY_ALL_SVG_PARTS_DATA":
        const allPartsV = cloneDeep(state);
        console.log("allPartsV: ", allPartsV)
        allPartsV.data.configData.partData.forEach((part, partIndex)=>{
        
          allPartsV.data.configData.partData[partIndex].data.partVisibility = action.payload.visibility 
        
        })
        return allPartsV;

      case "DELETE_SVG_PART_DATA_BY_INDEX":   
      // dispatch(deletePart({index:index, type:'delete'}))
            const  deletePartList = cloneDeep(state);
            if(action.payload.type==='delete' && deletePartList.data?.configData?.partData[action.payload.index]?.action?.type==='new'){
              deletePartList.data.configData.partData.splice(action.payload.index , 1)
             // delete  deletePartList.data.configData.partData[action.payload.index]
             
              delete  deletePartList.data.configData.lastSelecedPart
            }
            else if(action.payload.type==='delete' && deletePartList.data?.configData?.type!=='new'){
              if(deletePartList.data.configData.partData[action.payload.index]['action']['type']==='existing'){
                deletePartList.data.configData.partData[action.payload.index]['action']['delete'] = true;
                deletePartList.data.configData.partData[action.payload.index]['action']['updated'] = true;
                delete  deletePartList.data.configData.lastSelecedPart
              }
            }
            deletePartList.data.configData.modified = true;
            return deletePartList;

     case "UPDATE_SVG_PART_DATA_BY_API":
      const  tempP = cloneDeep(state)      
      tempP.responseType = action.payload.data.responseType;
      tempP.responseDesc =action.payload.data.responseDesc
      tempP.loading = false;
      tempP.data.configData = action.payload.data.responseData
      tempP.data.configData.type = 'existing';
      tempP.data.configData.modified = false;
      return tempP;
    case "SET_SVG_PART_DATA_LOADING":
        return {
          ...state,
          loading: true,
          inintal:true,
          error: false,
          responseType: "",
          message: "",
                
        };
        case "RESET_SVG_PART_DATA_LOADING":
          return {
            ...state,
            loading: false,
            inintal:true,
            error: false,
            responseType: "",
            message: "",
                  
          };
              
    case "GET_SVG_PART_DATA_FAILED":
      return {
        ...state,
        loading: false,
        responseType: action.payload.response.data.responseType,
        responseDesc:action.payload.response.data.responseDesc,
        error: action.payload.response.data.responseDesc,
      };

    default:
      return state;
  }
};

export default SvgViewerPartData;
