const AssemblyJsonTableData = (
  state = {
    loading: true,
    error: false,
    responseType: "",
    message: "",
    data: {},
  },
  action
) => {
  switch (action.type) {
    case "GET_SOURCE_JSON_DATA_LOADING":
      return {
        ...state,
        loading: true,
        responseType: "",
        data: {},
      };

    case "GET_SOURCE_JSON_DATA":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        data: action.payload.data.responseData,
      };
    case "GET_SOURCE_JSON_DATA_FAILED":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        error: action.payload.data.responseData,
      };

    default:
      return state;
  }
};

export default AssemblyJsonTableData;
