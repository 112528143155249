import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getFiberData } from "../axios/index";
import DataGrid from "../components/tables/dataGrid";
import DefaultBreadCrumb from "../components/breadcrumb";
import PageHeader from "../components/header/pageHeader";
import { userRoles } from "../components/utils/userRoles";

const Fibers = () => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.fiber);
  const userInfo = useSelector((state) => state.userInfo);
  const myModel = useSelector((state) => state.userModel);
  const latestVersion = useSelector((state) => state.latestVersion);
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    dispatch(
      getFiberData({
        ...(myModel.isChecked && {
          myModels: userInfo.sAMAccountName,
        }),
        ...(latestVersion.isLatestVersionChecked && {
          latestVersion: latestVersion.isLatestVersionChecked,
        }),
      })
    );

    if (userInfo.sAMAccountName) {
      setUserRole(userRoles(userInfo.Roles));
    }
  }, [userInfo, myModel.isChecked, latestVersion.isLatestVersionChecked, dispatch]);

  return (
    <div className="main-panel ps">
      <div className="content padd-top">
        <div className="row">
          <DefaultBreadCrumb
            data={[
              { title: "Home", path: "/" },
              { title: "Fibers", path: "/fibers", active: true },
            ]}
          />
        </div>
        <div className="row">
          <PageHeader iconType={"fiber"} title={"Manage Fibers"} path="new-fiber" type="Fiber" userRole={userRole} />
        </div>
        <div className="row">
          <DataGrid tableData={data} path="fiber-details" enableLink={true} />
        </div>
      </div>
    </div>
  );
};

export default Fibers;
