

const intinalState = {
  loading: true,
  error: false,
  responseType: "",
  message: "",
  data:[]
}



const PartCategories = (
  state =  intinalState,
  action
) => {
  switch (action.type) {
    case "GET_PART_CATEGORIES_DATA_LOADING":
      return intinalState;

    case "GET_PART_CATEGORIES_DATA":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        data: action.payload.data.responseData,
      };
    case "GET_PART_CATEGORIES_DATA_FAILED":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
       error: action.payload.data.responseData,
      };

    default:
      return state;
  }
};

export default PartCategories;
