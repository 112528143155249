import cloneDeep from 'lodash/cloneDeep';

const intinalState = {
  loading: true,
  error: false,
  responseType: "",
  message: "",
  data:{all:true, others:{} }
}



const SvgLayerVisibility = (
  state =  intinalState,
  action
) => {
  switch (action.type) {
    case "SVG_RESET_LAYERS_VISIBILITY":
      return intinalState;

    case "SVG_UPDATE_LAYERS_VISIBILITY":
      const  layers = cloneDeep(state)
      if(action.payload.type==='all'){
        if(layers.data.all===true){
          layers.data.all = false;
          layers.data.others = action.payload.data;
        }
        else{
          layers.data.all = true;
          layers.data.others = {};
        }
        
      }
      else if(action.payload.type==='single' && layers.data.others[action.payload.key]!==undefined){
        delete layers.data.others[action.payload.key]        
        layers.data.all = true  
      }  
      else if(action.payload.type==='single' && layers.data.others[action.payload.key]===undefined){
        layers.data.others[action.payload.key] = false
        layers.data.all = true;
      }   
      return layers;   

    case "SVG_UPDATE_LAYERS_VISIBILITY_FOR_AREA_SELECTION":
      const layersForAreaSelection = cloneDeep(state);
      layersForAreaSelection.data.others = {...layersForAreaSelection.data.others, ...action.payload.data}
      return layersForAreaSelection;
      
    default:
      return state;
  }
};

export default SvgLayerVisibility;
