import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import MaterialSummaryData from "../reducers/home/materialData";
import SamSummaryData from "../reducers/home/samData";
import FiberSummaryData from "../reducers/home/fiberData";
import LabvantageApiData from "../reducers/materials/labvantage";
import Materials from "../reducers/materials/materials";
import SAMs from "../reducers/materials/sam";
import Fibers from "../reducers/materials/fiber";
import Calculators from "../reducers/materials/calculator";
import MaterailsDetails from "../reducers/materials/materialDetails";
import searchKey from "../reducers/materials/searchKey";
import UserInfo from "../reducers/user/user";
import UserModel from "../reducers/user/userModel";
import LatestVersion from "../reducers/materials/latestVersion";
import RecordUpdateApi from "../reducers/materials/recordUpdateApi";
import DeleteRecordApi from "../reducers/materials/deleteRecordApi";
import MaterialSearch from "../reducers/search/materialSearch";
import SamSearch from "../reducers/search/samSearch";
import FiberSearch from "../reducers/search/fiberSearch";
import fiberSearch from "../reducers/search/fiberSearch";
import queryIdForSelectedProperty from "../reducers/search/labvantage";
import calulatorApiResult from "../reducers/search/calulatorApiResult";
import CompressionData from "../reducers/materials/compression";
import DefaultMetaDataFields from "../reducers/materials/defaultMetaDataFields";
import GraphData from "../reducers/materials/graph";

/////////////////PHASE 2///////////////////
import ProductDBScreenData from "../reducers/geometry/productDBScreen";
import AssemblyJsonTableData from "../reducers/geometry/assemblyJsonTable";
import UploadFileData from "../reducers/geometry/UploadFile";
import UploadFieldsOptions from "../reducers/geometry/uploadFieldsOptions";
import SourceScreenData from "../reducers/source/sourceScreen";
import ConfigTableData from "../reducers/geometry/configTable";
import SourceIdTableData from "../reducers/geometry/sourceIdTable";
import JsonIdTableData from "../reducers/geometry/jsonIdTable";
import RequestJson from "../reducers/geometry/requestJson";
import AdminDashboardScreenData from "../reducers/adminDashboard/adminScreenDetails";
// import PartNamesTableData from "../reducers/adminDashboard/partNamesTable";
import AdminTableData from "../reducers/adminDashboard/adminTableData";
import SvgViewerData from "../reducers/svgViewer/svgViewerData";
import SvgViewerPartData from "../reducers/svgViewer/svgViewerPartData";
import SvgSelectedLayers from "../reducers/svgViewer/svgSelectedLayers"
import PartCategories from "../reducers/svgViewer/partCategories"
import SvgLayerVisibility from "../reducers/svgViewer/svgLayerVisibility";
import SvgUndoData from "../reducers/svgViewer/svgUndoData";
// import
// export default configureStore({
//   reducer: {
//     materialSummaryData: MaterialSummaryData,
//     samSummaryData: SamSummaryData,
//     fiberSummaryData: FiberSummaryData,
//     calculatorData: CalculatorData,

//   }

// })

const reducer = {
  materialSummaryData: MaterialSummaryData,
  samSummaryData: SamSummaryData,
  fiberSummaryData: FiberSummaryData,

  labvantage: LabvantageApiData,
  materialsData: Materials,
  sam: SAMs,
  fiber: Fibers,
  calculator: Calculators,
  MaterailsDetail: MaterailsDetails,

  searchKey: searchKey,

  userInfo: UserInfo,
  userModel: UserModel,
  latestVersion: LatestVersion,
  recordUpdateApi: RecordUpdateApi,
  deleteRecordApi: DeleteRecordApi,
  materialSearch: MaterialSearch,
  fiberSearch: FiberSearch,
  samSearch: SamSearch,
  QueryIdForSelectedProperty: queryIdForSelectedProperty,
  CalulatorApiResult: calulatorApiResult,
  compressionData: CompressionData,
  defaultMetaDataFields: DefaultMetaDataFields,
  graphData: GraphData,
  ProductDBScreenData: ProductDBScreenData,
  AssemblyJsonTableData: AssemblyJsonTableData,
  UploadFileData: UploadFileData,
  UploadFieldsOptions: UploadFieldsOptions,
  SourceScreenData: SourceScreenData,
  ConfigTableData: ConfigTableData,
  SourceIdTableData: SourceIdTableData,
  JsonIdTableData: JsonIdTableData,
  RequestJson: RequestJson,
  AdminDashboardScreenData: AdminDashboardScreenData,
  // PartNamesTableData: PartNamesTableData,
  AdminTableData: AdminTableData,
  svgViewerData: SvgViewerData,
  svgViewerPartData:SvgViewerPartData,
  svgSelectedLayers:SvgSelectedLayers,
  partCategories:PartCategories,
  svgLayerVisibility:SvgLayerVisibility,
  svgUndoData:SvgUndoData,
};

export default configureStore({
  reducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
