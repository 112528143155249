import React from "react";
import SvgComponent from "../../../icons/svgComponent";
import "./index.css";

function PageHeaderTitle(props) {
  return (
    <div className="header-title-txt">
      <SvgComponent
        type={props.metaData.iconType}
        width={"32px"}
        height={"32px"}
        background={"transparent"}
      />

      {props.metaData.pageTitle}
    </div>
  );
}

export default PageHeaderTitle;
