import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getCalculatorReferenceData} from '../axios/index'  
import DataGrid from "../components/tables/dataGrid" 
import DefaultBreadCrumb from "../components/breadcrumb"
import PageHeader from "../components/header/pageHeader"


const Calculators = () => {
    const dispatch = useDispatch();    
    const data = useSelector((state)=> state.calculator);
    // const [subRegion, setSubRegion] = React.useState('');
    // const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    // const selectedSubRegion = useSelector((state) => state.updatedSubRegion)
    // const oktaAuth = useSelector((state) => state.oktaAuth);

    useEffect(() => {
         dispatch(getCalculatorReferenceData());
    }, []);

       
    return (
        <div className="main-panel ps">
            <div className="content padd-top">
            <div className="row">
                <DefaultBreadCrumb data={[{title:'Home',path:'/'}, {title:'Calculators',path:'/calculators', active:true }]} />  
            </div> 
            <div className="row">
                <PageHeader  iconType ={"calculator"} title = {" All Calculators"}  />
            </div>
            <div className="row">
                <DataGrid  tableData = {data} path ='calculator-details'/>
            </div>
            </div>
     </div>
    );
}

export default Calculators;