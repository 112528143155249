import React from "react";
import "./index.css";

function ErrorMsg({ message }) {
  return (
    <div className="error">
      <h6>{message}</h6>
    </div>
  );
}

export default ErrorMsg;
