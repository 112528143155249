import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Drawer, notification, Popconfirm, Space, Table, Typography } from "antd";
import { CloseOutlined, DeleteOutlined, DownloadOutlined, EyeTwoTone, LineChartOutlined } from "@ant-design/icons";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";

import Curation from "../materialCuration/Curation";
import CurationForm from "../materialCuration/CurationForm";
import { downloadSpecimenFile, getMaterialTestMethods, deleteTestMethodFiles } from "../../axios";
import CompressionCurationForm from "../materialCuration/CompressionCurationForm";
import ViewCuration from "../materialCuration/ViewCuration";

function TestMethods({ materialId, materialName, materialDescription }) {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openViewCurationDrawer, setOpenViewCurationDrawer] = useState(false);
  const [currentCurationId, setCurrentCurationId] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  const getData = useCallback(() => {
    setLoading(true);

    if (materialId) {
      dispatch(getMaterialTestMethods(materialId))
        .then((response) => {
          setLoading(false);
          if (response?.data?.responseData) {
            setData(response.data.responseData);
          } else {
            setData([]);
          }
        })
        .catch((error) => {
          console.error("Error: ", error);
          setData([]);
          setLoading(false);
        });
    }
  }, [dispatch, materialId]);

  useEffect(() => {
    getData();
  }, [getData]);

  const showDrawer = (curationId) => {
    setCurrentCurationId(curationId);
    setOpen(true);
  };

  const handleDrawerClose = () => {
    getData();
    setOpen(false);
  };

  const showViewCurationDrawer = (curationId) => {
    setCurrentCurationId(curationId);
    setOpenViewCurationDrawer(true);
  };

  const downloadFile = async (filePath) => {
    const key = `open${Date.now()}`;

    notification.open({
      message: "File downdload is in progress!",
      type: "info",
      key,
    });

    dispatch(downloadSpecimenFile(filePath))
      .then((response) => {
        const blob = new Blob([response.data], { type: "text/csv" });
        saveAs(blob, filePath.split("/").pop()); // Uses file-saver to download file, could use the last part of the path as the filename
        notification.close(key);
      })
      .catch((error) => {
        console.error("Download error:", error);
        notification.close(key);
        notification.open({
          message: "File downdload failed!",
          type: "error",
          duration: 2,
        });
      });
  };

  const handleDelete = (id, materialId) => {
    const formattedId = id.replace(/\s+/g, " ");
    dispatch(deleteTestMethodFiles(formattedId, materialId))
      .then(() => {
        notification.success({
          message: "Test Method Deleted",
          description: "The test method has been successfully deleted.",
        });
        getData();
      })
      .catch((error) => {
        console.error("Delete error:", error);
        notification.error({
          message: "Delete Failed",
          description: "There was an issue deleting the test method.",
        });
      });
  };

  const columns = [
    {
      title: "Test Method",
      dataIndex: "id",
      key: "id",
      render: (text, record) => (
        <Typography.Text strong>
          {text} ({record.name})
        </Typography.Text>
      ),
      fixed: "left",
    },
    {
      title: "Name",
      dataIndex: "curationName",
      key: "curationName",
      render: (text) => text || "-",
    },
    {
      title: "Description",
      dataIndex: "curationDescription",
      key: "curationDescription",
      width: 320,
      render: (text) => text || "-",
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (text) => text || "-",
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => (text ? new Date(text).toLocaleDateString("en-US") : "-"),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "id",
      fixed: "right",
      render: (_, record) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            {record.name !== "Compression" ? (
              <>
                {!record?.csvFilePath ? (
                  <CurationForm
                    testMethodId={record.id}
                    defaulValues={{
                      materialId,
                      materialName,
                      materialDescription,
                      ...record
                    }}
                    onFormSuccess={() => getData()}
                  />
                ) : (
                  <>
                    <Button
                      type="ghost"
                      size="small"
                      disabled={!record?.sampleFilePath}
                      onClick={() => downloadFile(record?.sampleFilePath)}
                    >
                      <Space size={4}>
                        <DownloadOutlined /> <span>Sample</span>
                      </Space>
                    </Button>
                    <Button
                      type="ghost"
                      size="small"
                      disabled={!record?.csvFilePath}
                      onClick={() => downloadFile(record?.csvFilePath)}
                    >
                      <Space size={4}>
                        <DownloadOutlined /> <span>CSV</span>
                      </Space>
                    </Button>
                    <Button
                      type="ghost"
                      size="small"
                      disabled={!record?.rawJSONPath}
                      onClick={() => downloadFile(record?.rawJSONPath)}
                    >
                      <Space size={4}>
                        <DownloadOutlined /> <span>Raw JSON</span>
                      </Space>
                    </Button>
                  </>
                )}
              </>
            ) : !record?.rawJSONPath ? (
              <CompressionCurationForm
                testMethodId={record.id}
                defaulValues={{
                  materialId,
                  materialName,
                  materialDescription,
                  defaultGaugeLength: record.defaultGaugeLength,
                  defaultWidth: record.defaultWidth,
                  defaultThickness: record.defaultThickness,
                }}
                onFormSuccess={() => getData()}
              />
            ) : (
              <Button
                type="ghost"
                size="small"
                disabled={!record?.rawJSONPath}
                onClick={() => downloadFile(record?.rawJSONPath)}
                style={{ width: 282 }}
              >
                <Space size={4}>
                  <DownloadOutlined /> <span>Raw JSON</span>
                </Space>
              </Button>
            )}

            <Button
              type="primary"
              size="small"
              disabled={!record?.rawJSONPath}
              onClick={() => showDrawer(record.curationId)}
            >
              <Space size={4}>
                <LineChartOutlined /> <span>Curate</span>
              </Space>
            </Button>
            <Button
              type="ghost"
              size="small"
              disabled={!record?.finalJSONPath}
              onClick={() => downloadFile(record?.finalJSONPath)}
            >
              <Space size={4}>
                <DownloadOutlined /> <span>Finalized JSON</span>
              </Space>
            </Button>

            {record?.finalJSONPath && (
              <Button
                size="small"
                disabled={!record?.finalJSONPath}
                onClick={() => showViewCurationDrawer(record.curationId)}
                title="View Curation"
              >
                <Space size={4}>
                  <EyeTwoTone />
                </Space>
              </Button>
            )}

            {record?.rawJSONPath && (
              <Popconfirm
                title="Are you sure to delete the curation for this test method?"
                onConfirm={() => handleDelete(record.id, materialId)}
                okText="Yes"
                cancelText="No"
              >
                <Button type="default" danger size="small" title="Delete">
                  <Space size={4}>
                    <DeleteOutlined />
                  </Space>
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
  ];

  if (!materialId) {
    return (
      <div style={{ width: "100%", marginTop: 12 }}>
        <Alert
          message="Test Data"
          description="Test Method data will be available after creating the material!"
          type="warning"
          showIcon
        />
      </div>
    );
  }

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        loading={loading}
        rowKey="key"
        scroll={{ x: "max-content" }}
        pagination={false}
        style={{ borderTop: "2px solid #06509e", marginTop: 16, width: "100%" }}
      />

      <Drawer
        key="Curation"
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Curation</span>
            <Button type="text" icon={<CloseOutlined />} onClick={handleDrawerClose} style={{ marginLeft: "auto" }} />
          </div>
        }
        width="100vw"
        placement="right"
        open={open}
        destroyOnClose={true}
        closable={false}
      >
        <Curation id={currentCurationId} closeCuration={handleDrawerClose} />
      </Drawer>

      <Drawer
        key="View Curation"
        title={
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <span>Curation</span>
            <Button
              type="text"
              icon={<CloseOutlined />}
              onClick={() => setOpenViewCurationDrawer(false)}
              style={{ marginLeft: "auto" }}
            />
          </div>
        }
        width="100vw"
        placement="right"
        open={openViewCurationDrawer}
        destroyOnClose={true}
        closable={false}
      >
        <ViewCuration id={currentCurationId} closeCuration={() => setOpenViewCurationDrawer(false)} />
      </Drawer>
    </>
  );
}

export default TestMethods;
