
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux"

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams, useSearchParams
} from "react-router-dom";
import loader from '../../loader.gif';
import './index.css';


const  SignInError=()=> {
 
//const renderSignInErrorMsg =()=>{
//   let releativeLink = window.location.href;
//  let originLink=window.location.origin
//  originLink = originLink+'/'
//   console.log("authState Z 1", urlParams)
//   console.log("releativeLink",authState, originLink,releativeLink)
//     if(isUserAuthenticated===true &&  originLink!==releativeLink){
//         return(
//             <div className='main-panel ps'>
//             <div className='content padd-top'>
//                 <div className='signInError'>
//                     <h5>Error!!</h5>
//                     <br/>
//                     <p>
//                        You do not have access to this application, please reachout to <a href = "mailto:andrew.t.baker@kcc.com?subject = AccessRequest&body = Message">Andrew T Baker <i>andrew.t.baker@kcc.com</i></a> for more information.
//                     </p>
//                 </div>
//             </div>

//       </div> 
//         )
//     }
    // else if(!authState &&  originLink===releativeLink){
    //   return(
    //     <div className='main-panel ps'>
    //         <div className='content padd-top'>
    //             <div className='waitingForUserInfo'>
    //                 <h5>Fetching user details...</h5>
    //                 <br/>
    //                 <img src={loader} className='loaderr' />
    //             </div>
    //         </div>

    //   </div> 
    //   )
    // }
     
//}
 


return(
  <div className='main-panel ps'>
  <div className='content padd-top'>
      <div className='signInError'>
          <h5>Error!!</h5>
          <br/>
          <p>
             You do not have access to this application, please reachout to <a href = "mailto:andrew.t.baker@kcc.com?subject = AccessRequest&body = Message">Andrew T Baker <i>andrew.t.baker@kcc.com</i></a> for more information.
          </p>
      </div>
  </div>

</div> 
)
}
export default SignInError;
