import ReactEcharts from "echarts-for-react";
import { LineChart } from "echarts/charts";
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from "echarts/components";
import * as echarts from "echarts/core";
import { UniversalTransition } from "echarts/features";
import { SVGRenderer } from "echarts/renderers";
import React from "react";

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  LineChart,
  SVGRenderer,
  UniversalTransition,
  DataZoomComponent,
  LegendComponent,
  VisualMapComponent,
  ToolboxComponent,
]);

function Chart({ axes, legendNames, data, onChartClick , showLegend = false}) {
  const onEvents = {
    click: onChartClick,
  };

  return (
    <ReactEcharts
      echarts={echarts}
      opts={{ renderer: "svg" }}
      option={{
        tooltip: {
          formatter: function (params) {
            var data = params.data || [0, 0];
            return `<b style='color: ${params.color}'>${params.seriesName}</b><br>` + data[0] + ", " + data[1];
          },
        },
        toolbox: {
          show: true,
          top: 8,
          right: 20,
          feature: {
            dataZoom: {},
            restore: {},
            saveAsImage: {},
          },
        },
        legend: {
          show: showLegend,
          data: legendNames,
          top: 8,
          selectedMode: false,
        },
        grid: {
          containLabel: true,
          left: 28,
          right: 24,
          top: 48,
          bottom: 36,
        },
        xAxis: {
          name: axes === "STRAIN_STRESS" ? "Strain" : "Crosshead (cm)",
          type: "value",
          minorTick: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
          nameLocation: "middle",
          nameGap: 36,
        },
        yAxis: {
          name: axes === "STRAIN_STRESS" ? "Stress (kPa)" : "Load (gf)",
          type: "value",
          minorTick: {
            show: true,
          },
          minorSplitLine: {
            show: true,
          },
          nameLocation: "middle",
          nameGap: 36,
        },
        dataZoom: [
          {
            type: "inside",
            xAxisIndex: [0],
            filterMode: "none",
          },
          {
            type: "inside",
            yAxisIndex: [0],
            filterMode: "none",
          },
        ],

        series: data,
        animation: false,
      }}
      onEvents={onEvents}
      lazyUpdate={true}
      style={{ height: "100%", width: "auto" }}
    />
  );
}

export default Chart;
