import React from "react";
import "./index.css";
import { Input } from "antd";

function Description({ moduleName, moduleFieldDataSet, editStatus, handleHeaderDataUpdate }) {
  const renderDescription = () => {
    if (moduleFieldDataSet[moduleName] !== undefined && moduleFieldDataSet[moduleName]["header"] && !editStatus) {
      return (
        <>
          <div className="field">
            <div className="fieldLabel">
              <span>{moduleName}</span> Description-{" "}
            </div>
            <div className="fieldInput">&nbsp;{moduleFieldDataSet[moduleName]["header"]["description"]}</div>
          </div>
          <div className="field">
            <div className="fieldLabel">Author- </div>
            <div className="fieldInput">&nbsp;{moduleFieldDataSet[moduleName]["header"]["author"]}</div>
          </div>
          <div className="field">
            <div className="fieldLabel">Version- </div>
            <div className="fieldInput">&nbsp;{moduleFieldDataSet[moduleName]["header"]["version"]}</div>
          </div>
          <div className="field">
            <div className="fieldLabel">Status- </div>
            <div className="fieldInput">&nbsp;{moduleFieldDataSet[moduleName]["header"]["status"]}</div>
          </div>
        </>
      );
    } else if (moduleFieldDataSet[moduleName] !== undefined && moduleFieldDataSet[moduleName]["header"] && editStatus) {
      return (
        <div className="otherFields">
          <div>
            <div className="field">
              <div className="fieldLabel col-2">
                <span>{moduleName}</span> Vendor -{" "}
              </div>
              <Input
                type="text"
                size="large"
                style={{ width: "30%" }}
                value={moduleFieldDataSet[moduleName]["header"]["vendor"]}
                onChange={(e) => handleHeaderDataUpdate({ e: e.target.value, key: "vendor", module: moduleName })}
              />
            </div>
            <br />
            <div className="field">
              <div className="fieldLabel col-2 row-3">
                <span>{moduleName}</span> Description -{" "}
              </div>
              <Input.TextArea
                size="large"
                style={{ width: "50%" }}
                value={moduleFieldDataSet[moduleName]["header"]["description"]}
                onChange={(e) => handleHeaderDataUpdate({ e: e.target.value, key: "description", module: moduleName })}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  return <div className="col-12 moduleDescription">{renderDescription()}</div>;
}

export default Description;
