import React from "react";
import {
  BrowserRouter,
  Switch,
  Routes,
  Navigate ,
  Route,
  Link,
  useNavigate,
} from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from "@okta/okta-react";
import { RequiredAuth } from "./securedRoutes";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import EmptyPage from "../../pages/home";

import Loading from './loading';

export default function DefaultRoutes() {

  return (
    <Routes>
      <Route  path="/"  element={<RequiredAuth />}  >
            <Route path=''  element={<EmptyPage />} />
          </Route>   
        <Route path="callback" element={<LoginCallback  loadingElement={<Loading/>} />} />
    </Routes>
  );
}