import React from 'react';

const CleanLG = (props) => {
  return (
    <svg
      width="64mm"
      height="64mm"
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="layer1">
        <path
          style={{ fill: 'none', stroke: '#000000', strokeWidth: 1.5 }}
          d="M 9.6743858,59.828632 7.0836211,56.909496 16.689473,18.94034"
        />
        <path
          style={{ fill: '#ffffff', stroke: '#000000', strokeWidth: 1.6 }}
          d="M 8.0222089,57.046817 H 56.868546"
        />
      </g>
      <path
        d="M 53.568739,23.848883 18.460399,15.346194"
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 1.5 }}
      />
      <rect
        style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6 }}
        width="13.046884"
        height="12.353571"
        x="0.96395457"
        y="50.780899"
        ry="0.49824521"
      />
      <path
        d="M 57.123424,57.244211 53.445981,23.110374"
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 1.5 }}
      />
      <rect
        style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6 }}
        width="13.046884"
        height="12.353571"
        x="50.090923"
        y="50.780899"
        ry="0.49824521"
      />
      <rect
        style={{ fill: '#c8c8c8', stroke: '#0000ff', strokeWidth: 1.6 }}
        width="13.046884"
        height="12.353571"
        x="8.7355127"
        y="11.520169"
        ry="0.49824521"
      />
      <rect
        style={{ fill: '#c8c8c8', stroke: '#0000ff', strokeWidth: 1.6 }}
        width="13.046884"
        height="12.353571"
        x="11.81125"
        y="8.322587"
        ry="0.49824521"
      />
      <rect
        style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6 }}
        width="13.046884"
        height="12.353571"
        x="46.516197"
        y="17.677019"
        ry="0.49824521"
      />
      <path
        style={{ fill: '#ffffff', stroke: '#0000ff', strokeWidth: 1.6, opacity: 0.548387 }}
        d="M 0.92604162,26.491406 5.920052,25.499218 2.9765624,29.302604"
      />
      <path
        style={{ fill: '#ffffff', stroke: '#0000ff', strokeWidth: 1.6, opacity: 0.548387 }}
        d="m 30.113147,2.4229084 -2.711979,3.9687495 4.7625,-1.1575515"
      />
    </svg>
  );
};

export default CleanLG;
