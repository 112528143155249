const RequestJson = (
  state = {
    loading: true,
    error: false,
    responseType: "",
    message: "",
    data: {},
  },
  action
) => {
  switch (action.type) {
    case "GET_REQUEST_JSON_LOADING_START":
      return {
        ...state,
        loading: true,
      };
    case "GET_REQUEST_JSON_LOADING_STOP":
      return {
        ...state,
        loading: false,
      };
    case "REQUEST_JSON":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        data: action.payload.data.responseData,
      };
    case "REQUEST_JSON_FAILED":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data,
        error: action.payload.data.responseData,
      };
    default:
      return state;
  }
};

export default RequestJson;
