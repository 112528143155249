let intinalState = {
    loading: true,    
    error: false,
    responseType: '',        
    message: '',
    data: {rows:[], columns:[]},
    metaData:{materialType:[],author:[], materialColumnTypes:[], status:['Draft', 'In Production']},
    others:{
        material:[
        {key:'materialType', label:"Material Type", type:'dropdown'},
        {key:'materialName', label:"Material Name", type:'text'},
        {key:'materialDescription', label:"Material Description", type:'text'},
        {key:'productionDate', label:"Material Prouction Date", type:'date'},
        {key:'author', label:"Author", type:'dropdown'},
        {key:'status', label:"Material Status", type:'dropdown'}],
    sam:[
        {key:'samName', label:"SAM Name", type:'text'},
        {key:'vendor', label:"Vendor", type:'text'},
        {key:'samDescription', label:"SAM Description", type:'text'},
        {key:'productionDate', label:"SAM Prouction Date", type:'date'},
        {key:'author', label:"Author", type:'dropdown'},
        {key:'status', label:"SAM Status", type:'dropdown'}],
    fiber:[
        {key:'fiberName', label:"Fiber Name", type:'text'},
        {key:'vendor', label:"Vendor", type:'text'},
        {key:'fiberDescription', label:"Fiber Description", type:'text'},
        {key:'productionDate', label:"Fiber Prouction Date", type:'date'},
        {key:'author', label:"Author", type:'dropdown'},
        {key:'status', label:"Fiber Status", type:'dropdown'}],
    labvantage:[
        {key:'dateFrom', label:"Request Start Date", type:'date', value: new Date(new Date().setFullYear(new Date().getFullYear() - 2)).toISOString().substring(0, 10)},
        {key:'dateTo', label:"Request End Date", type:'date', value: new Date().toISOString().substring(0, 10)},
        {key:'requestId', label:"Request ID", type:'text'},
        {key:'requestorName', label:"Requestor Name", type:'text'},
        {key:'productCategory', label:"Product Category", type:'text'},
        {key:'productDescription', label:"Product Description", type:'text'},
        {key:'keyId', label:"Sample ID", type:'text'}    
    ],    
    compression:[
        {key:'requestId', label:"Request Id", type:'text'},
        {key:'sampleId', label:"Sample Id", type:'text'},
        {key:'requestorName', label:"Requestor Name", type:'text'},
        {key:'productCategory', label:"Product Category", type:'text'},
        {key:'materialDescription', label:"Material Description", type:'text'},

        {key:'testState', value : 'dry' , label:"Test State", type:'text', editable:true}    
    ], 

    }
}

export default (
    state = intinalState, action) => {
    switch (action.type) {
        case 'FETCH_SEARCH_RESULT_FROM_MATERIAL_DB':
            console.log("GET_MATERIAL_SEARCH_DATA ", action.payload.data)
            return {
                ...state,
                loading: false,
                responseType:action.payload.data.responseType,
                data:action.payload.data.responseData,
            };        
     
        case 'FETCH_MATERIAL_SEARCH_DATA_TYPES':
            console.log("FETCH_MATERIAL_SEARCH_DATA_TYPES ", action.payload.data)
            return {
                ...state,
                metaData:{
                    ...state.metaData,
                    materialType:action.payload.data.responseData
                }
            };  
            
        case 'FETCH_MATERIAL_COLUMN_TYPES':
            console.log("FETCH_MATERIAL_SEARCH_DATA_TYPES ", action.payload.data)
            return {
                ...state,
                metaData:{
                    ...state.metaData,
                    materialColumnTypes:action.payload.data.responseData
                }
            };  
             
        case 'FETCH_MATERIAL_AUTHOR_TYPES':
            console.log("FETCH_MATERIAL_AUTHOR_TYPES ", action.payload.data)
            return {
                ...state,
               metaData:{
                    ...state.metaData,
                    author:action.payload.data.responseData
                }
            };                 
    case 'RESET_MATERIAL_SEARCH_DATA':    
            return action.payload;
    case 'RESET_MATERIAL_SEARCH_DATA_INITIAL':
            return {...intinalState};        
        default:
            return state;
    }

};