import React from "react";
import "./index.css";
import { Input } from "antd";

function Header({ moduleName, moduleFieldDataSet, editStatus, handleHeaderDataUpdate }) {
  const renderHeaderBar = () => {
    if (
      moduleFieldDataSet[moduleName] !== undefined &&
      moduleFieldDataSet[moduleName]["header"] &&
      editStatus === false
    ) {
      return (
        <>
          <div className="header-title-txt-container">
            <small>
              <span>{moduleName}</span> Id -
            </small>
            <b>{moduleFieldDataSet[moduleName]["header"]["key"]}</b>
          </div>
          <div className="header-title-txt-container">
            <small>
              <span>{moduleName}</span> Name -
            </small>
            <b>{moduleFieldDataSet[moduleName]["header"]["name"]}</b>
          </div>
          {moduleName === "material" ? (
            <div className="header-title-txt-container">
              <small>{moduleName} Type -</small>
              <b>{moduleFieldDataSet[moduleName]["header"]["type"]}</b>
            </div>
          ) : null}
        </>
      );
    } else if (
      moduleFieldDataSet[moduleName] !== undefined &&
      moduleFieldDataSet[moduleName]["header"] &&
      editStatus === true
    ) {
      return (
        <>
          <small>
            <span>{moduleName}</span> Id -
          </small>
          <b>{moduleFieldDataSet[moduleName]["header"]["key"]}</b>
          <small>
            <span>{moduleName}</span> Name -
          </small>
          <b>
            <Input
              type="text"
              size="large"
              value={moduleFieldDataSet[moduleName]["header"]["name"]}
              onChange={(e) => handleHeaderDataUpdate({ e: e.target.value, key: "name", module: moduleName })}
            />
          </b>
          {moduleName === "material" ? (
            <>
              <small>
                <span>{moduleName}</span> Type -
              </small>
              <b>
                <Input
                  type="text"
                  size="large"
                  value={moduleFieldDataSet[moduleName]["header"]["type"]}
                  onChange={(e) => handleHeaderDataUpdate({ e: e.target.value, key: "type", module: moduleName })}
                />
              </b>
            </>
          ) : null}
        </>
      );
    }
  };

  return <div className="header-title-txt">{renderHeaderBar()}</div>;
}

export default Header;
