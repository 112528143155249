import React from 'react';
import loader from '../../loader.gif';
import logo from '../header/logo.png';
const Loading = () => {
  return (
    <div className='main-panel ps'>
             <div className='content padd-top'>
                 <div className='waitingForUserInfo'>
                     
                     <div className='loadingMsg'>
                     <img src={logo} /> 
                    
                     <div className='loadingRes'>
                     <div>Loading</div>
                     
                     <img src={loader} className='loaderr' />
                     </div>
                     </div>
                 </div>
             </div>

       </div> 
  );
};

export default Loading;