import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOktaAuth } from "@okta/okta-react";

import { updateUserInfo } from "../axios/index";
import SignInError from "../components/signInError";
import Header from "../components/header";
import AppRoutes from "../components/routers";
import DefaultRoutes from "../components/routers/default";

const DefaultHome = (props) => {
  const dispatch = useDispatch();
  const { authState, oktaAuth } = useOktaAuth();
  const userInfo = useSelector((state) => state.userInfo);

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      dispatch(updateUserInfo({}));
    } else {
      // get user information from `/userinfo` endpoint
      oktaAuth.getUser().then((info) => {
        dispatch(updateUserInfo(info));
      });
    }
  }, [authState, dispatch, oktaAuth]); // Update if authState changes

  const renderConetent = () => {
    if (
      userInfo !== undefined &&
      Object.keys(userInfo).length > 0 &&
      Object.getPrototypeOf(userInfo) === Object.prototype
    ) {
      if (userInfo.sAMAccountName !== undefined && userInfo.Roles !== undefined && userInfo.Roles.length > 0) {
        return (
          <>
            <Header />
            <AppRoutes />
          </>
        );
      } else {
        return <SignInError />;
      }
    } else {
      return (
        <>
          <Header />
          <DefaultRoutes />
        </>
      );
    }
  };

  return <>{renderConetent()}</>;
};

export default DefaultHome;
