import React from "react";
import "./index.css";

function TextArea({ id, label, onChange, required, value }) {
  return (
    <div class="textarea">
      <div className="textLabel">
        <span>{label}</span>
        {required && <span className="required">*</span>}
      </div>

      <textarea
        class="form-control"
        id={id}
        rows="3"
        onChange={onChange}
        value={value}
      ></textarea>
    </div>
  );
}

export default TextArea;
