import { useOktaAuth } from "@okta/okta-react";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  BulbTwoTone,
  ControlOutlined,
  CopyTwoTone,
  FileProtectOutlined,
  HomeOutlined,
  HomeTwoTone,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Button, Dropdown, Menu, Typography } from "antd";
import React, { useEffect, useState } from "react";

import SvgComponent, { GeometryDBIcon, MaterialDBIcon } from "../../icons/svgComponent";
import brand from "./brand.jpg";
import "./index.css";
import logo from "./logo.png";

function Header() {
  const { oktaAuth } = useOktaAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const userInfo = useSelector((state) => state.userInfo);
  const permissions = useSelector(({ userInfo }) => userInfo?.Roles || []);

  const [selectedNavItem, setSelectedNavItem] = useState("home");

  useEffect(() => {
    setSelectedNavItem(location.pathname.replace(/\//g, ""));
  }, [location.pathname]);

  function getItem(label, key, icon, children) {
    return {
      label,
      key,
      icon,
      children,
    };
  }

  const navItems = [
    getItem("Home", "home", <HomeTwoTone />),
    getItem("Geometry", "geometry", <GeometryDBIcon />),
    getItem("Material Database", "material", <MaterialDBIcon />, [
      getItem("Home", "material-database", <HomeOutlined />),
      getItem(
        "Materials",
        "materials",
        <SvgComponent
          type={"material"}
          width={"16px"}
          customClass={"headerIcon"}
          height={"16px"}
          background={"transprent"}
        />
      ),
      getItem(
        "Fibers",
        "fibers",
        <SvgComponent
          type={"fiber"}
          width={"20px"}
          customClass={"headerIcon"}
          height={"20px"}
          background={"transprent"}
        />
      ),
      getItem(
        "SAM",
        "sam",
        <SvgComponent
          type={"sam"}
          width={"20px"}
          customClass={"headerIcon"}
          height={"20px"}
          background={"transprent"}
        />
      ),
      getItem("Mechanical Test Data", "mechanical-properties", <ControlOutlined />),
    ]),
  ];

  if (["NL_ADMIN"].every((r) => permissions.includes(r))) {
    navItems.push(getItem("Admin", "admin", <FileProtectOutlined />));
  }

  const handleCopyToken = () => {
    let localState = JSON.parse(localStorage.getItem("okta-token-storage"));
    let token = "";
    if (localState && localState.hasOwnProperty("idToken")) {
      let idToken = localState.idToken.idToken;
      token = `Bearer ${idToken}`;
    }

    navigator.clipboard.writeText(token).then(
      () => {
        console.log("token copied");
        /* Resolved - text copied to clipboard successfully */
      },
      () => {
        console.error("Failed to copy");
        /* Rejected - text failed to copy to the clipboard */
      }
    );
  };

  const handleNavMenuSelect = ({ key }) => {
    if (key === "home") {
      navigate(`/`);
    } else {
      navigate(`/${key}`);
    }
  };

  const userDDMenu = (
    <Menu>
      <Menu.Item>
        <Typography.Text strong>
          {userInfo.given_name !== undefined ? userInfo.given_name : "..."}{" "}
          {userInfo.family_name !== undefined ? userInfo.family_name : "loading"}
        </Typography.Text>
      </Menu.Item>
      <Menu.ItemGroup>
        <Menu.Item icon={<BulbTwoTone />}>
          <Link to="/tool-overview">Tool Overview</Link>
        </Menu.Item>
        <Menu.Item icon={<CopyTwoTone />} onClick={() => handleCopyToken()}>
          Copy Token
        </Menu.Item>
      </Menu.ItemGroup>
      <Menu.ItemGroup>
        <Menu.Item icon={<LogoutOutlined />} onClick={() => oktaAuth.signOut()} danger>
          Logout
        </Menu.Item>
      </Menu.ItemGroup>
    </Menu>
  );

  return (
    <nav className="navbar navbar-expand-lg navbar-transparent  bg-primary  navbar-absolute fixed-top">
      <div className="container-fluid">
        <div className="navbar-wrapper">
          <Link className="navbar-brand" to="/">
            <img src={logo} alt="Kimberly Clark" />
            <img src={brand} alt="Virtual R&D" style={{ paddingLeft: "20px", maxWidth: "94px" }} />
          </Link>
        </div>

        <div style={{ display: "flex", alignItems: "center" }}>
          <Menu
            selectedKeys={[selectedNavItem]}
            mode="horizontal"
            items={navItems}
            onSelect={handleNavMenuSelect}
            disabledOverflow
          />

          <Dropdown overlay={userDDMenu}>
            <Button type="text" shape="circle" size="large" icon={<UserOutlined />} />
          </Dropdown>
        </div>
      </div>
    </nav>
  );
}

export default Header;
