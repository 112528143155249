// let intinalState = {
//   loading: "initial",
//   error: false,
//   responseType: "",
//   responseDesc: [],
//   message: "",
//   data: {},
// };
// export default (state = intinalState, action) => {
//   switch (action.type) {
//     case "GET_UPLOAD_SCREEN_DATA_LOADING_START":
//       return {
//         ...state,
//         loading: true,
//       };
//     case "GET_UPLOAD_SCREEN_DATA_LOADING_STOP":
//       return {
//         ...state,
//         loading: false,
//       };
//     case "GET_UPLOAD_SCREEN_DATA":
//       return {
//         ...state,
//         loading: false,
//         responseType: action.payload.data.responseType,
//         fields: action.payload.data.responseData,
//       };
//     case "GET_UPLOAD_SCREEN_DATA_FAILED":
//       return {
//         ...state,
//         loading: false,
//         responseType: action.payload.data.responseType,
//         error: action.payload.data.responseData,
//       };

//     default:
//       return state;
//   }
// };

// export default UploadFileData;

const UploadFileData = (
  state = {
    loading: true,
    error: false,
    responseType: "",
    message: "",
    data: {},
    fields: {},
  },
  action
) => {
  switch (action.type) {
    case "GET_UPLOAD_SCREEN_DATA_LOADING_START":
      return {
        ...state,
        loading: true,
      };
    case "GET_UPLOAD_SCREEN_DATA_LOADING_STOP":
      return {
        ...state,
        loading: false,
      };
    case "GET_UPLOAD_SCREEN_DATA":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        fields: action.payload.data.responseData,
      };
    case "GET_UPLOAD_SCREEN_DATA_FAILED":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        error: action.payload.data.responseData,
      };
    case "UPLOAD_FILE":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        data: action.payload.data.responseData,
      };
    case "UPLOAD_FILE_FAILED":
      return {
        ...state,
        loading: false,
        // responseType: action.payload.data,
        // error: action.payload.data.responseData,
      };
    default:
      return state;
  }
};

export default UploadFileData;
