import { Button } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { NotificationTwoTone } from "@ant-design/icons";

import layerPic from "../../components/header/layersPic.png";
import { GeometryDBIcon, MaterialDBIcon } from "../../icons/svgComponent";
import "./index.css";

const HomeContent = () => {
  return (
    <div>
      <div className="row details">
        <div className="col-6 pt-1">
          <div className="details-col">
            <div className="title">
              <div>Product</div>
              <div>Modeling Resources</div>
            </div>
            <div style={{ fontSize: "18px", padding: "0 16px" }}>
              <span>
                Use links below to access the absorbency material database as well as the product geometry database.
                These tools provide access to previously created components for product simulations.
              </span>
              <div style={{ textAlign: "center", paddingTop: "40px" }}>
                <div className="buttons">
                  <Link className="nav-link" to="/geometry" exact>
                    <button className="btn-geometry">
                      <GeometryDBIcon style={{ fontSize: 22 }} /> Geometry Database
                    </button>
                  </Link>
                  <Link className="nav-link" to="/material-database" exact>
                    <button className="btn-material">
                      <MaterialDBIcon style={{ fontSize: 22 }} /> Material Database
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-6">
          <img src={layerPic} alt="Layers Pic" />
        </div>
      </div>

      <footer class="footer mt-auto py-3 bg-body-tertiary">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 8,
          }}
        >
          <NotificationTwoTone />
          <span>Mesh Database 1.2 is out!</span>
          <Button type="link" size="small">
            See What's new
          </Button>
        </div>
      </footer>
    </div>
  );
};

export default HomeContent;
