let intinalState = {
    loading: true,
    error: false,
    responseType: '',        
    message: '',
    data: {}
}
export default (
    state = intinalState, action) => {
    switch (action.type) {

        case 'FETCH_SAM_SEARCH_DATA':
            console.log("FETCH_SAM_SEARCH_DATA ", action.payload.data)
            return {
                ...state,
                loading: false,
                responseType:action.payload.data.responseType,
                data:action.payload.data.responseData,
            };        
    case 'RESET_SAM_SEARCH_DATA':    
            return action.payload;
    case 'RESET_SAM_SEARCH_DATA_INITIAL':
            return intinalState;        
        default:
            return state;

    }

};