import React from 'react';

const MovePointLG = (props) => (
  <svg
    width="64mm"
    height="64mm"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="layer1">
      <path
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 1.5 }}
        d="M -0.06302842,38.762482 30.127586,28.047648 63.59568,0.2521137"
      />
    </g>
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="23.383545"
      y="21.681778"
      ry="0.49824521"
    />
    <path
      style={{ fill: '#505050', stroke: 'none' }}
      d="m 19.000332,18.211133 c -0.04374,0.952824 0.02138,8.122839 0.02138,8.122839 l 1.570048,0.0099 0.0022,-7.785817 h 7.405485 l -0.01576,-1.583851 c 0,0 -5.460536,-0.0057 -7.768583,-0.01 -0.54701,0.05678 -1.160743,0.420211 -1.214789,1.246928 z"
    />
    <path
      style={{ fill: '#c8c8c8', fillOpacity: 1, stroke: 'none' }}
      d="m 15.298926,14.709281 c -0.04374,0.952824 0.02138,8.122839 0.02138,8.122839 l 1.570048,0.0099 0.0022,-7.785817 h 7.405485 l -0.01576,-1.583851 c 0,0 -5.460536,-0.0057 -7.768583,-0.01 -0.54701,0.05678 -1.160743,0.420211 -1.214789,1.246928 z"
    />
    <path
      style={{ fill: '#e6e6e6', fillOpacity: 1, stroke: 'none' }}
      d="m 11.422677,10.801519 c -0.04374,0.952824 0.02138,8.122839 0.02138,8.122839 l 1.570048,0.0099 0.0022,-7.785817 H 20.42179 L 20.40603,9.5645902 c 0,0 -5.460536,-0.0057 -7.768583,-0.01 -0.54701,0.05678 -1.160743,0.420211 -1.214789,1.2469278 z"
    />
    <path
      style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1.29944 }}
      d="m 28.862069,52.022102 3.401743,-18.764681 13.51975,13.38137 -6.629038,1.153566 3.488965,8.767106 -4.273983,1.461185 -3.663418,-8.844011 z"
    />
  </svg>
);

export default MovePointLG;
