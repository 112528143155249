import { formatDate } from "./dateUtils";
import { Tag } from "antd";
import { getStatusColor } from "./statusUtils";
import SvgComponent from "../../icons/svgComponent";
export function sortColumn(column) {
  //   console.log(column, "columncolumn======>", column.attributeName);
  switch (column?.type) {
    case "string":
      const stringAttr = column.attributeName;

      return function (a, b) {
        return ("" + a[stringAttr]).localeCompare(b[stringAttr]);
      };
    case "number":
      const numberAttr = column.attributeName;
      return (a, b) => a[numberAttr] - b[numberAttr];

    case "date":
      const dateAttr = column.attributeName;
      return (a, b) =>
        new Date(a[`${dateAttr}`]).getTime() -
        new Date(b[`${dateAttr}`]).getTime();
    default:
      return false;
  }
}

export function getSearchFilterData(valuesData, keysData, searchText) {
  let filterData = valuesData?.filter((value) => {
    if (
      keysData?.some((item) => {
        if (
          item?.type === "string" &&
          value[item?.attributeName]
            ?.toLowerCase()
            .match(searchText.toLowerCase())
        ) {
          return true;
        } else if (
          item?.type === "number" &&
          value[item?.attributeName] == searchText
        ) {
          return true;
        } else if (
          item?.type === "date" &&
          formatDate(value[item?.attributeName])
            .toLowerCase()
            .match(searchText.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      })
    ) {
      return value;
    } else {
      return null;
    }
  });
  return filterData;
}

export function getColumnsDetails(value, props) {
  if (value?.displayName === "Actions") {
    return {
      key: value?.attributeName,
      dataIndex: value?.attributeName,
      title: value?.displayName,
      render: (record) => {
        return (
          <div className="d-flex">
            <div
              onClick={() => {
                if (props?.selectedScreen === "admin") {
                  props?.onAdminEditClick(record, props?.tableType);
                }
              }}
              data-toggle="tooltip"
              title="Edit"
            >
              <SvgComponent
                type={"editPencil"}
                width={"20px"}
                customClass={"headerIcon"}
                height={"20px"}
                background={"transprent"}
              />
            </div>
            <div
              onClick={() => {
                if (props?.selectedScreen === "admin") {
                  props?.onAdminDeleteClick(record, props?.tableType);
                }
              }}
              data-toggle="tooltip"
              title="Delete"
            >
              <SvgComponent
                type={"delete"}
                width={"20px"}
                customClass={"headerIcon"}
                height={"20px"}
                background={"transprent"}
              />
            </div>
          </div>
        );
      },
    };
  }
  switch (value?.attributeName) {
    case "color":
      return {
        key: value?.attributeName,
        type: value?.type,
        //   sorter: sortColumn(value),
        dataIndex: value?.attributeName,
        title: value?.displayName,
        render: (text) => {
          return (
            <div
              style={{
                backgroundColor: `rgb${text}`,
                margin: "0px 5px",
                borderRadius: "5px",
                height: "25px",
                width: "82px",
              }}
            ></div>
          );
        },
      };
    case "status":
      return {
        key: value?.attributeName,
        type: value?.type,
        sorter: sortColumn(value),
        dataIndex: value?.attributeName,
        title: value?.displayName,
        render: (text) => {
          return <Tag color={getStatusColor(text)}>{text}</Tag>;
        },
      };
    case "createdAt":
      return {
        key: value?.attributeName,
        type: value?.type,
        sorter: sortColumn(value),
        dataIndex: value?.attributeName,
        title: value?.displayName,
        render: (text) => {
          let date = formatDate(text);
          return <div>{date}</div>;
        },
      };

    default:
      return {
        key: value?.attributeName,
        type: value?.type,
        sorter: sortColumn(value),
        dataIndex: value?.attributeName,
        title: value?.displayName,
      };
  }
}
