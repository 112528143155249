import React from 'react';

const AddMidPointLG = (props) => (
  <svg
    width="64mm"
    height="64mm"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="layer1">
      <path
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 1.5 }}
        d="M 6.8220449,58.402462 56.553909,6.6369871 63.528173,6.6189395"
      />
    </g>
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="24.547108"
      y="26.940334"
      ry="0.49824521"
    />
    <path
      style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1.29944 }}
      d="m 31.86758,56.510907 3.401743,-18.764681 13.51975,13.38137 -6.629038,1.153566 3.488965,8.767106 -4.273983,1.461185 -3.663418,-8.844011 z"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6, fillOpacity: 1 }}
      width="13.046884"
      height="12.353571"
      x="50.20874"
      y="0.90140206"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6, fillOpacity: 1 }}
      width="13.046884"
      height="12.353571"
      x="0.96395385"
      y="50.959167"
      ry="0.49824521"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.14826 }}
      d="m 39.020551,44.29491 v 9.576608"
    />
    <path
      style={{ opacity: 1, fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.18757 }}
      d="M 43.582996,49.065112 H 34.224351"
    />
  </svg>
);

export default AddMidPointLG;
