import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { editProductType, addProductType, getBusinessUnit } from "../../axios";
import { useDispatch } from "react-redux";
import loader from "../../loader.gif";
import Input from "../atom/input";
import ErrorMsg from "../errors/errorMsg";
import "./index.css";

function AddEditProductTypeModal({
  isModalOpen,
  setIsModalOpen,
  selectedItem,
  refetchApi,
  editModal,
}) {
  const initialValue = {
    businessUnit: "",
    productType: "",
    // productCategory: "",
  };
  const isEditModal = editModal === "Product Types";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [allFields, setAllFields] = useState({ ...initialValue });
  const [buOptions, setBuOptions] = useState([]);

  const handleAdd = () => {
    // Validations

    // Check all mandatory files
    let mandatoryFieldMissing = false;
    for (let key in allFields) {
      if (allFields.hasOwnProperty(key) && !allFields[key]) {
        setError(`${key} is mandatory.`);
        mandatoryFieldMissing = true;
        return;
      }
    }

    if (mandatoryFieldMissing) {
      return;
    }

    setLoading(true);
    dispatch(
      isEditModal ? editProductType(allFields) : addProductType(allFields)
    )
      .then((result) => {
        refetchApi();
        setLoading(false);
        setAllFields({});
        setIsModalOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.responseDesc || `Error in operation`);
      });
  };

  useEffect(() => {
    if (isEditModal) {
      setAllFields({
        businessUnit: selectedItem?.businessUnit,
        productType: selectedItem?.productType,
        // productCategory: selectedItem?.productType,
        id: selectedItem?.Id,
      });
    } else {
      setAllFields({ ...initialValue });
    }
  }, [isEditModal, isModalOpen]);

  useEffect(() => {
    dispatch(getBusinessUnit())
      .then((result) => {
        setBuOptions(result?.data?.responseData || []);
      })
      .catch((err) => {
        console.log(err, "error");
      });
    return () => {
      setError("");
      setAllFields({ ...initialValue });
      setLoading(false);
    };
  }, []);

  const onChange = (e) => {
    setAllFields({
      ...allFields,
      [e.target?.id]: e.target?.value,
    });
    setError("");
  };

  return (
    <Modal
      title={<h3>{isEditModal ? "Edit" : "Add"} Product Type</h3>}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setAllFields({ ...initialValue });
        setError("");
        setLoading(false);
      }}
      onOk={() => {
        handleAdd();
      }}
      okText={isEditModal ? "Edit" : "Save"}
      destroyOnClose
      width={700}
      confirmLoading={loading}
      // size={"large"}
    >
      {error && <ErrorMsg message={error} />}
      <>
        <div className="textLabel">
          <span>{"Business Unit"}</span>
          <span className="required">*</span>
        </div>

        <div className="dropdown">
          <select
            class="custom-select"
            id={"businessUnit"}
            key={"businessUnit"}
            value={allFields["businessUnit"]}
            onChange={onChange}
          >
            <option selected="" disabled="" value="">
              Choose...
            </option>

            {buOptions?.map((value) => (
              <option value={value?.id}>{value?.businessUnit}</option>
            ))}
          </select>
        </div>
      </>
      {/* <Input
        id={"productOrganization"}
        label={"Business Unit"}
        onChange={onChange}
        required={true}
        value={allFields["productOrganization"]}
      /> */}
      <Input
        id={"productType"}
        label={"Product Type "}
        onChange={onChange}
        // infoComponent={infoComponent}
        required={true}
        value={allFields["productType"]}
      />
      {/* <Input
        id={"productCategory"}
        label={"Product Category "}
        onChange={onChange}
        // infoComponent={infoComponent}
        required={true}
        value={allFields["productCategory"]}
      /> */}
    </Modal>
  );
}

export default AddEditProductTypeModal;
