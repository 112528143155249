import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Outlet, NavLink } from "react-router-dom";

import { getAdminScreenElements } from "../axios/index";
import AdminModule from "../components/adminModule";
import DefaultBreadCrumb from "../components/breadcrumb";

function AdminPage() {
  const dispatch = useDispatch();
  //Get api hit for all columns and dropdown details fetch
  useEffect(() => {
    dispatch(getAdminScreenElements());
  }, []);

  return (
    <div className="main-panel bg-gray">
      <div className="main-panel ps">
        <div className="content padd-top">
          <div className="row">
            <DefaultBreadCrumb
              data={[
                { title: "Home", path: "/" },
                { title: "Admin", path: "/admin", active: true },
              ]}
            />
          </div>
          <AdminModule />
          {/* <div div className="row">
            <div className="col-2">
              <div class="nav-wrapper position-relative end-0">
                <ul className="nav flex-column">
                  <li className="nav-item">
                    <NavLink
                      to="partNames"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Part Names
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="regions"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Regions
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="productTypes"
                      className="nav-link"
                      activeClassName="active"
                    >
                      Product Types
                    </NavLink>
                  </li>
                </ul>

            
              </div>
            </div>
            <div className="col-10">
              <Outlet />
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default AdminPage;
