import React, { useState, useEffect } from "react";
import { Modal } from "antd";
import { addRegion, editRegion } from "../../axios";
import { useDispatch } from "react-redux";
import Input from "../atom/input";
import ErrorMsg from "../errors/errorMsg";
import "./index.css";

function AddEditRegionModal({
  isModalOpen,
  setIsModalOpen,
  selectedItem,
  refetchApi,
  editModal,
}) {
  const initialValue = {
    region: "",
  };
  const isEditModal = editModal === "Regions";
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [allFields, setAllFields] = useState({ ...initialValue });

  const handleAdd = () => {
    // Validations

    // Check all mandatory files
    let mandatoryFieldMissing = false;
    for (let key in allFields) {
      if (allFields.hasOwnProperty(key) && !allFields[key]) {
        setError(`${key} is mandatory.`);
        mandatoryFieldMissing = true;
        return;
      }
    }

    if (mandatoryFieldMissing) {
      return;
    }

    setLoading(true);
    dispatch(isEditModal ? editRegion(allFields) : addRegion(allFields))
      .then((result) => {
        refetchApi();
        setLoading(false);
        setAllFields({ ...initialValue });
        setIsModalOpen(false);
      })
      .catch((err) => {
        setLoading(false);
        setError(err?.response?.data?.responseDesc || `Error in operation`);
      });
  };

  useEffect(() => {
    if (isEditModal) {
      setAllFields({ region: selectedItem?.regionName, id: selectedItem?.id });
    } else {
      setAllFields({ ...initialValue });
    }
  }, [isEditModal, isModalOpen]);

  useEffect(() => {
    return () => {
      setError("");
      setAllFields({ ...initialValue });
      setLoading(false);
    };
  }, []);

  const onChange = (e) => {
    setAllFields({
      ...allFields,
      [e.target?.id]: e.target?.value,
    });
    setError("");
  };

  return (
    <Modal
      title={<h3>{isEditModal ? "Edit" : "Add"} Region</h3>}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
        setAllFields({ ...initialValue });
        setError("");
        setLoading(false);
      }}
      onOk={() => {
        handleAdd();
      }}
      okText={isEditModal ? "Edit" : "Save"}
      destroyOnClose
      width={700}
      confirmLoading={loading}
      // size={"large"}
    >
      {error && <ErrorMsg message={error} />}

      <Input
        id={"region"}
        label={"Region Name"}
        onChange={onChange}
        // infoComponent={infoComponent}
        required={true}
        value={allFields["region"]}
      />
    </Modal>
  );
}

export default AddEditRegionModal;
