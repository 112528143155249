import React from "react";
import "./index.css";
import SvgComponent from "../../icons/svgComponent";
function FilesTable({
  files,
  setUploadedFiles,
  uploadedFilesResponse,
  setUploadedFilesResponse,
}) {
  function handleDelete(index) {
    setUploadedFiles((prevState) => {
      let state = [...prevState];
      state.splice(index, 1);
      return state;
    });

    setUploadedFilesResponse((prevState) => {
      let prevResponses = [...prevState?.fileResponses];
      prevResponses.splice(index, 1);
      return {
        responseCount: prevState?.responseCount,
        fileResponses: prevResponses,
      };
    });
  }

  function handleRemove(e, originalname) {
    setUploadedFilesResponse((prevState) => {
      let newFileResponses = prevState?.fileResponses?.map((val, index) => {
        if (val?.originalname === originalname) {
          // val.dependency.removeAllDependency = true;

          return {
            ...val,
            dependency: {
              ...val?.dependency,
              removeAllDependency: e.target.value === "true" ? true : false,
            },
          };
        } else {
          return val;
        }
      });
      let newState = {
        fileResponses: newFileResponses,
        responseCount: prevState?.responseCount,
      };

      return newState;
    });
  }

  return (
    <div>
      <table className="uploadFileTable">
        <thead>
          <tr>
            <th scope="col">#</th>
            {uploadedFilesResponse?.responseCount > 0 && (
              <th scope="col">
                Upload <br /> Status
              </th>
            )}
            <th scope="col">
              File <br /> Name
            </th>
            <th scope="col">
              File <br />
              Size
            </th>

            {uploadedFilesResponse?.responseCount > 0 && (
              <th scope="col">
                Is <br /> Duplicate
              </th>
            )}
            {uploadedFilesResponse?.responseCount > 0 && (
              <th scope="col">
                Is <br />
                Dependent
              </th>
            )}
            <th scope="col">Action</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {files.map((item, index) => {
            return (
              <tr
                key={index + "-row"}
                onClick={(e) => {
                  // e.preventDefault();
                  e.stopPropagation();
                }}
              >
                <th scope="row">{index + 1}</th>
                {uploadedFilesResponse?.fileResponses[index] && (
                  <td>
                    {uploadedFilesResponse?.fileResponses[index]?.status ===
                    "Uploaded" ? (
                      <SvgComponent
                        type={"pass"}
                        width={"20px"}
                        height={"20px"}
                        background={"transprent"}
                      />
                    ) : (
                      <SvgComponent
                        type={"failed"}
                        width={"25px"}
                        customClass={"headerIcon"}
                        height={"25px"}
                        background={"transprent"}
                      />
                    )}{" "}
                  </td>
                )}
                <td>{item?.name}</td>
                <td>{item?.size}</td>

                {uploadedFilesResponse?.fileResponses[index] && (
                  <td>
                    {uploadedFilesResponse?.fileResponses[index]?.isDuplicate ==
                    true
                      ? "YES"
                      : "NO"}{" "}
                  </td>
                )}
                {uploadedFilesResponse?.fileResponses[index] && (
                  <td>
                    {uploadedFilesResponse?.fileResponses[index]?.dependency
                      ?.isPublished
                      ? "YES"
                      : "NO"}{" "}
                  </td>
                )}
                {uploadedFilesResponse?.fileResponses[index] && (
                  <td>
                    {uploadedFilesResponse?.fileResponses[index]?.isDuplicate ==
                      true &&
                      !uploadedFilesResponse?.fileResponses[index]?.dependency
                        ?.isPublished && (
                        <select
                          class="custom-select"
                          id={"removeAllDependency"}
                          key={"removeAllDependency"}
                          value={
                            uploadedFilesResponse?.fileResponses[index]
                              ?.dependency?.removeAllDependency
                          }
                          onChange={(e) =>
                            handleRemove(
                              e,
                              uploadedFilesResponse?.fileResponses[index]
                                ?.originalname
                            )
                          }
                        >
                          <option selected>Select...</option>
                          <option value={true}>Yes, Remove dependency</option>
                          <option value={false}>
                            No, Do not remove dependency
                          </option>
                        </select>
                      )}
                  </td>
                )}
                {
                  <td>
                    {uploadedFilesResponse?.fileResponses[index]?.status !==
                      "Uploaded" && (
                      <span
                        data-toggle="tooltip"
                        title="Delete File"
                        onClick={() => handleDelete(index)}
                      >
                        <SvgComponent
                          type={"delete"}
                          width={"20px"}
                          customClass={"headerIcon"}
                          height={"20px"}
                          background={"transprent"}
                        />
                      </span>
                    )}
                  </td>
                }
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

export default FilesTable;
