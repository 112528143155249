const partData = {
  action:{
    updated:false,
    type:'new',
    delete:false,
  },
  data:{
    partName:'',
    partDescription:'',
    partType:'',
    PartArea:'',
    partZ:'',
    partDirection:'',
    PartCalulatedOrigin:'',
    partOriginMetaData:[],
    partSourceSvgPath:'',
    partTransformedSvgPath:'',
    partReference:{},
    partHoleReference:{},
    partSourceCoordinates:{},
    partTransformedCoordinates:{},
    partStatus:0,
    partVisibility:0,
  } 
}

const configMetaData = {
  configId:'',
  configName:'',
  meshType:'',
  configDescription:'',
  configFile:'',
  configStatus:'-',
  configActive:'false',
  createdBy:'',
  lastModifiedBy:'',
  configUnits:'mm',

}


const intinalState = {
  loading: true,
  error: false,
  responseType: "",
  message: "",
  data:{ 
        assemblyData:{
        },
        configData:{
           
            metaData:{},
            partData:[] 
        }
  }
}



const SvgViewerData = (
  state =  intinalState,
  action
) => {
 
  switch (action.type) {
    case "GET_SVG_DATA_LOADING":
      return intinalState;

    case "GET_SVG_DATA":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
        data: action.payload.data.responseData,
      };
    case "GET_SVG_DATA_FAILED":
      return {
        ...state,
        loading: false,
        responseType: action.payload.data.responseType,
       error: action.payload.data.responseData,
      };
    
    case "UPDATE_ASSEMBLY_PARTS":
      console.log("assembly redux state: ", action.payload.data.data)
      return{
        ...state,
        loading: false,
        responseType: "success",
        data: action.payload.data.data,
      }

    default:
      return state;
  }
};

export default SvgViewerData;
