export default (
    state = {
        loading: true,
        error: false,
        responseType: '',        
        message: '',
        data: {}
    }, action) => {
    switch (action.type) {

        case 'GET_FIBER_SUMMARY_DATA':
            return {
                ...state,
                loading: false,
                responseType:action.payload.data.responseType,
                data : action.payload.data.responseData,
            };
        

        default:

            return state;

    }

};