import React from 'react';

const RemoveConnectionLG = (props) => (
  <svg
    width="64mm"
    height="64mm"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g>
      <path
        style={{
          fill: 'none',
          stroke: '#0000ff',
          strokeWidth: 1.5,
          strokeOpacity: 1,
        }}
        d="M 9.6743858,59.828632 7.0836211,56.909496 43.068976,7.3408422"
      />
      <path
        style={{
          fill: '#ffffff',
          stroke: '#000000',
          strokeWidth: 1.6,
          strokeOpacity: 1,
        }}
        d="M 8.0222089,57.046817 H 56.868546"
      />
    </g>
    <rect
      style={{
        fill: '#c8c8c8',
        stroke: '#000000',
        strokeWidth: 1.6,
      }}
      width="13.046884"
      height="12.353571"
      x="0.96395457"
      y="50.780899"
      ry="0.49824521"
    />
    <rect
      style={{
        fill: '#c8c8c8',
        stroke: '#000000',
        strokeWidth: 1.6,
      }}
      width="13.046884"
      height="12.353571"
      x="50.090923"
      y="50.780899"
      ry="0.49824521"
    />
    <rect
      style={{
        fill: '#c8c8c8',
        stroke: '#000000',
        strokeWidth: 1.6,
      }}
      width="13.046884"
      height="12.353571"
      x="36.705269"
      y="0.94965947"
      ry="0.49824521"
    />
    <path
      style={{
        fill: '#000000',
        stroke: '#000000',
        strokeWidth: 1.29944,
      }}
      d="m 26.843975,45.181903 3.401743,-18.764681 13.51975,13.38137 -6.629038,1.153566 3.488965,8.767106 -4.273983,1.461185 -3.663418,-8.844011 z"
    />
    <path
      style={{
        fill: '#ffffff',
        stroke: '#ffffff',
        strokeWidth: 2.18757,
        strokeOpacity: 1,
      }}
      d="M 38.202848,36.755616 H 28.844203"
    />
  </svg>
);

export default RemoveConnectionLG;
