export const DBConfig = {
  name: "MaterialCuration",
  version: 1,
  objectStoresMeta: [
    {
      store: "graph_actions",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "action", keypath: "action", options: { unique: false } },
        { name: "data", keypath: "data", options: { unique: false } },
      ],
    },
  ],
};
