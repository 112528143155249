import React from "react";
import { Modal } from "antd";

import ErrorMsg from "../errors/errorMsg";
import "./index.css";

function KnowMoreModal({ isModalOpen, setIsModalOpen, selectedItem }) {
  return (
    <Modal
      title={<h3>Know More</h3>}
      visible={isModalOpen}
      onCancel={() => {
        setIsModalOpen(false);
      }}
      destroyOnClose
      width={600}
      footer={null}
      // size={"large"}
    >
      {selectedItem?.error && <ErrorMsg message={selectedItem?.error} />}
      {/* {<ErrorMsg message={"milan"} />} */}
    </Modal>
  );
}

export default KnowMoreModal;
