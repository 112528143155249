import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TableHeader from "../header/tableHeader";
import GenericTable from "../tables/genericTable";
import { getAdminTableData } from "../../axios";
import { getColumnsDetails } from "../utils/tableUtils";
import { getSearchFilterData } from "../utils/tableUtils";
import AddEditRegionModal from "../modals/regionModal";
import AddEditPartNameModal from "../modals/partNameModal";
import AddEditProductTypeModal from "../modals/productTypeModal";
import DeleteRegionModal from "../modals/deleteAdminModal";
import AddEditBusinessUnitModal from "../modals/businessUnitModal";

const AdminModule = () => {
  const [tableColumns, setTableColumns] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [selectedType, setSelectedType] = useState("Part Names"); /// types [Regions,Product Types, Part Names, Business Units]
  const [isPartNamesModalOpen, setIsPartNamesModalOpen] = useState(false);
  const [isRegionsModalOpen, setIsRegionsModalOpen] = useState(false);
  const [isBusinessUnitsModalOpen, setIsBusinessUnitsModalOpen] =
    useState(false);
  const [isProductTypesModalOpen, setIsProductTypesModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [editModal, setEditModal] = useState("");
  const [deleteModal, setDeleteModal] = useState("");
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [response, setResponse] = useState("");

  const screenDetails = useSelector((state) => state?.AdminDashboardScreenData);
  const tableDetails = useSelector((state) => state?.AdminTableData);

  const dispatch = useDispatch();

  const genericTerms = {
    Regions: {
      displayName: "Region",
      deleteItemName: "regionName",
      url: "regionDelete",
    },
    "Product Types": {
      displayName: "Product Type",
      deleteItemName: "productType",
      url: "productDelete",
    },
    "Part Names": {
      displayName: "Part Names",
      deleteItemName: "name",
      url: "partDelete",
    },
    "Business Units": {
      displayName: "Business Unit",
      deleteItemName: "businessUnit",
      url: "businessUnitDelete",
    },
  };
  function getTableData() {
    let data = [];
    let type = "partNames";
    switch (selectedType) {
      case "Regions":
        data = screenDetails?.data?.associatedValues?.table1Headers;
        type = "regions";
        break;
      case "Product Types":
        data = screenDetails?.data?.associatedValues?.table2Headers;
        type = "productTypes";
        break;
      case "Part Names":
        data = screenDetails?.data?.associatedValues?.table3Headers;
        type = "partNames";

        break;
      case "Business Units":
        data = screenDetails?.data?.associatedValues?.table4Headers;
        type = "businessUnit";

        break;
      default:
        break;
    }

    setHeaders(data);
    dispatch(getAdminTableData({ type: type }));
  }

  useEffect(() => {
    getTableData();
  }, [selectedType, screenDetails?.data]);

  const handleSearch = (searchText) => {
    let data = [];

    if (data) {
      switch (selectedType) {
        case "Regions":
          data = screenDetails?.data?.associatedValues?.table1Headers;
          break;
        case "Product Types":
          data = screenDetails?.data?.associatedValues?.table2Headers;
          break;
        case "Part Names":
          data = screenDetails?.data?.associatedValues?.table3Headers;

          break;
        case "Business Units":
          data = screenDetails?.data?.associatedValues?.table4Headers;

          break;

        default:
          break;
      }
    }

    let keysData = data?.filter((item) => {
      if (item?.shouldSearch) {
        return item?.attributeName;
      } else {
        return null;
      }
    });
    let valuesData = tableDetails?.data ? tableDetails?.data : [];

    let filterData = getSearchFilterData(valuesData, keysData, searchText);
    setTableData(filterData || []);
  };

  function onAdminEditClick(item, tableType) {
    setSelectedItem(item);
    setEditModal(tableType);
    handleModalOpen(true);
  }

  function onAdminDeleteClick(item, tableType) {
    setSelectedDeleteItem({
      ...item,
      tableType: tableType,
      deleteTitle: genericTerms[selectedType]?.displayName,
      deleteItemName: genericTerms[selectedType]?.deleteItemName,
      url: genericTerms[selectedType]?.url,
    });
    setDeleteModal(tableType);
    setIsDeleteModalOpen(true);
  }

  function setHeaders(data) {
    if (data) {
      let columns = data?.map((value) => {
        return getColumnsDetails(value, {
          selectedScreen: "admin",
          tableType: selectedType,
          onAdminEditClick,
          onAdminDeleteClick,
        });
      });
      setTableColumns(columns);
    }
  }

  // Table data set
  useEffect(() => {
    setTableData(tableDetails?.data ? tableDetails?.data : []);
  }, [tableDetails?.data, tableColumns]);

  const renderDropdown = () => {
    return (
      <div class="dropdown">
        <a
          className="nav-link dropdown-toggle"
          //   href="#"
          id="navbarDropdownMenuLink"
          role="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          {selectedType}
        </a>

        <ul class="dropdown-menu">
          {Object.keys(genericTerms)?.map((item) => {
            return (
              <li onClick={() => setSelectedType(item)}>
                <p>
                  <span className="d-md-block">{item}</span>
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  const handleModalOpen = (flag) => {
    switch (selectedType) {
      case "Regions":
        setIsRegionsModalOpen(flag);
        break;

      case "Product Types":
        setIsProductTypesModalOpen(flag);
        break;

      case "Part Names":
        setIsPartNamesModalOpen(flag);
        break;
      case "Business Units":
        setIsBusinessUnitsModalOpen(flag);
        break;

      default:
        break;
    }
  };

  return (
    <div>
      <div className="row">
        <TableHeader
          iconType={"config"}
          title={" Manage -"}
          // checkBoxTitle={"My partNames Files"}
          buttonTitle={`New ${selectedType}`}
          onButtonClick={() => {
            setEditModal("");
            handleModalOpen(true);
          }}
          renderElement={renderDropdown}
          handleSearch={handleSearch}
          //   handleMyFiles={handleMyConfigFiles}
        />
      </div>
      <div className="row">
        <GenericTable
          columns={tableColumns}
          isLoading={tableDetails?.loading}
          data={tableData}
        />
      </div>

      <AddEditRegionModal
        isModalOpen={isRegionsModalOpen}
        setIsModalOpen={setIsRegionsModalOpen}
        selectedItem={selectedItem}
        refetchApi={() => {
          getTableData();
        }}
        editModal={editModal}
      />
      <AddEditBusinessUnitModal
        isModalOpen={isBusinessUnitsModalOpen}
        setIsModalOpen={setIsBusinessUnitsModalOpen}
        selectedItem={selectedItem}
        refetchApi={() => {
          getTableData();
        }}
        editModal={editModal}
      />

      <AddEditPartNameModal
        isModalOpen={isPartNamesModalOpen}
        setIsModalOpen={setIsPartNamesModalOpen}
        selectedItem={selectedItem}
        refetchApi={() => {
          getTableData();
        }}
        editModal={editModal}
      />

      <AddEditProductTypeModal
        isModalOpen={isProductTypesModalOpen}
        setIsModalOpen={setIsProductTypesModalOpen}
        selectedItem={selectedItem}
        refetchApi={() => {
          getTableData();
        }}
        editModal={editModal}
      />

      <DeleteRegionModal
        isModalOpen={isDeleteModalOpen}
        setIsModalOpen={setIsDeleteModalOpen}
        selectedDeleteItem={selectedDeleteItem}
        refetchApi={() => {
          dispatch(getTableData());
        }}
        response={response}
        setResponse={setResponse}
      />
    </div>
  );
};

export default AdminModule;
