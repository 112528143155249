import React from "react";

import HomeContent from "../components/home";

const Home = () => {
  return (
    <div className="main-panel bg-gray">
      <HomeContent />
    </div>
  );
};

export default Home;
