import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { getAssemblyByID, getConfigByID } from "../axios/index";
import DefaultBreadCrumb from "../components/breadcrumb";
import PageHeader from "../components/header/pageHeader";
import { userRoles } from "../components/utils/userRoles";
import loader from "../loader.gif";
import SVG from "../components/svg/index";

const Svg = () => {
  const { configId, moduleName } = useParams();
  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.userInfo);
  const [userRole, setUserRole] = useState();
  const svgSource = useSelector((state) => state.svgViewerData);

  useEffect(() => {
    if (configId !== undefined && moduleName !== undefined) {
      if (moduleName === "config-viewer") {
        dispatch(getConfigByID({ configID: configId }));
      } else if (moduleName === "assembly-viewer") {
        dispatch(getAssemblyByID({ assemblyID: configId }));
      }
    }
  }, [configId]);

  useEffect(() => {
    if (userInfo.sAMAccountName) {
      setUserRole(userRoles(userInfo.Roles));
    }
  }, [userInfo]);

  //render SVG component
  //Pre
  const renderSvgComponent = () => {
    let tempModule =
      moduleName === "config-viewer" ? " Configuration " : " Assembly ";

    if (svgSource.error === false && svgSource.loading === true) {
      return (
        <div className="details-empty screenLoader">
          <h5>Loading {tempModule} File...</h5>
          <img src={loader} className="details-preloader" />
        </div>
      );
    } else if (svgSource.error === true && svgSource.loading === false) {
      return (
        <div className="details-empty screenLoader">
          <h5 style={{ color: "red" }}>Error!!</h5>
          <p>{tempModule} file is empty</p>
          <p>{svgSource?.message}</p>
        </div>
      );
    } else if (svgSource.error === false && svgSource.loading === false) {
      return <SVG moduleName={tempModule} />;
    } else {
      return (
        <div className="details-empty screenLoader">
          <h5>Loading {tempModule} File...</h5>
          <p>Retrieval of file is taking longer than usual </p>
          <img src={loader} className="details-preloader" />
        </div>
      );
    }
  };

  return (
    <div className="main-panel ps">
      <div className="content padd-top">
        <div className="row">
          <DefaultBreadCrumb
            data={[
              { title: "Home", path: "/" },
              { title: "Geometry", path: "/geometry" },
              {
                title: moduleName,
                path: `/geometry/${moduleName}`,
                active: true,
              },
            ]}
          />
        </div>
        {renderSvgComponent()}
      </div>
    </div>
  );
};

export default Svg;
