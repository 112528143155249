import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSourceScreenElements,
  getSourceDataById,
  getJsonDataById,
  getFileUploadOptions,
} from "../axios/index";
import GenericTable from "../components/tables/genericTable";
import TableHeader from "../components/header/tableHeader";
import { formatDate } from "../components/utils/dateUtils";
import { sortColumn } from "../components/utils/tableUtils";
import { getSearchFilterData } from "../components/utils/tableUtils";
import { getStatusColor } from "../components/utils/statusUtils";
import SvgComponent from "../icons/svgComponent";
import DeleteSourceFileModal from "../components/modals/deleteSourceFileModal";
import { Button } from "antd";
import UploadFileModal from "../components/modals/uploadFileModal";
import ScaleModal from "../components/modals/scale";
import KnowMoreModal from "../components/modals/knowMoreModal";
import DefaultBreadCrumb from "../components/breadcrumb";
import DeleteAssemblyModal from "../components/modals/deleteAssemblyModal";

//SourceFile screen
const SourceFile = () => {
  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo);
  const { sourceId } = useParams();
  const screenDetails = useSelector((state) => state?.SourceScreenData);
  const sourceIdTableData = useSelector((state) => state?.SourceIdTableData);
  const jsonIdTableData = useSelector((state) => state?.JsonIdTableData);
  const [table1Data, setTable1Data] = useState([]);
  const [table2Data, setTable2Data] = useState([]);
  const [table1Columns, setTable1Columns] = useState([]);
  const [table2Columns, setTable2Columns] = useState([]);
  const [isDeleteSourceFileModalOpen, setIsDeleteSourceFileModalOpen] =
    useState(false);
  const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
  const [isScaleModalOpen, setIsScaleModalOpen] = useState(false);
  const [selectedSourceFileDeleteItem, setSelectedSourceFileDeleteItem] =
    useState({});
  const [selectedDeleteAssemblyItem, setSelectedDeleteAssemblyItem] = useState(
    {}
  );
  const [isDeleteAssemblyModalOpen, setIsDeleteAssemblyModalOpen] =
    useState(false);
  const [isMyContainers, setIsMyContainers] = useState(false);
  const [isMyJsons, setIsMyJsons] = useState(false);
  const [requestJsonType, setRequestJsonType] = useState("DXF");
  const [selectedContainerRows, setSelectedContainerRows] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [isKnowMoreOpen, setIsKnowMoreOpen] = useState(false);
  const [selectedKnowMore, setSelectedKnowMore] = useState({});
  //Set Data for table 1
  useEffect(() => {
    setTable1Data(sourceIdTableData?.data ? sourceIdTableData?.data : []);
    setRequestJsonType(
      sourceIdTableData?.data[0] ? sourceIdTableData?.data[0]?.fileType : ""
    );
  }, [sourceIdTableData]);

  //Set Data for table 2
  useEffect(() => {
    setTable2Data(jsonIdTableData?.data ? jsonIdTableData?.data : []);
  }, [jsonIdTableData]);

  //Get api hit for all columns and dropdown details fetch
  useEffect(() => {
    dispatch(getSourceScreenElements());
    dispatch(
      getSourceDataById({
        selectedFileType: "source",

        containerID: sourceId,
      })
    );
    dispatch(
      getJsonDataById({
        selectedFileType: "JSON",

        containerID: sourceId,
      })
    );
  }, []);

  useEffect(() => {
    let filterData;
    if (isMyContainers) {
      filterData = sourceIdTableData?.data?.filter(
        (value) => value?.lastModifiedBy === userInfo?.email
      );
    } else {
      filterData = sourceIdTableData?.data ? sourceIdTableData?.data : [];
    }
    setTable1Data(filterData);
  }, [isMyContainers]);

  useEffect(() => {
    let filterData;
    if (isMyJsons) {
      filterData = jsonIdTableData?.data?.filter(
        (value) => value?.lastModifiedBy === userInfo?.email
      );
    } else {
      filterData = jsonIdTableData?.data ? jsonIdTableData?.data : [];
    }
    setTable2Data(filterData);
  }, [isMyJsons]);

  // Tables columns set
  useEffect(() => {
    let data1 = screenDetails?.data?.associatedValues?.table1Headers;
    let data2 = screenDetails?.data?.associatedValues?.table2Headers;

    if (data1) {
      let columns = data1?.map((value) => {
        if (
          value?.attributeName === "lastModifiedAt" ||
          value?.attributeName === "createdAt"
        ) {
          return {
            id: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              let date = formatDate(text);
              return <div>{date}</div>;
            },
          };
        } else if (value?.attributeName === "status") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              return <Tag color={getStatusColor(text)}>{text}</Tag>;
            },
          };
        } else if (value?.displayName === "Action") {
          return {
            key: value?.attributeName,
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (record) => {
              return (
                <div>
                  {record?.status === "Uploading" ? (
                    "-NA-"
                  ) : record?.status === "Failed" ? (
                    <Tag color={getStatusColor(record?.status)}>
                      {record?.status}
                    </Tag>
                  ) : (
                    <div style={{ display: "flex" }}>
                      <div
                        data-toggle="tooltip"
                        title="Delete"
                        onClick={() => {
                          setSelectedSourceFileDeleteItem(record);
                          setIsDeleteSourceFileModalOpen(true);
                        }}
                      >
                        <SvgComponent
                          type={"delete"}
                          width={"20px"}
                          customClass={"headerIcon"}
                          height={"20px"}
                          background={"transprent"}
                        />
                      </div>
                      <a
                        href={`${process.env.REACT_APP_BACKEND_API_URL}/api/download/source?sourceID=${record?.sourceFileID}`}
                        target="_blank"
                      >
                        {/* <button
                        type="button"
                        className="btn btn-outline-success p-1 "
                        data-toggle="modal"
                        data-target="#exampleModal2"
                      >
                        {"Download"}{" "}
                        <i className="now-ui-icons arrows-1_cloud-download-93"></i>
                      </button> */}
                        <div data-toggle="tooltip" title="Download">
                          <SvgComponent
                            type={"download"}
                            width={"20px"}
                            customClass={"headerIcon"}
                            height={"20px"}
                            background={"transprent"}
                          />
                        </div>
                      </a>
                    </div>
                  )}
                </div>
              );
            },
          };
        } else if (value?.attributeName === "sourceFileName") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            width: "20px",
            ellipsis: true,
            render: (text) => {
              return (
                <div
                  style={{
                    maxWidth: "170px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                </div>
              );
            },
          };
        }
        return {
          id: value?.attributeName,
          type: value?.type,
          sorter: sortColumn(value),
          dataIndex: value?.attributeName,
          title: value?.displayName,
        };
      });
      setTable1Columns(columns);
    }
    if (data2) {
      let columns = data2?.map((value) => {
        if (
          value?.attributeName === "lastModifiedAt" ||
          value?.attributeName === "createdAt"
        ) {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              let date = formatDate(text);
              return <div>{date}</div>;
            },
          };
        } else if (value?.displayName === "Actions") {
          return {
            key: value?.attributeName,
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (record) => {
              switch (record?.status) {
                case "Failed":
                  return (
                    <div className="d-flex">
                      <div
                        onClick={() => {
                          setSelectedDeleteAssemblyItem(record);
                          setIsDeleteAssemblyModalOpen(true);
                        }}
                        data-toggle="tooltip"
                        title="Delete"
                      >
                        <SvgComponent
                          type={"delete"}
                          width={"20px"}
                          customClass={"headerIcon"}
                          height={"20px"}
                          background={"transprent"}
                        />
                      </div>
                      <div
                        onClick={() => {
                          setIsKnowMoreOpen(true);
                          setSelectedKnowMore(record);
                        }}
                      >
                        Know More
                      </div>
                    </div>
                  );
                case "Generated":
                  return (
                    <div className="d-flex">
                      <div
                        onClick={() => {
                          setSelectedDeleteAssemblyItem(record);
                          setIsDeleteAssemblyModalOpen(true);
                        }}
                        data-toggle="tooltip"
                        title="Delete"
                      >
                        <SvgComponent
                          type={"delete"}
                          width={"20px"}
                          customClass={"headerIcon"}
                          height={"20px"}
                          background={"transprent"}
                        />
                      </div>
                      <a
                        href={`${process.env.REACT_APP_BACKEND_API_URL}/api/download/assembly?assemblyID=${record?.assemblyID}`}
                        target="_blank"
                      >
                        <div data-toggle="tooltip" title="Download">
                          <SvgComponent
                            type={"download"}
                            width={"20px"}
                            customClass={"headerIcon"}
                            height={"20px"}
                            background={"transprent"}
                          />
                        </div>
                      </a>
                    </div>
                  );
                case "Generating":
                  return "-NA-";

                default:
                  return null;
              }
            },
          };
        } else if (value?.attributeName === "assemblyID") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text, record) => {
              if (record?.status === "Generated") {
                return (
                  <Link to={`/geometry/assembly-viewer/${text}`}>{text}</Link>
                );
              } else {
                return text;
              }
            },
          };
        } else if (value?.attributeName === "status") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            render: (text) => {
              return <Tag color={getStatusColor(text)}>{text}</Tag>;
            },
          };
        } else if (value?.attributeName === "sourceID") {
          return {
            key: value?.attributeName,
            type: value?.type,
            sorter: sortColumn(value),
            dataIndex: value?.attributeName,
            title: value?.displayName,
            width: "20px",
            ellipsis: true,
            render: (text) => {
              return (
                <div
                  style={{
                    maxWidth: "170px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {text}
                </div>
              );
            },
          };
        }
        return {
          key: value?.attributeName,
          type: value?.type,
          sorter: sortColumn(value),
          dataIndex: value?.attributeName,
          title: value?.displayName,
        };
      });
      setTable2Columns(columns);
    }
  }, [screenDetails?.data]);

  const handleTable1Search = (searchText) => {
    let data = screenDetails?.data?.associatedValues?.table1Headers;
    let keysData = data?.filter((item) => {
      if (item?.shouldSearch) {
        return item?.attributeName;
      } else {
        return null;
      }
    });
    let valuesData = sourceIdTableData?.data;

    let filterData = getSearchFilterData(valuesData, keysData, searchText);

    setTable1Data(filterData || []);
  };

  const handleTable2Search = (searchText) => {
    let data = screenDetails?.data?.associatedValues?.table2Headers;
    let keysData = data?.filter((item) => {
      if (item?.shouldSearch) {
        return item?.attributeName;
      } else {
        return null;
      }
    });
    let valuesData = jsonIdTableData?.data;

    let filterData = getSearchFilterData(valuesData, keysData, searchText);

    setTable2Data(filterData || []);
  };

  const handleMyContainers = (check) => {
    setIsMyContainers(check);
  };

  const handleMyJsons = (check) => {
    setIsMyJsons(check);
  };

  const onContainerIdTableCheckboxChange = (
    selectedRowKeys,
    selectedRows,
    ...args
  ) => {
    // setRequestJsonType(selectedRows[0] ? selectedRows[0]?.fileType : "");
    // Place the selected rows in state

    // if (args[0]?.type === "all") {
    if (args[0]?.type === "single") {
      setSelectedContainerRows(selectedRows);
      setSelectedRowKeys(selectedRowKeys);
    }
    // else if (isAllSelect.current && args[0]?.type === "single") {
    //   setSelectedContainerRows([...table1Data]);
    //   setSelectedRowKeys(table1Data?.map((item) => item.key));
    // } else {
    //   setSelectedContainerRows([]);
    //   setSelectedRowKeys([]);
    // }

    // setSelectedContainerRows(selectedRows);

    // setSelectedRowKeys(selectedRowKeys);
    // Change the all select flag of selction rows to false as some files are unselect
    // if (isAllSelect) {
    //   setIsAllSelect(false);
    // }
  };
  const rowSelection = {
    type: requestJsonType === "DXF" ? "radio" : "checkbox",
    selectedRowKeys: selectedRowKeys,
    onChange: onContainerIdTableCheckboxChange,
    getCheckboxProps: (record) => ({
      // disabled: isSelectableDisabled(record),
      // Column configuration not to be checked
      // name: record.name,
    }),
    // columnTitle: "Select to Compare",
    // columnWidth: 60,
    onSelectAll: (allSelect) => {
      // Make select all flag true so all rows can be passed to api.
      if (allSelect) {
        setSelectedContainerRows([...table1Data]);
        setSelectedRowKeys(table1Data?.map((item) => item.key));
      } else {
        setSelectedContainerRows([]);
        setSelectedRowKeys([]);
      }
    },
  };

  const RenderRequestJsonButton = () => {
    return (
      <>
        <Button
          style={{ marginTop: "5px", display: "flex", alignItems: "center" }}
          type="primary"
          // shape="round"
          // size="large"
          icon={<i class="now-ui-icons ui-1_simple-add"></i>}
          // onClick={handleRequestJson}
          onClick={() => {
            setIsScaleModalOpen(true);
          }}
          // loading={requestFileLoading}
          disabled={selectedContainerRows?.length == 0}
        >
          Request New Assembly For Selected Files
        </Button>

        {/* {Object.keys(requestJsonResponse).length > 0 && (
          <div className="mt-2">
            {requestJsonResponse?.data?.responseType === "success" ? (
              <SuccessMsg message={requestJsonResponse?.data?.responseDesc} />
            ) : (
              <ErrorMsg message={"Assembly generation failed "} />
            )}
          </div>
        )} */}
      </>
    );
  };
  return (
    <div className="main-panel ps">
      {Object.keys(screenDetails?.data).length > 0 && (
        <div className="content padd-top">
          <div className="row">
            <DefaultBreadCrumb
              data={[
                { title: "Home", path: "/" },
                { title: "Geometry", path: "/geometry" },
                {
                  title: sourceId,
                  path: `/geometry/${sourceId}`,
                  active: true,
                },
              ]}
            />
          </div>
          <div className="row">
            <TableHeader
              iconType={"GeoFileIcon"}
              title={` ${screenDetails?.data?.associatedValues?.table1Name} - ${sourceId}`}
              checkBoxTitle={"Uploaded by me"}
              buttonTitle={"Upload New Source File"}
              handleSearch={handleTable1Search}
              handleMyFiles={handleMyContainers}
              onButtonClick={() => {
                setIsUploadFileModalOpen(true);
              }}
            />
          </div>
          <div className="row">
            <GenericTable
              isLoading={sourceIdTableData?.loading}
              columns={table1Columns}
              data={table1Data}
              enableRowSelection={true}
              rowSelection={rowSelection}
              footer={() => <RenderRequestJsonButton />}
              // onSelectRowEvent={onContainerIdTableCheckboxChange}
            />
          </div>

          <div className="row">
            <TableHeader
              iconType={"GeoAssyIcon"}
              title={`${screenDetails?.data?.associatedValues?.table2Name}`}
              checkBoxTitle="Requested by me"
              // buttonTitle={"Request for New JSON"}
              onButtonClick={() => {
                console.log("Button click");
              }}
              handleSearch={handleTable2Search}
              handleMyFiles={handleMyJsons}
            />
          </div>
          <div className="row">
            <GenericTable
              isLoading={jsonIdTableData?.loading}
              columns={table2Columns}
              data={table2Data}
            />
          </div>
        </div>
      )}
      <DeleteSourceFileModal
        isModalOpen={isDeleteSourceFileModalOpen}
        setIsModalOpen={setIsDeleteSourceFileModalOpen}
        selectedDeleteItem={selectedSourceFileDeleteItem}
        refetchApi={() => {
          dispatch(
            getSourceDataById({
              selectedFileType: "source",

              containerID: sourceId,
            })
          );
        }}
      />

      <DeleteAssemblyModal
        isModalOpen={isDeleteAssemblyModalOpen}
        setIsModalOpen={setIsDeleteAssemblyModalOpen}
        selectedDeleteItem={selectedDeleteAssemblyItem}
        refetchApi={() => {
          dispatch(
            getJsonDataById({
              selectedFileType: "JSON",
              containerID: sourceId,
            })
          );
        }}
      />

      <UploadFileModal
        isModalOpen={isUploadFileModalOpen}
        setIsModalOpen={setIsUploadFileModalOpen}
        refetchApi={() => {
          dispatch(getFileUploadOptions());
          dispatch(
            getSourceDataById({
              selectedFileType: "source",
              containerID: sourceId,
            })
          );
          dispatch(
            getJsonDataById({
              selectedFileType: "JSON",
              containerID: sourceId,
            })
          );
        }}
      />
      <ScaleModal
        isModalOpen={isScaleModalOpen}
        setIsModalOpen={setIsScaleModalOpen}
        selectedItem={{
          selectedContainerRows,
          sourceId,
          requestJsonType,
          // isAssemblyFilesGenerated: isSelectableDisabled(selectedContainerRows),
        }}
        refetchApi={() => {
          // dispatch(getSourceScreenElements());
          dispatch(
            getSourceDataById({
              selectedFileType: "source",
              containerID: sourceId,
            })
          );
          dispatch(
            getJsonDataById({
              selectedFileType: "JSON",
              containerID: sourceId,
            })
          );
        }}
      />

      <KnowMoreModal
        isModalOpen={isKnowMoreOpen}
        setIsModalOpen={setIsKnowMoreOpen}
        selectedItem={selectedKnowMore}
      />
    </div>
  );
};

export default SourceFile;
