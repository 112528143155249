
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {reaetSendRecordDataToBackEndReducer, deleteProperty} from '../../axios/index'  
import {Link, useNavigate} from "react-router-dom";
import loader from '../../loader.gif';
import './index.css';
 
function DeletePopupWindow(props) {  
  const dispatch = useDispatch();
  //deleteRecordApiStatus
  const deleteRecordApiStatus = useSelector((state)=>state.deleteRecordApi) 
  const [statusType, setStatusType] = React.useState('inDraft');
  const navigate = useNavigate();  
  const renderDeleteSvg = () => (
    <svg width='40px' height='40px' viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m25 30 3.645 47.383A5 5 0 0 0 33.63 82h32.74a5 5 0 0 0 4.985-4.617L75 30"
        fill="#f37e98"
      />
      <path
        d="M65 38v35c0 1.65-1.35 3-3 3s-3-1.35-3-3V38c0-1.65 1.35-3 3-3s3 1.35 3 3zm-12 0v35c0 1.65-1.35 3-3 3s-3-1.35-3-3V38c0-1.65 1.35-3 3-3s3 1.35 3 3zm-12 0v35c0 1.65-1.35 3-3 3s-3-1.35-3-3V38c0-1.65 1.35-3 3-3s3 1.35 3 3zm36-14h-4l-1.835-3.058A4 4 0 0 0 67.735 19h-35.47a4 4 0 0 0-3.43 1.942L27 24h-4a3 3 0 1 0 0 6h54a3 3 0 1 0 0-6z"
        fill="#f15b6c"
      />
      <path
        d="M66.37 83H33.63c-3.116 0-5.744-2.434-5.982-5.54l-3.645-47.383 1.994-.154 3.645 47.384A4.018 4.018 0 0 0 33.63 81h32.74a4.018 4.018 0 0 0 3.988-3.692l3.645-47.385 1.994.154-3.645 47.384C72.113 80.566 69.485 83 66.37 83zM56 20a1 1 0 0 1-1-1v-3c0-.552-.449-1-1-1h-8c-.551 0-1 .448-1 1v3a1 1 0 1 1-2 0v-3c0-1.654 1.346-3 3-3h8c1.654 0 3 1.346 3 3v3a1 1 0 0 1-1 1z"
        fill="#1f212b"
      />
      <path
        d="M77 31H23c-2.206 0-4-1.794-4-4s1.794-4 4-4h3.434l1.543-2.572A5.026 5.026 0 0 1 32.265 18h35.471c1.747 0 3.389.931 4.287 2.428L73.566 23H77c2.206 0 4 1.794 4 4s-1.794 4-4 4zm-54-6c-1.103 0-2 .897-2 2s.897 2 2 2h54c1.103 0 2-.897 2-2s-.897-2-2-2h-4a1 1 0 0 1-.857-.485l-1.835-3.058A3.017 3.017 0 0 0 67.735 20h-35.47a3.015 3.015 0 0 0-2.572 1.457l-1.835 3.058c-.181.3-.507.485-.858.485h-4z"
        fill="#1f212b"
      />
      <path
        d="M61.5 25h-36a.5.5 0 0 1 0-1h36a.5.5 0 0 1 0 1zm12 0h-5a.5.5 0 0 1 0-1h5a.5.5 0 0 1 0 1zm-7 0h-2a.5.5 0 0 1 0-1h2a.5.5 0 0 1 0 1zM50 76c-1.654 0-3-1.346-3-3V38c0-1.654 1.346-3 3-3s3 1.346 3 3v25.5a.5.5 0 0 1-1 0V38c0-1.103-.897-2-2-2s-2 .897-2 2v35c0 1.103.897 2 2 2s2-.897 2-2v-3.5a.5.5 0 0 1 1 0V73c0 1.654-1.346 3-3 3zm12 0c-1.654 0-3-1.346-3-3V47.5a.5.5 0 0 1 1 0V73c0 1.103.897 2 2 2s2-.897 2-2V38c0-1.103-.897-2-2-2s-2 .897-2 2v1.5a.5.5 0 0 1-1 0V38c0-1.654 1.346-3 3-3s3 1.346 3 3v35c0 1.654-1.346 3-3 3z"
        fill="#1f212b"
      />
      <path
        d="M59.5 45a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 1 1 0v2a.5.5 0 0 1-.5.5zM38 76c-1.654 0-3-1.346-3-3V38c0-1.654 1.346-3 3-3s3 1.346 3 3v35c0 1.654-1.346 3-3 3zm0-40c-1.103 0-2 .897-2 2v35c0 1.103.897 2 2 2s2-.897 2-2V38c0-1.103-.897-2-2-2z"
        fill="#1f212b"
      />
    </svg>
  )

  const renderModuleDetails = () =>{
    if(deleteRecordApiStatus.loading &&  deleteRecordApiStatus.responseType===''){
    if(props.moduleFieldDataSet[props.moduleName]!== undefined && props.moduleFieldDataSet[props.moduleName]['header']){
      return(
        <>
          <div className='deleteWanringMsg'>
            <p><span>Are you sure </span> you want to delete  {props.moduleName} - <b>{props.moduleFieldDataSet[props.moduleName]['header']['name']}</b> with ID - <b>{props.moduleFieldDataSet[props.moduleName]['header']['key']}</b> </p>
            <p>You can't undo this action.</p>
          </div>         
        </> 
      )
      }
    }
    else if(deleteRecordApiStatus.loading===false &&  deleteRecordApiStatus.responseType!==''){
      if(deleteRecordApiStatus.responseType==='Failed'){
        return( 
               <div ClassName='failedMsg'>
                    <div className='failed'><i className='now-ui-icons ui-1_simple-remove'></i>&nbsp; {deleteRecordApiStatus.responseType}</div>
                    <div className='failedTxt'>{renderingTxt()}</div>
               </div>
      )
  
      }
      else if(deleteRecordApiStatus.responseType==='success'){
        return( 
               <div ClassName='failedMsg'>
                    <div className='successMsg'><i className='now-ui-icons ui-1_check'></i>&nbsp; {deleteRecordApiStatus.responseType}</div>
                    <div className='successTxt'>{renderingTxt2()}</div>
               </div>
        )
  
      }
       
    }
  }
  const handleDeleteEvent = (e)=>{
    dispatch(deleteProperty({id: props.urlId, type: props.moduleName}))
  }
  const closeModal =()=>{
    let tempPath = '../';
    navigate(tempPath, { replace: true });     
    //dispatch(reaetSendRecordDataToBackEndReducer({}))   
  }
  const renderingTxt=()=>{
    let errtxt = 'Error';
    if(deleteRecordApiStatus.responseDesc!==undefined){    
      
        errtxt = deleteRecordApiStatus.responseDesc
    
    }
   
    return(
      errtxt
    )
  }
  const renderingTxt2=()=>{
    let errtxt = 'Success';
    if(deleteRecordApiStatus.responseDesc!==''){
      
       
        errtxt = deleteRecordApiStatus.responseDesc
      }
    
   
    return(
      errtxt
    )
  }
  const renderCloseButton =()=>{
    if(deleteRecordApiStatus.loading===false &&  deleteRecordApiStatus.responseType!==''){
      return(<button type="button" className="close"   aria-label="Close" onClick={(e)=>{closeModal(e)}}>
      <span aria-hidden="true">&times;</span>
    </button>  )
    }
    else{
      return(<button type="button" className="close"   aria-label="Close" onClick={(e)=>{props.closeModal(e)}}>
      <span aria-hidden="true">&times;</span>
    </button>  )
    }
  }

  const renderFooterBtn =() =>{

   if(deleteRecordApiStatus.responseType==='' && deleteRecordApiStatus.loading!==false){
      return(<> <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Cancel</button>
      <button type="button" className="btn btn-primary" onClick={(e)=>handleDeleteEvent(e)} ><i className='now-ui-icons ui-1_check' ></i> &nbsp;Continue</button></>)
    }
    else if(deleteRecordApiStatus.responseType==='Failed' && deleteRecordApiStatus.loading===false){
      return(
        <button type="button" className="btn btn-secondary"   onClick={(e)=>{props.closeModal(e)}}><i className='now-ui-icons ui-1_simple-remove'></i> &nbsp;Close</button>
      )
    }
    else if(deleteRecordApiStatus.responseType==='success' && deleteRecordApiStatus.loading===false){
      return(
        <button type="button" className="btn btn-primary"   onClick={(e)=>{closeModal(e)}} > &nbsp;Ok</button>
      )
    }
  }
  return (
    <div className="modal fade show" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{display:'block'}}>
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title delete" id="exampleModalLabel">
               {renderDeleteSvg()} 
               Delete {props.moduleName}                 
            </h5>
            {renderCloseButton()}          
          </div>
          <div className="modal-body">
            <div className="card downloadFile" style={{'boxShadow':'none'}}>
                  {renderModuleDetails()}
                  {/* renderCardBody() */}                  
                </div>
          </div>
          <div className="modal-footer">
           {renderFooterBtn()}


          </div>
        </div>
      </div>
    </div>
  );
}

export default DeletePopupWindow;
