import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Input } from "antd";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  resetLatestVersion,
  resetSearchKey,
  resetUserModel,
  updateLatestVersion,
  updateSearchKey,
  updateUserModel,
} from "../../../axios/index";
import "./index.css";

const { Search } = Input;

function PageHeadeActionButtons(props) {
  const dispatch = useDispatch();
  const myModel = useSelector((state) => state.userModel);
  const latestVersion = useSelector((state) => state.latestVersion);
  const searchKey = useSelector((state) => state.searchKey);
  const type = props.metaData.type ? props.metaData.type.toLowerCase() : props.metaData.type;
  const [searchValue, setSearchValue] = useState("");

  const handleSearchKey = (e) => {
    localStorage.setItem("searchKey", e);
    dispatch(updateSearchKey(e));
  };

  const handleMyModel = (e) => {
    const param = { isChecked: e.target.checked };
    dispatch(updateUserModel(param));
  };

  const handleLatestVersion = (e) => {
    const param = { isLatestVersionChecked: e.target.checked };
    dispatch(updateLatestVersion(param));
  };

  const handleClear = (e) => {
    dispatch(resetSearchKey());
    dispatch(resetUserModel());
    dispatch(resetLatestVersion());
    setSearchValue("")
  };

  return (
    <div className="header-btn-grp">
      {(type === "fiber" || type === "material" || type === "sam") && (
        <>
          <Checkbox
            id="defaultCheck1"
            checked={latestVersion.isLatestVersionChecked}
            onChange={(e) => handleLatestVersion(e)}
          >
            Latest Versions
          </Checkbox>
          <Checkbox id="defaultCheck2" checked={myModel.isChecked} onChange={(e) => handleMyModel(e)}>
            My Models
          </Checkbox>
        </>
      )}
      {(type === "fiber" || type === "material" || type === "sam" || type === "compression") && (
        <Search placeholder="Search" value={searchValue} onSearch={handleSearchKey}  onChange={(e) => setSearchValue(e.target.value)} style={{ width: 200 }} />
      )}
      {(searchKey || myModel.isChecked || latestVersion.isLatestVersionChecked) && (
        <Button type="dashed" onClick={() => handleClear()}>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <CloseOutlined /> <span>Clear</span>
          </div>
        </Button>
      )}

      {(props.metaData.userRole === "NL_ADMIN" || props.metaData.userRole === "NL_EDIT") && (
        <Button type="primary" style={{backgroundColor: "#06509e"}}>
          <Link to={`${props.metaData.path}/-1`} class="link1">
            <div style={{ display: "flex", alignItems: "center", gap: 8, color: "#fff" }}>
              <PlusOutlined /> <span>New {props.metaData.type}</span>
            </div>
          </Link>
        </Button>
      )}
    </div>
  );
}

export default PageHeadeActionButtons;
