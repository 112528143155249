import React from 'react';

const AddPointLG = (props) => (
  <svg
    width="64mm"
    height="64mm"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="layer1">
      <path
        style={{ fill: 'none', stroke: '#969696', strokeWidth: 1.5 }}
        d="M 0.31514213,47.082234 27.228279,8.5088358 63.973851,25.337427"
      />
    </g>
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="31.499689"
      y="28.366505"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#969696', strokeWidth: 1.6, fillOpacity: 1 }}
      width="13.046884"
      height="12.353571"
      x="20.793974"
      y="0.90140206"
      ry="0.49824521"
    />
    <path
      style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1.29944 }}
      d="m 35.864955,57.270688 3.401743,-18.764681 13.51975,13.38137 -6.629038,1.153566 3.488965,8.767106 -4.273983,1.461185 -3.663418,-8.844011 z"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.14826 }}
      d="m 42.661383,44.074199 v 9.576608"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.18757 }}
      d="M 47.223828,48.844401 H 37.865183"
    />
  </svg>
);

export default AddPointLG;
