
export default (
    state = {
        loading: false,
        error: false,
        responseType: '',        
        message: '',
        data: {             
            
            fields:{
                    type:{ label:'Type',key:'type',value:'physical', render:true, editable:false,  type:'text'},
                    creationDate:{ label:'Creation Date', key:'creationDate', value:'',render:false,editable:false, type:'date'},
                    propertyName:{ label:'Property Name', key:'propertyName', value:'',render:true, editable:false, type:'text' },
                    source:{ label:'Data Source', key:'source', value:'', render:true, editable:true, type:'text'},
                    author:{ label:'Author', key:'author', value:'', render:true,  editable:false, type:'text'},
                    description:{ label:'Description', key:'description', value:'',editable:true, render:true,  type:'textarea'},
                 },
                default:{
                    type: "physical",
                    creationDate: "2022-08-22",
                    propertyName: "",
                    metaData: {
                        source: "",
                        author: "",
                        description: ""
                    }
                }
            
           
        }
    }, action) => {
    switch (action.type) {
        case 'DEFAULT_META_DATA_FIELDS':
            return state;
        default:
            return state;
    }

};