export const runAutoCalculation=(item, calcFormula, i)=>{
    return(eval(calcFormula))
}

export const  runAutoCalculationCheck=(tempDataSet,key, fields) =>{
    fields['fields'][key]['targetCalculatorField'].map((key, index)=>{
       if(fields['calc'][key]['targetCalculationColumn'].length>0){
        let calcModule =fields['calc'][key]['module'];
        let calcFieldIndex = fields['calc'][key]['index'];
        let calcFormula = fields['calc'][key]['formulaUsed'];
        let inputVals = {};
        fields['calc'][key]['targetCalculationColumn'].map((fieldKey)=>{
          let module = fields['fields'][fieldKey]['module']
          let fieldIndex = fields['fields'][fieldKey]['index']
          inputVals[fieldKey]= tempDataSet[module]['propertyData'][fieldIndex][fieldKey]['value']

          if(module==='sam'){
            if(fieldKey ==='solidsDryGelRadius' || fieldKey==='solidsDryGelDensity' || fieldKey ==='contactAngle'){
                 
              if(inputVals[fieldKey]==='' || inputVals[fieldKey]===null || inputVals[fieldKey]===0 ||inputVals[fieldKey]==='-'){
                inputVals[fieldKey] = 1;
              }
            }
          }
        })
        
        tempDataSet[calcModule]['propertyData'][calcFieldIndex][key]['value'] = runAutoCalculation(inputVals,calcFormula)
        
       } 
    })
    return tempDataSet;
  }

  export const autoCalulation = (module, tempDataSet, fields )=>{     
    if(Object.keys(fields.calc).length>0){
         Object.keys(fields.calc).map((key, index)=>{
           fields['calc'][key]['targetCalculationColumn'].map((item)=>{
                 
                if(fields['fields'][item]['module']===module && fields['fields'][item]['targetCalculatorField'].length>0){
                     
                    tempDataSet =  runAutoCalculationCheck(tempDataSet,item, fields)   
                }
            })
        })
    }
   return tempDataSet;
  }





 /* log gamma */
export const  loggamma=(x)=>  {
var v=1;
var w=0;
var z=0;
  while ( x<8 ) { v*=x; x++ }
  w=1/(x*x);
  return ((((((((-3617/122400)*w + 7/1092)*w
   -691/360360)*w + 5/5940)*w
   -1/1680)*w + 1/1260)*w
   -1/360)*w + 1/12)/x + 0.5 * Math.log(2*Math.PI)-Math.log(v)-x+(x-0.5)*Math.log(x) ;

}

 /* gamma */ 
 export const  gamma=(x)=> { 
  if ( x <= 0 ) {
    if (Math.abs(x)-Math.floor(Math.abs(x))==0 )
      return "ComplexInfinity" ;
    else return Math.PI/( Math.sin(Math.PI*x) * Math.exp( loggamma(1-x) ) );
    }
  else
    return Math.exp(loggamma(x)) ;
}


  