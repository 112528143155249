import React from 'react';

const RemoveHoleLG = (props) => (
  <svg
    width="64mm"
    height="64mm"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props} // This allows passing additional props, such as style or className
  >
    <rect
      style={{ fill: '#e6e6e6', stroke: '#969696', strokeWidth: 1.57924, fillOpacity: 1 }}
      width="49.847588"
      height="49.401909"
      x="6.7639303"
      y="7.5661516"
      ry="0.53206915"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#0000ff', strokeWidth: 1.6, strokeOpacity: 1 }}
      d="M 47.865845,35.832533 57.02745,7.5460791 76.943436,29.623489 Z"
      transform="translate(-31.197479,7.1308524)"
    />
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="19.367804"
      y="9.5475597"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="39.203148"
      y="30.012291"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="9.520977"
      y="36.96487"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#969696', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="50.166836"
      y="0.95406669"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#969696', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="0.87481827"
      y="0.95406705"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#969696', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="50.25597"
      y="51.048306"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#969696', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="1.0530887"
      y="50.959171"
      ry="0.49824521"
    />
    <path
      style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1.29944 }}
      d="m 26.683983,55.666248 3.401743,-18.764681 13.51975,13.38137 -6.629038,1.153566 3.488965,8.767106 -4.273983,1.461185 -3.663418,-8.844011 z"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.18757 }}
      d="M 38.042856,47.239961 H 28.684211"
    />
  </svg>
);

export default RemoveHoleLG;
