import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {getLabvantageReferenceData} from '../axios/index'  
import DataGrid from "../components/tables/dataGrid" 
import DefaultBreadCrumb from "../components/breadcrumb"
import PageHeader from "../components/header/pageHeader"


const LabvantageAPIs = () => {
    const dispatch = useDispatch();    
    const data = useSelector((state)=> state.labvantage);
    // const [subRegion, setSubRegion] = React.useState('');
    // const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    // const selectedSubRegion = useSelector((state) => state.updatedSubRegion)
    // const oktaAuth = useSelector((state) => state.oktaAuth);

    useEffect(() => {
         dispatch(getLabvantageReferenceData());
    }, []);

       
    return (
        <div className="main-panel ps">
            <div className="content padd-top">
            <div className="row">
                <DefaultBreadCrumb  data={[{'title':'Home',path:'/'}, {'title':'Labvantage APIs',path:'/labvantage-apis', active:true }]}/>  
            </div> 
            <div className="row">
                <PageHeader iconType ={"labvantageApis"} title = {" All Labvantage APIs"}  />
            </div>
            <div className="row">
                <DataGrid  tableData = {data} path ='labvantage-details' enableLink={false}/>
            </div>
            </div>
     </div>
    );
}

export default LabvantageAPIs;