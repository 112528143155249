import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Popover, Space, Tag } from "antd";

import "./index.css";
import { getGraphData } from "../../axios/index";
import { userRoles } from "../utils/userRoles";
import {
  CloseOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  LineChartOutlined,
  SaveFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { JSONFileTypeIcon, XMLFileTypeIcon } from "../../icons/svgComponent";

function ActionButtons(props) {
  const dispatch = useDispatch();
  const urlParams = useParams();

  const [isPopover, setIsPopover] = useState(false);
  const userInfo = useSelector((state) => state.userInfo);
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    if (userInfo.sAMAccountName) {
      setUserRole(userRoles(userInfo.Roles));
    }

    dispatch(getGraphData());
  }, [userInfo]);

  const handleDeletEvent = (status) => {
    return props.handleDeletEvent(status);
  };

  const handleEvent = (status) => {
    return props.handleEditStatus(status);
  };

  const handleSave = (e) => {
    return props.handleSaveEvent(true);
  };

  const handleAdvancedSearchEvent = (e) => {
    return props.handleAdvancedSearchStatus("search", true);
  };

  const handleGraphVisualizer = (e) => {
    return props.handleAdvancedSearchStatus("graph", true);
  };

  const handlePopoverChange = (event) => {
    setIsPopover(event);
  };

  const renderActionButtons = () => {
    if (props.editStatus) {
      return (
        <>
          {props.moduleName === "material" ? (
            <Button type="text" size="small" className="action-btn" onClick={() => handleGraphVisualizer(true)}>
              <Space size={4}>
                <LineChartOutlined /> <span>Visualizer</span>
              </Space>
            </Button>
          ) : null}

          <Button type="text" size="small" className="action-btn" onClick={(e) => handleAdvancedSearchEvent(e)}>
            <Space size={4}>
              <SearchOutlined /> <span>Search & Import</span>
            </Space>
          </Button>

          <Button type="primary" onClick={() => handleSave()}>
            <Space size={4}>
              <SaveFilled /> <span>Save</span>
            </Space>
          </Button>

          <Button type="dashed" danger onClick={() => handleEvent(false)}>
            <Space size={4}>
              <CloseOutlined /> <span>Cancel</span>
            </Space>
          </Button>
        </>
      );
    } else {
      return (
        <>
          {(userRole === "NL_ADMIN" || userRole === "NL_EDIT") && (
            <>
              {props.moduleName === "material" && urlParams.id !== -1 ? (
                <Button type="text" size="small" className="action-btn" onClick={() => handleGraphVisualizer(true)}>
                  <Space size={4}>
                    <LineChartOutlined /> <span>Visualizer</span>
                  </Space>
                </Button>
              ) : null}

              {props.moduleName === "selectedMaterialId" && urlParams.id !== -1 ? (
                <Button type="text" size="small" className="action-btn" onClick={() => handleGraphVisualizer(true)}>
                  <Space size={4}>
                    <LineChartOutlined /> <span>Visualizer</span>
                  </Space>
                </Button>
              ) : null}

              <Button type="text" size="small" className="action-btn" onClick={() => handleEvent(true)}>
                <Space size={4}>
                  <EditOutlined /> <span>Edit {props.moduleName === "sam" ? "SAM" : props.moduleName}</span>
                </Space>
              </Button>
              <Button type="text" size="small" className="action-btn" danger onClick={() => handleDeletEvent(true)}>
                <Space size={4}>
                  <DeleteOutlined /> <span>Delete {props.moduleName === "sam" ? "SAM" : props.moduleName}</span>
                </Space>
              </Button>
            </>
          )}
        </>
      );
    }
  };

  const handleDownload = (type) => {
    var win = window.open(
      `${process.env.REACT_APP_BACKEND_API_URL}/api/download/material?moduleName=${props.moduleName}&downloadType=${type}&id=${urlParams.id}`,
      "_blank"
    );
    win.focus();
  };

  const renderStatus = () => {
    if (
      props.moduleName &&
      props.moduleFieldDataSet[props.moduleName] !== undefined &&
      props.moduleFieldDataSet[props.moduleName]["header"] !== undefined
    ) {
      const value = props.moduleFieldDataSet[props.moduleName]["header"]["status"];
      return (
        <Tag color={value === "In Production" ? "green" : "orange"}>
          {props.moduleFieldDataSet[props.moduleName]["header"]["status"]}
        </Tag>
      );
    }
  };

  const downloadPopover = () => {
    return (
      <Space>
        <Button onClick={(e) => handleDownload("json")} style={{ padding: 8, height: "auto" }}>
          <JSONFileTypeIcon
            style={{
              fontSize: 40,
            }}
          />
        </Button>

        {props.moduleName === "material" ? (
          <Button onClick={(e) => handleDownload("xml")} style={{ padding: 8, height: "auto" }}>
            <XMLFileTypeIcon
              style={{
                fontSize: 40,
              }}
            />
          </Button>
        ) : (
          ""
        )}
      </Space>
    );
  };

  return (
    <div className="header-btn-grp">
      {renderStatus()}
      <Popover
        placement="bottom"
        content={downloadPopover}
        trigger="click"
        open={isPopover}
        onOpenChange={handlePopoverChange}
        arrowPointAtCenter
      >
        {["material", "sam", "fiber"].includes(props.moduleName) && urlParams.id !== -1 ? (
          <Button type="text" size="small" className="action-btn">
            <Space size={4}>
              <DownloadOutlined /> <span>Download</span>
            </Space>
          </Button>
        ) : null}
      </Popover>
      {renderActionButtons()}
    </div>
  );
}

export default ActionButtons;
