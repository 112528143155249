import React from 'react';

const RemovePointLG = (props) => (
  <svg
    width="64mm"
    height="64mm"
    viewBox="0 0 64 64"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="layer1">
      <path
        style={{ fill: 'none', stroke: '#000000', strokeWidth: 1.5 }}
        d="M 7.8916728,57.867648 27.228279,8.5088358 63.973851,25.337427"
      />
    </g>
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="20.793974"
      y="0.90140206"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="50.345108"
      y="18.246384"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="1.0530887"
      y="50.959171"
      ry="0.49824521"
    />
    <path
      style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1.29944 }}
      d="m 26.32744,31.510485 3.401743,-18.764681 13.51975,13.38137 -6.629038,1.153566 3.488965,8.767106 -4.273983,1.461185 -3.663418,-8.844011 z"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.18757 }}
      d="M 37.686313,23.084198 H 28.327668"
    />
  </svg>
);

export default RemovePointLG;
