export function arraysAreEqual(arr1, arr2) {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const set1 = new Set(arr1);
  const set2 = new Set(arr2);

  // Compare the sets
  for (let element of set1) {
    if (!set2.has(element)) {
      return false;
    }
  }

  return true;
}

export function doArraysMatch(arr1, arr2) {
  // Check if the arrays have the same length
  if (arr1.length !== arr2.length) {
    return false;
  }
  // Sort and compare the arrays element by element
  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();
  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }
  return true;
}

export function hasSpecialCharacters(inputString) {
  // Define a regex pattern that matches non-alphanumeric characters
  const regex = /[^A-Za-z0-9\s]/g;

  // Use the test method to check if the string contains any special characters
  return regex.test(inputString);
}

export function isValidContainerName(containerName) {
  const pattern = /^(?!.*--)[a-z0-9]([-a-z0-9]*[a-z0-9])?$/;

  const length = containerName.length;

  return (
    length >= 3 &&
    length <= 63 && // Check length constraint
    pattern.test(containerName) && // Check pattern
    containerName === containerName.toLowerCase() // Check lowercase letters
  );

  // const length = containerName.length;
  // const lowercaseName = containerName.toLowerCase();
  // const containsSpecialCharacters = /[^a-z0-9-]/.test(containerName);
  // const containsConsecutiveHyphens = /--/.test(containerName);

  // switch (true) {
  //   case length < 3 || length > 63:
  //     return "Container name should be greater than 3 and less than 25 characters";
  //   case lowercaseName !== containerName:
  //     return "Container name should be in lowercases";
  //   case containsConsecutiveHyphens:
  //     return "Consecutive Hyphens in container name are not allowed.";
  //   case containsSpecialCharacters:
  //     return "Special characters in container name are not allowed.";
  //   default:
  //     return "";
  // }
}

export function haveSameFiletype(files) {
  if (files.length === 0) {
    // If the array is empty, there's no conflict
    return true;
  }

  // Get the file extension of the first file
  const firstFileExtension = files[0].split(".").pop();

  for (const file of files) {
    // Get the file extension of the current file
    const fileExtension = file.split(".").pop();

    // Check if the current file has the same extension as the first file
    if (fileExtension !== firstFileExtension) {
      return false;
    }
  }

  // All files have the same extension
  return true;
}
