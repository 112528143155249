import React from 'react';

const AddConnectionLG = (props) => (
  <svg
    width="64mm"
    height="64mm"
    viewBox="0 0 64 64"
    {...props}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="layer1">
      <path
        style={{ fill: 'none', stroke: '#0000ff', strokeWidth: 1.5 }}
        d="M 9.6743858,59.828632 7.0836211,56.909496 55.416828,7.1537535"
      />
      <path
        style={{ opacity: 1, fill: '#ffffff', stroke: '#000000', strokeWidth: 1.6 }}
        d="M 8.0222089,57.046817 H 56.868546"
      />
    </g>
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="0.96395457"
      y="50.780899"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#c8c8c8', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="49.810291"
      y="50.780899"
      ry="0.49824521"
    />
    <rect
      style={{ fill: '#0000ff', stroke: '#000000', strokeWidth: 1.6 }}
      width="13.046884"
      height="12.353571"
      x="49.988564"
      y="1.0432038"
      ry="0.49824521"
    />
    <path
      style={{ fill: '#000000', stroke: '#000000', strokeWidth: 1.29944 }}
      d="m 31.801825,48.081777 3.401743,-18.764681 13.51975,13.38137 -6.629038,1.153566 3.488965,8.767106 -4.273983,1.461185 -3.663418,-8.844011 z"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.14826 }}
      d="m 38.598253,34.885288 v 9.576608"
    />
    <path
      style={{ fill: '#ffffff', stroke: '#ffffff', strokeWidth: 2.18757 }}
      d="M 43.160698,39.65549 H 33.802053"
    />
  </svg>
);

export default AddConnectionLG;
