import React from "react";
import Icon from "@ant-design/icons";

const Circle = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M240 0a240 240 0 1 0 0 480 240 240 0 0 0 0-480Zm0 360a120 120 0 1 1 0-240 120 120 0 0 1 0 240Z"></path>
  </svg>
);
const CircleFilled = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <circle cx="240" cy="240" r="240"></circle>
  </svg>
);
const Square = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M70.3 70.3h339.4v339.4H70.3z"></path>
  </svg>
);
const Triangle = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M240 60 32.2 420h415.7L240 60z"></path>
  </svg>
);
const X = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M240 240A240 240 0 0 0 0 480a240 240 0 0 0 240-240ZM240 240A240 240 0 0 0 0 0a240 240 0 0 0 240 240ZM480 0a240 240 0 0 0-240 240A240 240 0 0 0 480 0ZM480 480a240 240 0 0 0-240-240 240 240 0 0 0 240 240Z"></path>
  </svg>
);
const Diamond = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 240 240 0l240 240-240 240z"></path>
  </svg>
);
const CrossCirclePlus = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M347.5 168.3C324 184 296 156 311.7 132.5 348.8 76.8 310.7 0 239.2 0s-108.6 75.8-70.9 132.5C184 156 156 184 132.5 168.3A85.2 85.2 0 0 0 0 239.2v1.6c0 68 75.8 108.6 132.5 70.9C156 296 184 324 168.3 347.6A85.2 85.2 0 0 0 239.2 480h1.6c68 0 108.6-75.8 70.9-132.5C296 324 324 296 347.6 311.7c55.6 37.1 132.4-1 132.4-72.5s-75.8-108.6-132.5-70.9Z"></path>
  </svg>
);
const Donut = () => (
  <svg fill="currentColor" viewBox="0 0 480 480" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
    <path d="M480 0H240A240 240 0 0 0 0 240v240h240a240 240 0 0 0 240-240V0ZM360 240a120 120 0 0 1-120 120H120V240a120 120 0 0 1 120-120h120v120ZM480 0H240A240 240 0 0 0 0 240v240h240a240 240 0 0 0 240-240V0ZM360 240a120 120 0 0 1-120 120H120V240a120 120 0 0 1 120-120h120v120Z"></path>
  </svg>
);

const CircleIcon = (props) => <Icon component={Circle} {...props} />;
const CircleFilledIcon = (props) => <Icon component={CircleFilled} {...props} />;
const SquareIcon = (props) => <Icon component={Square} {...props} />;
const TriangleIcon = (props) => <Icon component={Triangle} {...props} />;
const XIcon = (props) => <Icon component={X} {...props} />;
const DiamondIcon = (props) => <Icon component={Diamond} {...props} />;
const CrossCirclePlusIcon = (props) => <Icon component={CrossCirclePlus} {...props} />;
const DonutIcon = (props) => <Icon component={Donut} {...props} />;

function LegendIcon({ index, style }) {
  switch (index) {
    case 1:
      return <CircleFilledIcon style={style} />;
    case 2:
      return <SquareIcon style={style} />;
    case 3:
      return <TriangleIcon style={style} />;
    case 4:
      return <XIcon style={style} />;
    case 5:
      return <DiamondIcon style={style} />;
    case 6:
      return <CrossCirclePlusIcon style={style} />;
    case 7:
      return <DonutIcon style={style} />;

    default:
      return <CircleIcon style={style} />;
  }
}

export default LegendIcon;
