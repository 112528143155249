let intinalState = {
    loading: true,
    error: false,
    responseType: '',        
    message: '',
    data: {}
}
export default (
    state = intinalState, action) => {
    switch (action.type) {

        case 'FETCH_QUERY_ID_FOR_SELECTED_PROPERTY':
            console.log("FETCH_QUERY_ID_FOR_SELECTED_PROPERTY ", action.payload.data)
            return {
                ...state,
                loading: false,
                responseType:action.payload.data.responseType,
                data:action.payload.data.responseData,
            };        
    case 'FETCH_QUERY_ID_FOR_SELECTED_PROPERTY_FAILED':    
            return state;
    case 'RESET_QUERY_ID_FOR_SELECTED_PROPERTY':
            return intinalState;        
        default:
            return state;

    }

};


// {
//     "labVantageDetail": {
//     "totalCount":,
//      "data": [
//             {
//                 "fieldLabel": "STM-00157",
//                 "queryId": "STM-00157",
//                 "dbColumnName": ""
//             }
//         ]
//     }
// }