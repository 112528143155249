let intinalState =  {
    loading: true,
    error: false,
    responseType: '', 
    responseDesc:[],       
    message: '',
    data: {},
     
}
export default (
    state = intinalState, action) => {
    switch (action.type) { 
        case 'DELETE_RECORD':
            return {
                loading: false,
                responseType:action.payload.data.responseType,
                responseDesc:action.payload.data.responseDesc,
            }
        case 'RESET_DELETE_RECORD':
            return intinalState;  
        case 'DELETE_RECORD_FAILED':
            return {
                loading: false,
                responseType:action.payload.data.responseType,
                responseDesc:action.payload.data.responseDesc,
            }    
        default:
            return state;

    }

};