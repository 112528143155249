import { Button, notification, Result, Space, Spin, Tag, Typography } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { saveAs } from "file-saver";
import { DownloadOutlined } from "@ant-design/icons";

import { getMaterialCurationById } from "../../axios";
import Chart from "./Chart";
import { symbols } from "../svg/symbols";
import LegendIcon from "./LegendIcon";

const { CheckableTag } = Tag;

function ViewCuration({ id, closeCuration }) {
  const dispatch = useDispatch();

  const axes = "STRAIN_STRESS";
  const smooth = true;
  const clip = false;
  const loadColor = "#FF0000"; // red
  const unloadColor = "#65B741"; // green
  const colors = useMemo(
    () => [
      "#0000FF", // Blue
      "#FFA500", // Orange
      "#800080", // Purple
      "#FF4E88", // pink
      "#543310", // brown
    ],
    []
  );

  const [loading, setLoading] = useState(false);
  const [isValidID, setIsValidID] = useState(true);
  const [finalJSON, setFinalJSON] = useState({});
  const [metadata, setMetadata] = useState({});
  const [dataPts, setDataPts] = useState([]);
  const [legendNames, setLegendNames] = useState([]);
  const [selectedCycle, setSelectedCycle] = useState(1);

  const convertRawJsonToGraphArray = (rawJson) => {
    const strainStress = rawJson.map((data) => {
      return data.Stress_kPa.map((y, i) => [data.Strain[i], y]);
    });
    const crossheadLoad = rawJson.map((data) => {
      return data.Load_gf.map((y, i) => [data.Crosshead_cm[i], y]);
    });

    return {
      STRAIN_STRESS: strainStress,
      CROSSHEAD_LOAD: crossheadLoad,
    };
  };

  const convertAvgJsonToGraphArray = (data) => {
    const load = data.loadStress.map((y, i) => [data.loadStrain[i], y]);
    const unload = data.unloadStress.map((y, i) => [data.unloadStrain[i], y]);

    return {
      LOAD: load,
      UNLOAD: unload,
    };
  };

  const generateDataPoints = useCallback(
    (dataSource, average) => {
      const series = dataSource.map((data, index) => {
        return {
          name: "Spec " + (index + 1),
          type: "line",
          smooth,
          clip,
          data: data,
          itemStyle: {
            color: colors[index % colors.length],
            opacity: 0.75,
          },
          lineStyle: {
            width: 1,
          },
          symbol: symbols[index % symbols.length],
          symbolSize: 5,
        };
      });
      setLegendNames(series.map((s) => s.name));

      const graphObjectAvg = convertAvgJsonToGraphArray(average);
      const loadSeries = {
        name: "Load Average",
        type: "line",
        smooth,
        clip,
        data: graphObjectAvg.LOAD,
        itemStyle: {
          color: loadColor,
        },
        lineStyle: {
          width: 8,
        },
        symbolSize: 8,
      };
      const unloadSeries = {
        name: "Unload Average",
        type: "line",
        smooth,
        clip,
        data: graphObjectAvg.UNLOAD,
        itemStyle: {
          color: unloadColor,
        },
        lineStyle: {
          width: 8,
        },
        symbolSize: 8,
      };
      series.push(loadSeries);
      series.push(unloadSeries);
      setDataPts(series);
    },
    [clip, colors, smooth]
  );

  const getSpecimentStressStrainObject = useCallback((object, testMethod) => {
    if (testMethod.startsWith("STM-00437")) {
      setSelectedCycle(object.cycle);
    }

    return object.finalJSON;
  }, []);

  const getData = useCallback(() => {
    setLoading(true);
    dispatch(getMaterialCurationById(id))
      .then((response) => {
        setLoading(false);
        if (response?.data?.responseData) {
          setMetadata(response.data.responseData.dataValues);
          setFinalJSON(response.data.responseData.finalJSON);

          const specimenStressStrain = getSpecimentStressStrainObject(
            response.data.responseData.finalJSON,
            response.data.responseData.dataValues.testMethod
          );
          const graphObject = convertRawJsonToGraphArray(specimenStressStrain);
          generateDataPoints(graphObject.STRAIN_STRESS, response.data.responseData.finalJSON.average[0]);
        } else {
          setIsValidID(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        notification.open({
          message: "Invalid ID!",
          type: "error",
          duration: 2,
        });
        setLoading(false);
        setIsValidID(false);
      });
  }, [dispatch, generateDataPoints, getSpecimentStressStrainObject, id]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <div>
      <Spin spinning={loading} size="large">
        <div style={{ height: "calc(100vh - 120px)", overflow: "auto" }}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <Space wrap>
              <span>
                <Typography.Text strong>Test Method: </Typography.Text>
                <Typography.Text>{metadata.testMethod || "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Material ID: </Typography.Text>
                <Typography.Text>{metadata.materialID || "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Gauge Length: </Typography.Text>
                <Typography.Text>{metadata.length ? metadata.length + "mm" : "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Width: </Typography.Text>
                <Typography.Text>{metadata.width ? metadata.width + "mm" : "-"}</Typography.Text>
              </span>
              <span>
                <Typography.Text strong>Thickness: </Typography.Text>
                <Typography.Text>{metadata.thickness ? metadata.thickness + "mm" : "-"}</Typography.Text>
              </span>
              {metadata?.testMethod?.startsWith("STM-00437") && (
                <span>
                  <Typography.Text strong>Cycle: </Typography.Text>
                  <Typography.Text>{selectedCycle}</Typography.Text>
                </span>
              )}
            </Space>

            <Button
              type="ghost"
              size="small"
              onClick={() => {
                const blob = new Blob([JSON.stringify(finalJSON, null, 2)], {
                  type: "application/json",
                });
                saveAs(blob, metadata?.finalJSONPath?.split("/").pop());
              }}
            >
              <Space size={4}>
                <DownloadOutlined /> <span>Download Finalized JSON</span>
              </Space>
            </Button>
          </div>

          <div
            style={{
              backgroundColor: "white",
              height: "calc(100% - 48px)",
              borderTop: "2px solid #06509e",
              marginTop: 8,
            }}
          >
            {isValidID ? (
              <>
                {dataPts.length > 0 && (
                  <>
                    <div className="curation-legends">
                      {legendNames.map((tag, i) => (
                        <CheckableTag key={tag} checked>
                          <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                            <LegendIcon
                              index={i + 1}
                              style={{
                                fontSize: 16,
                                color: colors[i % colors.length],
                              }}
                            />

                            <b>{tag}</b>
                          </div>
                        </CheckableTag>
                      ))}

                      <>
                        <div
                          style={{ display: "flex", alignItems: "center", gap: 4, marginRight: 8, cursor: "default" }}
                        >
                          <LegendIcon
                            index={0}
                            style={{
                              fontSize: 16,
                              color: loadColor,
                            }}
                          />

                          <b>Load Average</b>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", gap: 4, cursor: "default" }}>
                          <LegendIcon
                            index={0}
                            style={{
                              fontSize: 16,
                              color: unloadColor,
                            }}
                          />
                          <b>Unload Average</b>
                        </div>
                      </>
                    </div>
                    <Chart axes={axes} legendNames={legendNames} data={dataPts} showLegend={false} />
                  </>
                )}
              </>
            ) : (
              <Result
                status="404"
                title="404"
                subTitle="Sorry, the curation you are looking for does not exist."
                extra={
                  <Button type="primary" onClick={closeCuration}>
                    Back to Test Methods
                  </Button>
                }
              />
            )}
          </div>
        </div>
      </Spin>
    </div>
  );
}

export default ViewCuration;
