import { Button, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { getMaterialData } from "../axios/index";
import DefaultBreadCrumb from "../components/breadcrumb";
import PageHeader from "../components/header/pageHeader";
import DataGrid from "../components/tables/dataGrid";
import { userRoles } from "../components/utils/userRoles";

const Materails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const data = useSelector((state) => state.materialsData);
  const userInfo = useSelector((state) => state.userInfo);
  const myModel = useSelector((state) => state.userModel);
  const latestVersion = useSelector((state) => state.latestVersion);

  const [userRole, setUserRole] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(
      getMaterialData({
        ...(myModel.isChecked && {
          myModels: userInfo.sAMAccountName,
        }),
        ...(latestVersion.isLatestVersionChecked && {
          latestVersion: latestVersion.isLatestVersionChecked,
        }),
      })
    );

    if (userInfo.sAMAccountName) {
      setUserRole(userRoles(userInfo.Roles));
    }
  }, [userInfo, myModel.isChecked, latestVersion.isLatestVersionChecked, dispatch]);

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    preserveSelectedRowKeys: true
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleCompare = () => {
    setLoading(true);
    navigate(`/materials/compare?id=${selectedRowKeys.toString()}`);
  };


  return (
    <div className="main-panel ps">
      <div className="content padd-top">
        <div className="row">
          <DefaultBreadCrumb
            data={[
              { title: "Home", path: "/" },
              { title: "Materials", path: "/materials", active: true },
            ]}
          />
        </div>
        <div className="row">
          <PageHeader
            iconType={"material"}
            title={" Manage Materials Models"}
            path="new-material"
            type="Material"
            userRole={userRole}
          />
        </div>
        <div
          className="row"
          style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0.5rem 1rem" }}
        >
          <Typography.Text strong style={{ marginLeft: 8 }}>
            {hasSelected ? `Selected ${selectedRowKeys.length} of ` : ""} {data?.data?.rows?.length} Materials
          </Typography.Text>
          <Button type="primary" onClick={handleCompare} disabled={selectedRowKeys.length < 2} loading={loading}>
            Compare
          </Button>
        </div>
        <div className="row">
          <DataGrid tableData={data} path="material-details" enableLink={true} rowSelection={rowSelection} />
        </div>
      </div>
    </div>
  );
};

export default Materails;
