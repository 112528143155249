let intinalState =  {
    loading: true,
    error: false,
    responseType: '',        
    message: '',
    data: {material:{}, fiber:{}, sam:{}},
    default:{material:{}, fiber:{}, sam:{}},
}
export default (
    state = intinalState, action) => {
    switch (action.type) {

        case 'GET_MATERIAL_DETAIL_DATA':
            return {
                ...state,
                loading: false,
                responseType:action.payload.data.responseType,
                data:action.payload.data.responseData,
                default:action.payload.data.responseData
            };
            case 'SET_SAM_DETAIL_DATA':
                return {
                    ...state,
                    loading: false,
                    data:{
                        ...state.data,
                        sam:action.payload
                    }
                };  
                
                

        case 'RESET_MATERIAL_DETAIL_DATA':
            
            //console.log("tempStatetempState", tempStateData)

            return action.payload;
        case 'RESET_MATERIAL_DETAIL_INITIAL':
               return intinalState;    
        default:

            return state;

    }

};