export function removeSpecialCharacters(inputString) {
  // Define a regular expression pattern to match special characters (non-alphanumeric characters) and hyphens that are not followed by another hyphen
  var pattern = /[^a-zA-Z0-9 -]|-(?=-)|\s/g;
  //   var pattern = /[^a-zA-Z0-9 -]|-(?![^-])/g;
  // Use the replace() method with the regular expression pattern to replace special characters with an empty string
  var resultString = inputString.replace(pattern, "");

  // Remove hyphens from the end of the string
  var stringWithoutEndHyphens = resultString.replace(/-+$/, "");

  // Remove two or more consecutive hyphens
  var finalString = stringWithoutEndHyphens.replace(/-{2,}/g, "-");
  // Define a regular expression pattern to match hyphens at the end of the string
  //   var pattern2 = /-+$/;

  //   // Use the replace() method to remove the hyphens from the end
  //   var finalString = resultString.replace(pattern2, "");

  //   // Define a regular expression pattern to match special characters (non-alphanumeric characters) except for consecutive hyphens
  //   var pattern = /[^a-zA-Z0-9 -]|-(?=-)/g;

  //   // Use the replace() method with the regular expression pattern to replace special characters with an empty string
  //   var resultString = inputString.replace(pattern, "");
  return finalString.toLowerCase();
}

export function removeSpecialCharactersTyped(inputString) {
  // Define a regular expression pattern to match special characters (non-alphanumeric characters) and hyphens that are not followed by another hyphen
  var pattern = /[^a-zA-Z0-9 -]|-(?=-)|\s/g;
  //   var pattern = /[^a-zA-Z0-9 -]|-(?![^-])/g;
  // Use the replace() method with the regular expression pattern to replace special characters with an empty string
  var resultString = inputString.replace(pattern, "");

  // Remove hyphens from the end of the string
  // var stringWithoutEndHyphens = resultString.replace(/-+$/, "");

  // Remove two or more consecutive hyphens
  var finalString = resultString.replace(/-{2,}/g, "-");
  // Define a regular expression pattern to match hyphens at the end of the string
  //   var pattern2 = /-+$/;

  //   // Use the replace() method to remove the hyphens from the end
  //   var finalString = resultString.replace(pattern2, "");

  //   // Define a regular expression pattern to match special characters (non-alphanumeric characters) except for consecutive hyphens
  //   var pattern = /[^a-zA-Z0-9 -]|-(?=-)/g;

  //   // Use the replace() method with the regular expression pattern to replace special characters with an empty string
  //   var resultString = inputString.replace(pattern, "");
  return finalString.toLowerCase();
}
