import React, { useEffect, useState, useCallback } from "react";
import {Link} from "react-router-dom"
import loader from '../../loader.gif';
import SvgComponent from '../../icons/svgComponent';

import './index.css';
 
function KpiCard(props) {  
  
 
  const renderAllModels = (kpis) =>{   
      if(kpis.loading==true){
        return(<span id="totalM"><img src={loader} className='loaderr' /></span>)
      }
      else return(
        <span>{kpis.data.totalCount}</span>
      )
  }
  const renderMyModels = (kpis) =>{
    if(kpis.loading===true){
      return(<span id="totalM"><img src={loader} className='loaderr' /></span>)
    }
    else return(
      <span>{kpis.data.userCount}</span>
    )
}


  return (
    <div className="col-lg-4">
            <div className="card card-chart">
              <Link className="tileLink" to={props.data.path}>
              <div className="card-header">
                <h5 className="card-category">{props.data.cardType}</h5>
                <h4 className="card-title">{props.data.title} 
                <i className={props.data.icon}></i>
                <SvgComponent  type ={props.data.icon} width={props.data.width} customClass = {'right'}height={props.data.height} background={props.data.bgcolor}/>
                </h4>                
              </div>
              <div className="card-body">
                <div className="chart-area">
                  <p>{props.data.content}</p>
                  <div className="chart-desc">
                  <div className="row">
                    <div className="col-lg-6">Total {props.data.title} Models</div>
                    <div className="col-lg-6">: {renderAllModels(props.data.kpiData)}</div>
                  </div>	
                  <div className="row">
                    <div className="col-lg-6">My {props.data.title} Models</div>
                    <div className="col-lg-6">: {renderMyModels(props.data.kpiData)}</div>
                  </div>
                  
                  </div> 
                        </div>
              </div>
              </Link>
              {(props.data.userRole == 'NL_ADMIN' || props.data.userRole=='NL_EDIT') && 
              <div className="card-footer">
                <div className="stats">
                  <a href={`${props.data.path}/new-${props.data.title.toLowerCase()}/-1`}> <i className="now-ui-icons ui-1_simple-add"></i> Add New {props.data.title}</a>
                </div>
              </div>
}
            </div>
          </div>
  );
}

export default KpiCard;
