import React, { useEffect, useState, useCallback } from "react";
import { renderIntoDocument } from "react-dom/test-utils";
import SvgComponent from "../../../icons/svgComponent";
import "./index.css";
function TableHeaderTitle(props) {
  return (
    <div className="header-title-txt">
      {
        <SvgComponent
          type={props.metaData.iconType}
          width={"34px"}
          customClass={""}
          height={"34px"}
          background={"transparent"}
        />
      }
      {props.metaData.tableTitle}
      {props.metaData.renderElement && props?.metaData.renderElement()}
    </div>
  );
}

export default TableHeaderTitle;
