import React, { useRef } from 'react';
// import {
//   Chart as ChartJS,
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   Tooltip,
//   Legend,
// } from 'chart.js';
// import {
//   Chart as ChartJS,
//   defaults
// } from 'chart.js';
import  { InteractionItem } from 'chart.js';
import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
  registerables 
} from 'chart.js';

import { Line, Scatter} from 'react-chartjs-2';
 
ChartJS.register(
  ...registerables
);



function  BarChart(props){
  console.log('props', props)
 
    const data = {
        datasets: [{
          type: 'scatter',
          label: 'Data',
          // yAxisID: 'A',
          data:  props.data.outputChart.rawData,
          borderColor: 'rgb(255, 99, 132)',
          backgroundColor: 'rgba(255, 99, 132, 0.2)'
        }, {
          type: 'line',
          label: 'Fit Data',
          radius: 0,
          data:props.data.outputChart.fitData,
          fill: false,
          borderColor: 'rgb(54, 162, 235)'
        }]
      };
    
      
  return <Scatter 
  options={{
    scales: {

      yAxes: {
       title: {
            display: true,
            text: props.data.chartTitle.ys
          }
       
      },
      xAxes: {
        title: {
          display: true,
          text: props.data.chartTitle.xs
        }
      }
    } 
  }} 
  data={data}     />;
}
export default BarChart;