import { FileAddOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Space,
  Spin,
  Table,
  Typography,
  Upload,
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getCompressionRows, uploadCurationMetadata } from "../../axios";

function CompressionCurationForm({ onFormSuccess, testMethodId, defaulValues }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const userInfo = useSelector((state) => state.userInfo);

  const [fileData, setFileData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileSourceMechanism, setFileSourceMechanism] = useState(""); // pick || upload
  const [comrpessionData, setComrpessionData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [testState, setTestState] = useState("");
  const [selectedCompression, setSelectedCompression] = useState({});

  useEffect(() => {
    if (testMethodId.toLowerCase().endsWith("dry")) {
      setTestState("dry");
    } else {
      setTestState("wet");
    }
  }, [testMethodId]);

  useEffect(() => {
    if (fileSourceMechanism === "pick" && testState !== "") {
      dispatch(getCompressionRows({ advanceSearch: { testState: testState } }))
        .then((response) => {
          if (response?.data?.responseData) {
            setComrpessionData(response.data.responseData?.rows);
            setFilteredData(response.data.responseData?.rows);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error: ", error);
          setLoading(false);
        });
    }
  }, [dispatch, fileSourceMechanism, testMethodId, testState]);

  useEffect(() => {
    form.setFieldsValue({
      upload: [],
    });
    return () => {
      setFileData(null);
    };
  }, [form]);

  const resetFormFields = () => {
    form.resetFields();
    setFileData(null);
  };

  const showModal = (fileMechanism) => {
    setIsModalVisible(true);
    setFileSourceMechanism(fileMechanism);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    resetFormFields();
  };

  const handleOk = () => {
    setLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const formData = new FormData();

        if (fileData) {
          const jsonData = JSON.parse(await fileData.text());
          jsonData.stackSpecimenCount = values.stackSpecimenCount;
          const file = new File(
            [
              new Blob([JSON.stringify(jsonData, null, 2)], {
                type: "application/json",
              }),
            ],
            fileData.name,
            {
              type: "application/json",
            }
          );
          formData.append("file", file);
        }

        Object.keys(values).forEach((key) => {
          if (key !== "upload") {
            formData.append(key, values[key]);
          }
        });
        formData.append("testMethod", testMethodId);
        formData.append("material_id", defaulValues.materialId);
        formData.append("created_by", userInfo.name);
        formData.append("referenceID", selectedCompression.key);

        dispatch(uploadCurationMetadata(formData))
          .then(() => {
            message.success("Metadata and file uploaded successfully!");
            onFormSuccess();
            setLoading(false);
            setIsModalVisible(false);
            resetFormFields();
          })
          .catch((error) => {
            console.error("Error uploading data:", error);
            message.error("Failed to upload data.");
            setLoading(false);
          });
      })
      .catch((info) => {
        console.log("Validation Failed:", info);
        setLoading(false);
      });
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    accept: ".json",

    beforeUpload: (file) => {
      if (!file.name.endsWith(".json")) {
        message.error(`${file.name} is not a JSON file`);
        return Upload.LIST_IGNORE;
      }
      if (fileData) {
        message.error("Only one file can be uploaded at a time.");
        setFileData(file);
        return Upload.LIST_IGNORE;
      }
      setFileData(file);
      return false;
    },
    onChange: (info) => {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
        setFileData(info.file.originFileObj);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
      form.setFieldsValue({ upload: fileList });
    },
    onRemove: (file) => {
      setFileData(null);
      form.setFieldsValue({ upload: [] });
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const handleSearch = (value) => {
    const newValue = value.toLowerCase().trim();
    const newData = comrpessionData.filter(
      (d) =>
        d.key.toString().toLowerCase().includes(newValue) ||
        d.sampleId.toString().toLowerCase().includes(newValue) ||
        d.requestorName.toLowerCase().includes(newValue) ||
        d.productCategory.toLowerCase().includes(newValue) ||
        d.materialDescription.toLowerCase().includes(newValue) ||
        d.testState.toLowerCase().includes(newValue)
    );

    setFilteredData(newData);
  };

  const columns = [
    {
      key: "key",
      type: "number",
      dataIndex: "key",
      title: "Id",
    },
    {
      key: "sampleId",
      type: "link",
      dataIndex: "sampleId",
      title: "Sample ID",
    },
    {
      key: "requestorName",
      type: "text",
      dataIndex: "requestorName",
      title: "Requestor Name",
    },
    {
      key: "productCategory",
      type: "text",
      dataIndex: "productCategory",
      title: "Product Category",
    },
    {
      key: "materialDescription",
      type: "text",
      dataIndex: "materialDescription",
      title: "Material Description",
      width: 180,
    },
    {
      key: "testState",
      type: "text",
      dataIndex: "testState",
      title: "Test State",
      width: 60,
    },
  ];

  return (
    <>
      <Button
        type="ghost"
        size="small"
        onClick={() => {
          showModal("pick");
        }}
        style={{ width: 124 }}
      >
        <Space size={4}>
          <FileAddOutlined /> <span>Pick File</span>
        </Space>
      </Button>
      <Button
        type="ghost"
        size="small"
        onClick={() => {
          showModal("upload");
        }}
        style={{ width: 148 }}
      >
        <Space size={4}>
          <UploadOutlined /> <span>Upload JSON</span>
        </Space>
      </Button>

      <Modal
        title={
          <Typography.Title level={5}>
            {testMethodId} - Add Metadata and
            {fileSourceMechanism === "pick" ? " Choose the Compression File" : " Upload Raw JSON"}
          </Typography.Title>
        }
        open={isModalVisible}
        width="60%"
        onOk={handleOk}
        onCancel={handleCancel}
        okText={fileSourceMechanism === "pick" ? "Choose" : "Upload"}
        centered
        destroyOnClose
        afterClose={() => {
          setTestState("");
          setFileSourceMechanism("");
        }}
      >
        <Spin spinning={loading}>
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              name: defaulValues.materialName,
              description: defaulValues.materialDescription,
              length: defaulValues.defaultGaugeLength,
              width: defaulValues.defaultWidth,
              thickness: defaulValues.defaultThickness,
              stackSpecimenCount: 1,
            }}
          >
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item name="name" label="Name" rules={[{ required: true, message: "Please input the Name" }]}>
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  rules={[{ required: true, message: "Please input the Description" }]}
                >
                  <Input.TextArea rows={4} />
                </Form.Item>
              </Col>
              {fileSourceMechanism !== "pick" && (
                <Col span={24}>
                  <Form.Item
                    name="stackSpecimenCount"
                    label="Stack Speciment Count"
                    rules={[{ required: true, message: "Please input the Stack Speciment Count" }]}
                  >
                    <InputNumber step={1} style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              )}

              <Col span={24}>
                {fileSourceMechanism !== "pick" ? (
                  <div style={{ height: 400, backgroundColor: "#06509e" }}>
                    <Form.Item
                      name="upload"
                      valuePropName="fileList"
                      getValueFromEvent={normFile}
                      rules={[
                        {
                          required: true,
                          message: "Please upload only one JSON File!",
                        },
                      ]}
                    >
                      <div
                        className="curation-upload"
                        style={{
                          height: 300,
                        }}
                      >
                        <Upload.Dragger
                          name="files"
                          listType="picture"
                          {...uploadProps}
                          style={{
                            backgroundColor: "#06509e",
                            border: "none !important;",
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            <UploadOutlined />
                          </p>
                          <Typography.Title level={5}>Upload JSON File</Typography.Title>
                          <Typography.Text strong>(Click Here or Drag and Drop File)</Typography.Text>
                          <br /><br />
                          <Typography.Text>Supported File Format: .json</Typography.Text>
                        </Upload.Dragger>
                      </div>
                    </Form.Item>
                  </div>
                ) : (
                  <div style={{ height: 400 }}>
                    <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 8 }}>
                      <Typography.Text strong>{filteredData.length} Compression Files</Typography.Text>
                      <Input.Search
                        placeholder="Search"
                        onSearch={handleSearch}
                        allowClear
                        enterButton
                        style={{ width: 240 }}
                      />
                    </div>

                    <Table
                      size="small"
                      loading={loading}
                      rowSelection={{
                        type: "radio",
                        hideSelectAll: true,
                        onChange: (_, selectedRows) => {
                          setSelectedCompression(selectedRows[0]);

                          fetch(
                            `${process.env.REACT_APP_BACKEND_API_URL}/api/download/compression?id=${selectedRows[0].key}`
                          )
                            .then((response) => response.blob())
                            .then(async (blob) => {
                              const jsonData = JSON.parse(await blob.text());
                              jsonData.stackSpecimenCount = selectedRows[0].stackSpecimenCount;
                              const file = new File(
                                [
                                  new Blob([JSON.stringify(jsonData, null, 2)], {
                                    type: "application/json",
                                  }),
                                ],
                                selectedRows[0].sampleId,
                                {
                                  type: "application/json",
                                }
                              );
                              setFileData(file);
                            });
                        },
                      }}
                      columns={columns}
                      dataSource={filteredData}
                      pagination={false}
                      scroll={{ x: "max-content", y: 314 }}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

export default CompressionCurationForm;
